<template>
  <div class="dash py-4 px-2 h-100">
    <div v-if="!forMobile" class="d-flex flex-row">
      <div v-for="(model, i) of models" :key="i">
        <model-card :activesSubscription="activesSubscription" @success="onSuccess" :model="model" :workspaceMemberRole="workspaceMemberRole"
          :actorsModels="actorsModels" />
      </div>
      <div>
        <div class="py-0">
          <v-btn :disabled="lockBtn()" depressed @click="createModel" class="w-100 btn">
            <v-icon color="secondary" class="mr-2">mdi-plus</v-icon> Create New
            Model</v-btn>
        </div>
      </div>
    </div>
    <div v-else>
      <v-tabs v-model="tab" next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows>
        <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->

        <v-tab v-for="model of models" :key="model._id">
          {{ model.name }}
        </v-tab>
        <v-tab :key="'create-btn'">
          Create New
          Model
        </v-tab>

    
        <v-tabs-items class="" v-model="tab">

          <v-tab-item class="tab-items  dash erd-tab"  v-for="model of models" :key="model._id">
            <model-card  :activesSubscription="activesSubscription" :ref="model._id" :forMobile="true"  @success="onSuccess" :model="model" :workspaceMemberRole="workspaceMemberRole"
            :actorsModels="actorsModels" />
          </v-tab-item>
          <v-tab-item class="tab-items  dash erd-tab"   :key="'create-btn'">
            <div class="container">
              <v-btn :disabled="lockBtn()" depressed @click="createModel" class="w-100 btn">
            <v-icon color="secondary" class="mr-2">mdi-plus</v-icon> Create New
            Model</v-btn>
            </div>
          </v-tab-item>
          
        </v-tabs-items>
      </v-tabs>
    </div>


    <!--  -->
    <v-dialog v-model="modelDialog" max-width="50rem" class="scrollbar-style-14">
      <editor-x @success="onSuccess" v-bind:fields.sync="modelFields" :callback="callBackModel" :model="modelModal"
        title="Model" v-if="modelDialog" @initFieldsValue="initFieldsValue"
        :submit-text="modelModal && modelModal._id ? 'Update Model' : 'Create Model'" />
    </v-dialog>
  </div>
</template>

<script>
import ModelCard from "./ModelCard.vue";
import {
  createModel,
  // updateModel,
  // deleteModel,
  // createAttribute,
  // updateAttribute,
  // deleteAttribute,
  // buildErdProject,
  // buildClassDiagramProject,
  // buildCodeProject,
  // exportPostmanProject,
  // saveModels,
  // saveClasses,
  // deleteProject,
  // addFeature,
} from "@/api/user.js";

import { methods, rules } from "@/utils/constant.js";
import EditorX from "../universal/EditorX.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    forMobile: {
      type: Boolean,
      default: false
    },
    models: {
      type: Array,
      default: () => [],
    },
    actorsModels: {
      type: Array,
      default: () => [],
    },
    workspaceMemberRole: {
      type: String,
      required: true
    },
    limitModelPerProjectReached: {
      type: Boolean,
      required: true
    },
    activesSubscription: null
  },
  data() {
    return {
      modelModal: {},
      setModelFieldValue: false,
      modelDialog: false,
      callBackModel: null,
      showAttributeDialog: null,
      tab: null,
      methods,
      modelFieldsData: this.getModelFieldsData()
    };
  },
  computed: {
    ...mapGetters([]),

    modelFields: {

      get() {
        return this.modelFieldsData
      },
      set(fields) {
        // this.setModelFieldValue = true;
        // alert(1)
        this.modelFieldsData = fields;
        // console.log(fields)
        // return fields
      },


    },
  },
  components: { ModelCard, EditorX },

  methods: {
    ...mapActions(["getModels"]),

    initFieldsValue() {
      this.modelFieldsData = this.getModelFieldsData()

    },
    getModelFieldsData() {
      return [
        {
          name: "project",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.$route.params.id,
        },
        {
          name: "sep-basic-config",
          label: "Basic Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "name",
          label: "Name",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          rules: [
            rules.diff(["Controller", "Auth"].concat(this.models.map(item => item.name)), "This model name is not available"),

          ]
        },
        {
          name: "description",
          label: "Description",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "color",
          label: "Color",
          type: "Color",
          col: 12,
          md: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "sep-advanced-config",
          label: "Advanced Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "isActor",
          label: "Is Actor",
          type: "Switch",
          // default: true,
          col: 12,
          md: 6,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-account-hard-hat"
        },
        {
          name: "modelType",
          label: "Model Type",
          type: "SelectX",
          col: 12,
          md: 6,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          userPlan: this.activesSubscription && this.activesSubscription.plan ? this.activesSubscription.plan.toLowerCase() : "free",
          // unauthorizeMessage:"",
          select: {
            data: [],
          },
          conditions: [
            {
              field: "isActor",
              value: true,
              action: "set-select-data",
              data: {
                yes: [
                  {
                    name: methods.getModelTypes(true).usernamePassword,
                    icon: "mdi-account-hard-hat",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).emailPassword,
                    icon: "mdi-account-hard-hat",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]


                  },
                  {
                    name: methods.getModelTypes(true).emailOrUsernamePassword,
                    icon: "mdi-account-hard-hat",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                ],
                no: [
                  {
                    name: methods.getModelTypes(true).defaultType,
                    icon: "mdi-xml",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).defaultGetMethod,
                    icon: "mdi-xml",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).simpleProduct,
                    icon: "mdi-xml",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).simpleRequest,
                    icon: "mdi-code-brackets",
                    badge: "standard +",
                    color: "purple",
                    authorizePlans: ["standard", "advanced"]
                  },
                  {
                    name: methods.getModelTypes(true).manage,
                    icon: "mdi-code-brackets",
                    badge: "standard +",
                    color: "purple",
                    authorizePlans: ["standard", "advanced"]
                  },
                  {
                    name: methods.getModelTypes(true).toggle,
                    icon: "mdi-code-brackets",
                    badge: "standard +",
                    color: "purple",
                    authorizePlans: ["standard", "advanced"]
                  },
                ]
              }
            }
          ],
        },
        // {
        //   name: "modelType",
        //   label: "Model Type",
        //   type: "Select",
        //   col: 12,
        //   md: 6,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   select: {
        //     data: [],
        //   },
        //   conditions: [
        //     {
        //       field: "isActor",
        //       value: true,
        //       action: "set-select-data",
        //       data: {
        //         yes: [
        //           methods.getModelTypes(true).usernamePassword,
        //           methods.getModelTypes(true).emailPassword,
        //           methods.getModelTypes(true).emailOrUsernamePassword,
        //         ],
        //         no: [
        //           methods.getModelTypes(true).defaultType,
        //           methods.getModelTypes(true).simpleRequest,
        //         ]
        //       }
        //     }
        //   ],
        // },

        // {
        //   name: "withPagination",
        //   label: "With pagination",
        //   type: "Switch",
        //   // default: true,
        //   col: 12,
        //   md:6,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   icon:"mdi-navigation"
        // },
        {
          name: "constantCode",
          label: "Constant",
          type: "Code",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },
        {
          name: "postFind",
          label: "Post Find",
          type: "Code",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },
        {
          name: "postFindOne",
          label: "Post Find One",
          type: "Code",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },
        {
          name: "sep-auth-config",
          label: "Auth Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,

        },



        {
          name: "createAuth",
          label: "Create Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,

          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },

        },
        {
          name: "updateAuth",
          label: "Update Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },
          conditions: [
            {
              field: "isActor",
              value: true,
              action: "hidden"
            }
          ],
        },
        {
          name: "deleteAuth",
          label: "Delete Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },

        },
        {
          name: "getAuth",
          label: "Get Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },
        },
      ]
    },
    lockBtn() {
      let result = false

      if (![methods.getWorkspaceMemberRoles(true).owner, methods.getWorkspaceMemberRoles(true).developer, methods.getWorkspaceMemberRoles(true).maintainer].includes(this.workspaceMemberRole)) {
        result = true
      }
      if (this.limitModelPerProjectReached == true) {
        result = true
      }
      return result
    },
    async createModel() {
      this.modelModal = {};

      this.modelDialog = !this.modelDialog;
      this.callBackModel = createModel;
    },
    async onSuccess() {
      this.modelDialog = false;
      this.showModelDialog = false;
      this.$emit("refresh")
      // await this.initialize()
      // this.$refs.classDiagram.initialize()
    },
  },
  watch: {
    models() {
      if (!this.tab && Array.isArray(this.models) && this.models[0]) {
        this.tab = this.models[0]._id
      }
    }
  }
};
</script>

<style></style>