<template>
  <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>

      <v-container v-if="project && project.project" class="white" fluid>
        <div class="py-5 setting-container scrollbar-style-14 w-100 px-5">
          <div class="d-flex">
            <v-btn class="mr-5" icon @click="dialog = false">
              <v-icon class="font-weight-bold" size="1rem">mdi-close</v-icon>
            </v-btn>
            <h3 class="big-title">Settings</h3>
          </div>

          <div class="d-flex d-md-block justify-center ">
            <div class="container ">
            <v-row class="px-md-12 mt-5 rounded py-md-8">
              <v-col cols="12" md="4">
                <div class="d-md-flex">
                  <div class="mr-md-5 d-md-block d-flex  justify-center align-center">
                    <v-avatar size="10rem">
                      <v-img :src="project.project.image"></v-img>
                    </v-avatar>
                  </div>
                  <div class="d-md-block d-flex  justify-center align-center">
                    <div>
                      <div class="d-md-flex align-center">
                      <h3 class="big-title mb-0">{{ project.project.name }}
                      </h3>
                      <!-- <span class="ml-md-3" v-if="project.project.gitLabFullPath">
                        <button class="elevation-0 copy-clipboard-btn btn px-2"
                          @click.prevent="copyToClipboard(project.project.gitLabFullPath)"><v-icon size=".9rem">{{
                            copiedText == 'Copy' ?
                            'mdi-content-copy' : 'mdi-check' }}</v-icon> {{ copiedText
                          }}
                          <span class="ml-3" v-if="project.project.gitLabFullPath"><v-icon>mdi-gitlab</v-icon></span>
                        </button>
                      </span> -->
                    </div>
                    <div>
                      <p class="mb-0 p-description secondary--text">
                        Contribute to the project as
                        <span class="font-weight-bold">{{ project.workspaceMemberRole }}</span>
                      </p>
                      <div class="mt-3">
                        <span class="mr-3"><v-icon small>mdi-table</v-icon>
                          <span>{{ project.project.totalModels }}</span></span>
                        <span class="mr-3"><v-icon small>mdi-account-circle</v-icon>
                          <span>{{ project.project.totalActors }}</span></span>

                      </div>
                      <div class="mt-5">
                        <v-btn small @click="deleteProject" color="white" outlined class="red--text elevation-0 btn"
                          rounded><v-icon small class="mr-2">mdi-delete</v-icon> Delete
                          this project</v-btn>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div class="d-flex ">

                  <div>
                    <h3 class=" mb-0 h5-title">Features</h3>
                    <div class="mt-1">
                      <ul v-if="project.project.features && project.project.features.length > 0">
                        <li class="h5-description" v-for="feature, i of project.project.features" :key="i">{{ feature }}
                        </li>
                      </ul>
                      <ul v-else>
                        <li class="h5-description">{{ "none" }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div class="d-flex">

                  <div>
                    <h3 class=" mb-0 h5-title">Subscription details</h3>
                    <div class="mt-1">
                      <li class="h5-description"> <span class="font-weight-bold-">Plan :</span> {{
                        project.workspaceSubscriptionResponse.plan }} </li>
                      <li class="h5-description"> <span class="font-weight-bold-">Max model per project :</span> {{
                        project.workspaceSubscriptionResponse.maxModelPerProject }} </li>
                      <li class="h5-description"> <span class="font-weight-bold-">Model limit per project reached
                          :</span> {{ project.workspaceSubscriptionResponse.maxModelPerProject >
                            project.project.totalModels ? 'No' : 'Yes' }} </li>
                    </div>
                  </div>
                </div>
              </v-col>

              <v-col cols="12" md="2">
                <div>
                  <div class="d-none  d-md-flex justify-center">
                    <logo />
                  </div>
                  <div class="px-3 text-center">
                    <h3 class="h5-title mt-0 mb-1 ">Project created by

                      <span>
                        {{ project.project.createdBy.lastName }}
                        {{ project.project.createdBy.firstName }}
                      </span>
                    </h3>
                    <h3 class="h5-title  mb-1 white--text">Doctor Mavix</h3>
                    <!-- <p class="h5-description">All right Reserve</p> -->
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="dash rounded">
              <v-row>
                <v-col cols="12">
                  <editor-x editorClass="elevation-0" @success="onSuccess" :fields="projectField"
                    :callback="callBackProject" :model="project.project" title="Project Global Setting"
                    :withoutImages="false" submitText="Update Project" />
                </v-col>
              </v-row>
            </div>
          </div>
          </div>
        </div>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapGetters,
  // mapActions
} from "vuex";
import Logo from "./Logo.vue";
import { updateProject, deleteProject } from "@/api/user.js";

import { methods } from "@/utils/constant.js";
import EditorX from "../universal/EditorX.vue";

export default {
  components: { Logo, EditorX },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["project"]),
    projectField() {
      return [
        {
          name: "name",
          label: "Project Name",
          placeholder: "Referentiel App",
          type: "String",
          col: 12,
          md: 4,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
        {
          name: "description",
          label: "Description",
          type: "String",
          col: 12,
          md: 8,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "template",
          label: "Template",
          hint: "You can create from template",
          type: "Select",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: false,
          select: {
            store: {
              action: this.getTemplates,
              params: {
                withAuthMiddleware: true,
                $axios: this.$axios,
                project: this.$route.params.id,
              },
              getter: this.templates,
              fieldName: "name",
            },
          },
        },
        {
          name: "sep-backend",
          label: "Global Backend Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "backendType",
          label: "Backend Type",
          type: "Select",
          col: 12,
          md: 3,

          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getBackendType(false),
          },
          icon: "mdi-nodejs",
        },

        {
          name: "port",
          label: "Backend Server Port",
          type: "Number",
          col: 12,
          md: 3,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-router-network",
        },
        {
          name: "sep-backend",
          label: "Database Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "databaseType",
          label: "Database Type",
          type: "Select",
          col: 12,
          md: 3,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getDatabaseType(false),
          },
          icon: "mdi-database",
        },
        {
          name: "database",
          label: "Database Name",
          type: "String",
          col: 12,
          md: 3,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-database-cog",
        },
        {
          name: "databaseHost",
          hint: "The hostname or IP address where your database is running",
          label: "Database Host",
          type: "String",
          col: 12,
          md: 3,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-database-cog",
          default: "localhost"

        },
        {
          name: "databasePort",
          hint: "The port number on which your database is listening for connections.",
          label: "Database Port",
          type: "String",
          col: 12,
          md: 3,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-database-cog",
          default: "27017"
        },


        {
          name: "databaseUsername",
          label: "Database Username",
          hint: " If authentication is enabled, the username used to connect to the database",
          type: "String",
          col: 12,
          md: 3,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-database-cog",
        },
        {
          name: "databasePassword",
          label: "Database Password",
          hint: "The password associated with the username for authentication",
          type: "String",
          col: 12,
          md: 3,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-database-cog",
        },
        {
          name: "smtp-config-sep",
          label: "SMTP config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "mailHost",
          label: "SMTP Host",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-gmail",
        },
        {
          name: "mailPort",
          label: "SMTP Port",
          type: "Number",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-mailbox-outline",
        },
        {
          name: "mailAddresse",
          label: "SMTP Addresse",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-email-outline",
        },
        {
          name: "mailPassword",
          label: "SMTP Password",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-email-lock",
        },
        // {
        //   name: "sep-frontend",
        //   label: "Global Frontend Config",
        //   type: "Separator",
        //   size: "12px",
        //   weight: "600",
        //   width: "180px",
        //   align: "left",
        //   textType: "left",
        //   col: 12,
        //   md: 12,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        // },
        // {
        //   name: "frontendType",
        //   label: "Frontend Type",
        //   type: "Select",
        //   col: 12,
        //   md: 6,

        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   select: {
        //     data: methods.getFrontendType(false),
        //   },
        //   icon: "mdi-vuejs",
        // },
        // {
        //   name: "frontendCssFramework",
        //   label: "Frontend CSS Framework",
        //   type: "Select",
        //   col: 12,
        //   md: 6,

        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   select: {
        //     data: methods.getFrontendCssFramework(false),
        //   },
        //   icon: "mdi-vuetify",
        // },

        {
          name: "sep-backend",
          label: "Project Additional Info",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "image",
          label: "Project Image",
          type: "File",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
        },
        // {
        //   name: "images",
        //   label: "Project Image",
        //   type: "Dropzone",
        //   col: 12,
        //   md: 6,
        //   multipleCol: 2,
        //   multipleMdCol: 3,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   icon: "mdi-email-outline",
        // },
      ];
    },
  },
  data() {
    return {
      callBackProject: updateProject,
      copiedText: "Copy"
    };
  },

  watch: {
    dialog() {
      if (this.dialog == false) {
        this.$emit("dialogChange");
      }
    },
  },
  methods: {
    onSuccess() {
      //   this.initialize()
      this.$emit("success");
    },
    async deleteProject() {
      try {
        methods.dispatchDeleteDialog(this.$store, {
          dialog: true,
          what: "Do you really want to delete this project",
          callback: deleteProject,
          data: { id: this.project.project._id },
          successHandler: () => {
            this.onSuccess();
            this.$router.push("/");
          },
          description: "You can no longer reconsider your decision",
        });
      } catch (error) {
        methods.dispatchError(this.$store);
      }
    },
    copyToClipboard(data) {
      if (!navigator.clipboard) {
        console.error('Clipboard API not supported');
        return;
      }
      navigator.clipboard.writeText(data).then(() => {
        console.log('Data copied to clipboard successfully!');
        this.copiedText = "Copied"
        setTimeout(() => {
          this.copiedText = "Copy"
        }, 2000)
      }).catch(err => {
        console.error('Failed to copy data to clipboard: ', err);
      });
    }
  },
};
</script>

<style>
.setting-container {
  overflow-y: scroll;
  height: 100vh;
}
</style>