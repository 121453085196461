<template>
  <div :class="forMobile ? 'container' : false">
    <div v-if="model && model._id">
      <div class="list-card rounded mr-12">
        <div class="white--text py-2 px-4 list-head rounded d-flex justify-space-between align-center"
          :style="`background-color:${model.color}`">
          <div>
            <span class="font-weight-bolder"> {{ methods.limitTextCharacters(model.name, 40) }} </span>
          </div>

          <div class="d-flex">
            <v-btn @click="manageShowModel" color="white" icon>
              <v-icon>mdi-dots-horizontal</v-icon></v-btn>
          </div>
        </div>
        <div class="list-body px-2 py-1">
          <div v-for="attribute of model.attributes" :key="attribute._id"
            class="px-2 list-item py-2 d-flex justify-space-between mb-1 rounded w-100"
            @click="manageShowAttribute(attribute)">
            <span class="list-text">{{ attribute.name }} </span>
            <span>
           
              <v-tooltip v-if="attribute.ref && attribute.ref._id" bottom color="gray lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="18px" v-if="attribute.ref && attribute.ref._id"
                  :color="attribute.ref.color">mdi-key-variant</v-icon>
                </template>
                <span>Attribute is a foreign key. It refers to the {{ attribute.ref.name }} model.</span>
              </v-tooltip>
              <v-tooltip bottom color="gray lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="18px" :color="'green'" class="ml-2"
                  v-if="['Object', 'ObjectArray'].includes(attribute.type)">mdi-code-json</v-icon>
                </template>
                <span>Attribute is of type {{ attribute.type }}.</span>
              </v-tooltip>


              <v-tooltip bottom color="gray lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="18px" :color="'gray'" class="ml-2"
                    v-if="attribute.fromSystem">mdi-robot-outline</v-icon>
                </template>
                <span>Attribute is provided by the system. By default, you cannot send it in the payload.</span>
              </v-tooltip>

              <v-tooltip bottom color="gray lighten-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="18px" :color="'gray'" class="ml-2"
                    v-if="attribute.isHidden">mdi-eye-off-outline</v-icon>
                </template>
                <span>Attribute is hidden. By default, it will not be sent to the user in the API response.</span>
              </v-tooltip>
            </span>
          </div>
          <div class="px-2 list-item py-0 d-flex justify-space-between mb-1 rounded w-100">
            <v-btn depressed @click="addAttribute" :disabled="lockBtn()" class="w-100 btn">Add Attribute <v-icon small
                class="ml-1" v-if="lockBtn()">mdi-lock</v-icon></v-btn>
          </div>
        </div>
      </div>

      <!-- show model -->
      <v-dialog v-model="showModelDialog" max-width="1000px">
        <div class="card">
          <!-- name -->
          <div class="px-5 py-3">
            <div class="">
              <h3 class="h4-title">{{ model.name }}</h3>
              <div v-if="isOpen == true" class="mt-3">
                <p class="orange darken-3 rounded font-weight-bolder white--text px-2 py-1">
                  <span class=" "> Warning : This model is currently open by :
                    {{ openByName ? openByName : "another user" }}</span>
                </p>
              </div>
            </div>
            <p class="h5-description mb-0 mt-2">
              {{ model.description }}
            </p>
          </div>

          <!-- type , additional type -->

          <!-- type  -->
          <div>
            <p class="mb-0 px-5 py-3">
              <span class="h5-title">Type :</span>
              <span class="h5-description"> {{ model.type }}</span>
            </p>
            <p class="mb-0 px-5 h5-title py-3">Additional types</p>
            <div class="px-2">
              <v-chip-group active-class="primary--text ">
                <v-chip v-for="(secondaryType, i) in model.secondaryTypes" :key="i" outlined>
                  {{ secondaryType }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>

          <!-- auth -->
          <div class="results mt-5">
            <p class="label">Auth</p>

            <div class="results-list">
              <div class="entry">
                <div class="icon">
                  <v-icon>mdi-plus</v-icon>
                </div>
                <div class="desc">
                  <label>{{
                    model.createAuth && model.createAuth._id
                      ? model.createAuth.name
                      : "None"
                  }}</label>
                  <span>This collection will use as default auth for corresponding
                    router
                    <span class="font-weight-bolder">post</span> method</span>
                </div>
              </div>
              <div class="entry">
                <div class="icon">
                  <v-icon>mdi-pencil</v-icon>
                </div>
                <div class="desc">
                  <label>{{
                    model.updateAuth && model.updateAuth._id
                      ? model.updateAuth.name
                      : "None"
                  }}</label>
                  <span>This collection will use as default auth for corresponding
                    router
                    <span class="font-weight-bolder">put</span> method</span>
                </div>
              </div>
              <div class="entry">
                <div class="icon">
                  <v-icon>mdi-delete</v-icon>
                </div>
                <div class="desc">
                  <label>{{
                    model.deleteAuth && model.deleteAuth._id
                      ? model.deleteAuth.name
                      : "None"
                  }}</label>

                  <span>This collection will use as default auth for corresponding
                    router
                    <span class="font-weight-bolder">delete</span> method</span>
                </div>
              </div>
              <div class="entry">
                <div class="icon">
                  <v-icon>mdi-meteor</v-icon>
                </div>
                <div class="desc">
                  <label>{{
                    model.getAuth && model.getAuth._id
                      ? model.getAuth.name
                      : "None"
                  }}</label>

                  <span>This collection will use as default auth for corresponding
                    router
                    <span class="font-weight-bolder">get</span> method</span>
                </div>
              </div>
            </div>

            <hr />

            <!-- is actor , is active  -->
            <div class="mb-2">
              <v-row class="w-100">
                <v-col cols="6">
                  <div class="switch-holder">
                    <div class="switch-label">
                      <v-icon size="18px" class="mr-2">mdi-account-hard-hat</v-icon>
                      <span class="font-weight-bolder">Is Actor : </span>
                      <span>
                        {{ model.isActor ? 'Yes' : 'No' }}
                      </span>
                    </div>

                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="switch-holder d-flex justify-end">
                    <div class="switch-label">
                      <v-icon size="18px" class="mr-2">mdi-watch-vibrate</v-icon>
                      <span class="font-weight-bolder">Is Activated : </span>
                      <span>
                        {{ model.isActive ? 'Yes' : 'No' }}
                      </span>
                    </div>

                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <hr />

          <div
            v-if="[methods.getWorkspaceMemberRoles(true).owner, methods.getWorkspaceMemberRoles(true).developer, methods.getWorkspaceMemberRoles(true).maintainer].includes(workspaceMemberRole)"
            class="py-4 pr-3">
            <div class="d-flex justify-end">
              <button v-if="!lockBtn()" @click="addAttribute" class="m-btn-dense ml-2">
                <span><v-icon size="16px" color="green" class="mr-1">mdi-plus</v-icon>
                  Add Attribute
                </span>
              </button>
              <button v-if="!lockBtn()" @click="editModel" class="m-btn-dense ml-2">
                <span><v-icon size="16px" color="green" class="mr-1">mdi-pencil</v-icon>
                  Edit
                </span>
              </button>

              <button v-if="!lockBtn()" @click="cloneModel" class="m-btn-dense ml-2">
                <span><v-icon size="16px" color="green" class="mr-1">mdi-content-copy</v-icon>
                  Clone
                </span>
              </button>

              <button v-if="!lockBtn()" @click="deleteModel" class="m-btn-dense ml-2">
                <span><v-icon size="16px" color="green" class="mr-1">mdi-trash-can</v-icon>
                  Delete
                </span>
              </button>
              <v-menu v-model="menuDialog" :close-on-content-click="false" offset-y>
                <template v-slot:activator="{ on, attrs }">

                  <button v-bind="attrs" v-on="on" v-if="!lockBtn()" :disabled="loading"
                    class="m-btn-dense d-none d-lg-inline ml-2">
                    <span>
                      <span v-if="!loading">
                        <v-icon size="16px" color="green" class="mr-1">mdi-cog-sync</v-icon>
                        <span> Build {{ methods.limitTextCharacters(model.name, 25) }} Class Diagram</span>
                      </span>
                      <span v-else>
                        <v-btn small loading icon class="white"></v-btn>
                      </span>
                    </span>
                  </button>
                </template>

                <div class="container white">
                  <div>
                    <button v-bind="attrs" v-on="on" v-if="!lockBtn()" :disabled="loading"
                      @click="buildModelClassDiagram('')" class="m-btn-dense d-none d-md-inline ml-2">
                      <span>
                        <span v-if="!loading">
                          <v-icon size="16px" color="green" class="mr-1">mdi-cog-sync</v-icon>
                          <span> Build Class Diagram</span>
                        </span>
                        <span v-else>
                          <v-btn small loading icon class="white"></v-btn>
                        </span>
                      </span>
                    </button>
                    <button v-bind="attrs" v-on="on" v-if="!lockBtn()" :disabled="loading"
                      @click="buildModelClassDiagram('Restore')" class="m-btn-dense d-none d-md-inline ml-2">
                      <span>
                        <span v-if="!loading">
                          <v-icon size="16px" color="green" class="mr-1">mdi-cog-sync</v-icon>
                          <span> Restore Class Diagram</span>
                        </span>
                        <span v-else>
                          <v-btn small loading icon class="white"></v-btn>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </v-menu>


            </div>
          </div>
        </div>
      </v-dialog>

      <!-- edit dialog -->
      <v-dialog v-model="modelDialog" max-width="900px">
        <editor-x @success="onSuccess" :fields="modelFields" :callback="callBackModel" :model="modelModal" title="Model"
          :multiple="false" color="primary" v-if="modelDialog" :submit-text="modelModal && modelModal._id ? 'Update Model' : 'Create Model'
            " />
      </v-dialog>

      <!-- show attribute -->
      <v-dialog v-model="showAttributeDialog" max-width="750px">
        <div class="card">
          <!-- name -->
          <div class="px-5 py-3">
            <h3 class="h4-title">{{ attributeModal.name }}</h3>
            <p class="h5-description mb-0 mt-2">
              {{ attributeModal.description }}
            </p>
          </div>

          <!-- type , additional type -->

          <!-- type  -->
          <div>
            <p class="mb-0 px-5 py-3">
              <span class="h5-title">Type :</span>
              <span class="h5-description"> {{ attributeModal.type }}</span>
            </p>
          </div>

          <!-- Ref -->
          <div class="results my-3">
            <div class="mb-2" v-if="attributeModal.ref && attributeModal.ref._id">
              <!-- "G" {{ attributeModal.refAttributes }} -->

              <hr />
              <p class="label mt-2" :style="'color:' + attributeModal.ref.color + ';'">
                Ref
              </p>

              <div class="results-list">
                <div class="entry">
                  <div class="icon">
                    <v-icon>mdi-key</v-icon>
                  </div>
                  <div class="desc">
                    <label>{{ attributeModal.ref.name }}</label>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <!-- is actor , is active  -->
            <div class="mb-2">
              <v-row class="w-100">
                <v-col cols="6" md="3">
                  <div class="flex-column">
                    <div class="switch-label   ">
                      <v-icon size="16px" class="mr-2">mdi-account-hard-hat</v-icon>
                      <span class="h5-title">Is Required :
                      </span>
                      <span class="h5-title">
                        {{ attributeModal.required ? 'Yes' : 'No' }}
                      </span>

                    </div>

                  </div>
                </v-col>
                <v-col cols="6" md="3">
                  <div class=" flex-column">
                    <div class="switch-label">
                      <v-icon size="16px" class="mr-2">mdi-watch-vibrate</v-icon>
                      <span class="h5-title">Is Unique :</span>
                      <span class="h5-title">
                        {{ attributeModal.unique ? 'Yes' : 'No' }}
                      </span>
                    </div>

                  </div>
                </v-col>
                <v-col cols="6" md="3">
                  <div class=" flex-column">
                    <div class="switch-label">
                      <v-icon size="16px" class="mr-2">mdi-watch-vibrate</v-icon>
                      <span class="h5-title">Is Hash : </span>
                      <span class="h5-title">
                        {{ attributeModal.isHash ? 'Yes' : 'No' }}
                      </span>
                    </div>

                  </div>
                </v-col>
                <v-col cols="6" md="3">
                  <div class="flex-column">
                    <div class="switch-label">
                      <v-icon size="16px" class="mr-2">mdi-watch-vibrate</v-icon>
                      <span class="h5-title">Is Activated : </span>
                      <span class="h5-title">
                        {{ attributeModal.isActive ? 'Yes' : 'No' }}
                      </span>
                    </div>

                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
          <hr />

          <div
            v-if="[methods.getWorkspaceMemberRoles(true).owner, methods.getWorkspaceMemberRoles(true).developer, methods.getWorkspaceMemberRoles(true).maintainer].includes(workspaceMemberRole)"
            class="py-4 pr-3">
            <div class="d-flex justify-end">
              <button @click="editAttribute(attributeModal)" class="m-btn-dense ml-2">
                <span><v-icon size="16px" color="green" class="mr-1">mdi-pencil</v-icon>
                  Edit
                </span>
              </button>

              <button @click="cloneAttribute(attributeModal)" class="m-btn-dense ml-2">
                <span><v-icon size="16px" color="green" class="mr-1">mdi-content-copy</v-icon>
                  Clone
                </span>
              </button>

              <button @click="deleteAttribute(attributeModal)" class="m-btn-dense ml-2">
                <span><v-icon size="16px" color="green" class="mr-1">mdi-trash-can</v-icon>
                  Delete
                </span>
              </button>
            </div>
          </div>
        </div>
      </v-dialog>

      <!--  -->
      <v-dialog v-model="attributeDialog" :max-width="!multipleAttribute ? '50rem' : '80rem'">
        <editor-x @success="onSuccess" v-bind:fields.sync="attributeFields" v-bind:models.sync="modelsFields"
          :callback="callBackAttribute" :model="attributeModal" title="Attribute" :multiple="multipleAttribute"
          v-if="attributeDialog" :submit-text="attributeModal && attributeModal._id
            ? 'Update Attribute'
            : 'Create Attribute (s)'
            " />
      </v-dialog>


      <v-dialog v-model="alertConfirmEdit" max-width="40rem">
        <v-alert class="white my-0 py-5" text dense color="read" icon="mdi-alert" border="left">
          <p class="">{{ "Would you like to really edit this model ?" }}</p>
          <v-divider class="my-4 read" style="opacity: 0.22"></v-divider>

          <div>
            <div>
              <p class="mb-0">
                {{ `By opening it, it will be closed on ${openByName}'s side even if it has not been saved yet.` }}
              </p>
            </div>
          </div>
          <div class="d-flex justify-end mt-5">
            <v-btn color="red" outlined @click="editModel">{{ "EDIT" }}</v-btn>
            <v-btn color="greens" class="ml-3" outlined @click="modelDialog = false">{{ "CANCEL"
              }}</v-btn>
          </div>
        </v-alert>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import EditorX from "../universal/EditorX.vue";
import { methods } from "@/utils/constant.js";
import {
  createModel,
  updateModel,
  deleteModel,
  createAttribute,
  updateAttribute,
  deleteAttribute,
  buildClassDiagramProject

} from "@/api/user.js";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    workspaceMemberRole: {
      type: String,
      required: true
    },
    forMobile: {
      type: Boolean,
      default: false
    },
    actorsModels: {
      type: Array,
      default: () => [],
    },
    activesSubscription: null

  },
  components: { EditorX },
  data() {
    return {
      modelModal: {},
      menuDialog: false,
      activated: true,
      loading: false,
      showModelDialog: false,
      modelDialog: false,
      attributeDialog: false,
      multipleAttribute: true,
      showAttributeDialog: false,
      callBackModel: null,
      attributeModal: {
      },
      callBackAttribute: null,
      setAttributeFieldValue: false,
      methods,
      attributeFieldsData: this.getAttributeFieldsData(),
      modelsFieldsData: this.getModelsFieldsData(),
      isOpen: false,
      openByName: null,
      alertConfirmEdit: false,
      fromCloneId: null,

    };
  },

  computed: {
    ...mapGetters(["models", "project"]),

    attributeFields: {
      get() {
        return this.attributeFieldsData;
      },
      set(fields) {
        this.setAttributeFieldValue = true;
        // alert(1)
        this.attributeFieldsData = fields;
        // console.log(fields)
        // return fields
      },

    },
    modelsFields: {
      get() {
        return this.modelsFieldsData;
      },
      set(fields) {
        // console.log("fields", fields)
        this.setAttributeFieldValue = true;
        // alert(1)
        // alert(JSON.stringify(fields))
        // console.log(fields)
        this.modelsFieldsData = fields;
        // return fields
      },

    },
    modelFields() {
      return [
        {
          name: "fromClone",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: false,
          existForAdd: true,
          value: this.fromCloneId,
        },
        {
          name: "name",
          label: "Name",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "description",
          label: "Description",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "color",
          label: "Color",
          type: "Color",
          col: 12,
          md: 6,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "isActor",
          label: "Is Actor",
          type: "Switch",
          // default: true,
          col: 12,
          md: 6,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
          icon: "mdi-account-hard-hat",
        },
        {
          name: "modelType",
          label: "Model Type",
          type: "SelectX",
          col: 12,
          md: 6,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          userPlan: this.activesSubscription && this.activesSubscription.plan ? this.activesSubscription.plan.toLowerCase() : "free",
          // unauthorizeMessage:"",
          select: {
            data: [],
          },
          conditions: [
            {
              field: "isActor",
              value: true,
              action: "set-select-data",
              data: {
                yes: [
                  {
                    name: methods.getModelTypes(true).usernamePassword,
                    icon: "mdi-account-hard-hat",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).emailPassword,
                    icon: "mdi-account-hard-hat",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]


                  },
                  {
                    name: methods.getModelTypes(true).emailOrUsernamePassword,
                    icon: "mdi-account-hard-hat",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                ],
                no: [
                  {
                    name: methods.getModelTypes(true).defaultType,
                    icon: "mdi-xml",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).defaultGetMethod,
                    icon: "mdi-xml",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).simpleProduct,
                    icon: "mdi-xml",
                    badge: "free",
                    authorizePlans: ["free", "standard", "advanced"]

                  },
                  {
                    name: methods.getModelTypes(true).simpleRequest,
                    icon: "mdi-code-brackets",
                    badge: "standard +",
                    color: "purple",
                    authorizePlans: ["standard", "advanced"]
                  },
                  {
                    name: methods.getModelTypes(true).manage,
                    icon: "mdi-code-brackets",
                    badge: "standard +",
                    color: "purple",
                    authorizePlans: ["standard", "advanced"]
                  },
                  {
                    name: methods.getModelTypes(true).toggle,
                    icon: "mdi-code-brackets",
                    badge: "standard +",
                    color: "purple",
                    authorizePlans: ["standard", "advanced"]
                  },
                ]
              }
            }
          ],
        },
        // {
        //   name: "modelType",
        //   label: "Model Type",
        //   type: "Select",
        //   col: 12,
        //   md: 6,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: true,
        //   existForUpdate: true,
        //   existForAdd: true,
        //   select: {
        //     data: methods.getModelTypes(false),
        //   },
        // },
        // {
        //   name: "withPagination",
        //   label: "With pagination",
        //   type: "Switch",
        //   // default: true,
        //   col: 12,
        //   md: 6,
        //   isRequiredForUpdate: false,
        //   isRequiredForAdd: false,
        //   existForUpdate: false,
        //   existForAdd: false,
        //   icon: "mdi-navigation",
        // },

        {
          name: "project",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.$route.params.id,
        },

        {
          name: "constantCode",
          label: "Constant",
          type: "Code",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },
        {
          name: "postFind",
          label: "Post Find",
          type: "Code",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },
        {
          name: "postFindOne",
          label: "Post Find One",
          type: "Code",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
        },

        {
          name: "createAuth",
          label: "Create Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,

          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },

        },
        {
          name: "updateAuth",
          label: "Update Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },
          conditions: [
            {
              field: "isActor",
              value: true,
              action: "hidden"
            }
          ],
        },
        {
          name: "deleteAuth",
          label: "Delete Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },

        },
        {
          name: "getAuth",
          label: "Get Auth",
          type: "Select",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          select: {
            objectData: this.actorsModels,
            fieldName: "name",
            fieldValue: "_id",
          },
        },
      ];
    },
  },
  beforeDestroy() {
    this.emitCloseModel()
  },
  beforeRouteLeave(to, from, next) {
    this.emitCloseModel()
    next();
  },
  sockets: {
    forceCloseModel(payload) {

      if (payload.modelId && JSON.stringify(payload.modelId) == JSON.stringify(this.model._id)) {
        this.emitCloseModel()
        this.showModelDialog = false
        this.modelDialog = false

      }
    },
    openModel(payload) {
      if (payload.modelId && JSON.stringify(payload.modelId) == JSON.stringify(this.model._id)) {
        this.isOpen = true
        this.openByName = payload.adminName

      }
    },
    closeModel(payload) {
      if (payload.modelId && JSON.stringify(payload.modelId) == JSON.stringify(this.model._id)) {
        this.isOpen = false

      }
    },
  },
  watch: {
    modelDialog() {
      if (this.modelDialog == true && this.modelModal && this.project && this.project.project) {
        this.$socket.emit('openModel', {
          projectId: this.project.project._id,
          modelId: this.modelModal._id
        })
        this.isOpen = true

      }
      if (this.modelDialog == true) {
        this.alertConfirmEdit = false
      }
      if (this.modelDialog == false) {
        this.emitCloseModel()
        this.isOpen = false
        this.alertConfirmEdit = false
      }
    },
    models() {
      if (this.setAttributeFieldValue == false) {

        this.attributeFieldsData = this.getAttributeFieldsData()
        this.modelsFieldsData = this.getModelsFieldsData()
      }
    },
    attributeDialog() {
      if (this.attributeDialog != true) {
        this.attributeFieldsData = this.getAttributeFieldsData()
        this.modelsFieldsData = this.getModelsFieldsData()
      }

    }
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeUnload);

    this.fromCloneId = null

    if (typeof this.model == 'object') {
      this.isOpen = this.model.isOpen

      if (this.model.openBy && this.model.openBy.lastName) {
        this.openByName = `${this.model.openBy.lastName} ${this.model.openBy.firstName}`
      }
    }
  },
  methods: {
    ...mapActions(["getModels"]),
    async buildModelClassDiagram(manageType) {
      try {
        this.loading = true;
        await buildClassDiagramProject({
          id: this.$route.params.id,
          manageType: manageType,
          schema: {
            modelIds: [
              this.model._id
            ]
          }
        });

        methods.dispatchSuccess(this.$store);
        // this.initialize();
        this.onSuccess()
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
      }
    },
    beforeUnload() {
      this.emitCloseModel()
    },
    emitCloseModel() {
      if (this.modelModal && this.modelModal._id && this.project && this.project.project) {
        this.$socket.emit('closeModel', {
          projectId: this.project.project._id,
          modelId: this.modelModal._id
        })
      }

    },
    getAttributeFieldsData() {
      return [
        {
          name: "sep-basic-config",
          label: "Basic Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "type",
          label: "Type",
          type: "Select",
          col: 12,
          multipleCol: 12,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getTypeList(false),
          },
        },
        {
          name: "name",
          label: "Name",
          type: "String",
          col: 12,
          multipleCol: 12,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "description",
          label: "Description",
          type: "String",
          col: 12,
          multipleCol: 12,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "default",
          label: "Default",
          type: "String",
          hint: "<< \" >>, << ' >> ,<< ` >> are not allowed for types: String, Number, Boolean, mongoose.Schema.Types.ObjectId, Date. Quotation marks are managed automatically for these types.",
          col: 12,
          multipleCol: 12,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },


        {
          name: "sep-relation-config",
          label: "Relation: Refer to another model",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "250px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          isHidden: true,
          conditions: [
            {
              field: "type",
              values: [methods.getTypeList(true).objectId, methods.getTypeList(true).objectArray],
              action: "show"
            },
          ],
        },
        {
          name: "ref",
          label: "Ref",
          type: "Select",
          col: 12,
          multipleCol: 12,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getModels,
              params: {
                // $axios: this.$axios,
                project: this.$route.params.id,
                // isActor:true
              },
              getter: this.models,
              fieldName: "name",
            },
          },
          isHidden: true,
          conditions: [
            {
              field: "type",
              values: [methods.getTypeList(true).objectId, methods.getTypeList(true).objectArray],
              action: "show"
            },
          ],
        },
        {
          name: "model",
          label: "",
          type: "Static",
          col: 12,
          multipleCol: 1,
          multipleMdCol: 1,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.model._id,
        },


        {
          name: "template",
          label: "Template",
          hint: "(Only use for Object or ObjectArray). I inform you that the default template for the object or array of objects will only load if the field is empty.",
          type: "Code",
          col: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          isHidden: true,

          conditions: [
            {
              field: "type",
              values: [methods.getTypeList(true).object, methods.getTypeList(true).objectArray],
              action: "show"
            },
            {
              field: "type",
              values: [methods.getTypeList(true).object],
              action: "default",
              content: `{
  "key":"value"
}`,
            },
            {
              field: "type",
              values: [methods.getTypeList(true).objectArray],
              action: "default",
              content: `[{
  "key":"value"
}]`,
            },
          ],
        },

        {
          name: "sep-advanced-config",
          label: "Advanced Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "250px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "required",
          label: "Required",
          type: "Switch",
          col: 12,
          md: 3,
          multipleCol: 12,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "unique",
          label: "Unique",
          type: "Switch",
          col: 12,
          md: 3,
          multipleCol: 12,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "isSingleObjectArray",
          label: "Is Single Object Array",
          hint: "Refers to a field that is an array containing a single object.",
          type: "Switch",
          col: 12,
          md: 4,
          multipleCol: 3,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          isHidden: true,
          conditions: [
            {
              field: "type",
              values: [
                methods.getTypeList(true).string,
                methods.getTypeList(true).date,
                methods.getTypeList(true).number,
                methods.getTypeList(true).boolean,
                methods.getTypeList(true).objectId
              ],
              action: "show"
            },
          ],
        },
        {
          name: "isFile",
          label: "Is File",
          type: "Switch",
          col: 12,
          md: 3,
          multipleCol: 12,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          conditions: [
            {
              field: "type",
              values: [
                methods.getTypeList(true).string,
                methods.getTypeList(true).array,
              ],
              action: "show"
            },
          ],
        },
        {
          name: "isHash",
          label: "Is Hash",
          type: "Switch",
          col: 12,
          md: 3,
          multipleCol: 12,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          conditions: [
            {
              field: "type",
              values: [
                methods.getTypeList(true).string,
              ],
              action: "show"
            },
          ],
        },
        {
          name: "isHidden",
          label: "Is Hidden",
          type: "Switch",
          col: 12,
          md: 3,
          multipleCol: 12,
          multipleMdCol: 2,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },


      ]
    },
    getModelsFieldsData() {
      return [
        //   {
        //   model: {},
        //   fields: this.getAttributeFieldsData()
        // }
      ]
    },
    lockBtn() {
      let result = false
      if ([methods.getWorkspaceMemberRoles(true).guest, methods.getWorkspaceMemberRoles(true).reporter].includes(this.workspaceMemberRole)) {
        result = true
      }
      if (this.model.isLock) {
        result = true
      }

      return result

    },
    manageShowModel() {
      this.showModelDialog = !this.showModelDialog;
    },

    editModel() {
      if (this.isOpen == true && this.alertConfirmEdit == false) {
        this.alertConfirmEdit = true
      } else {
        this.modelDialog = !this.modelDialog;
        this.callBackModel = updateModel;
        this.modelModal = this.model;
      }

    },
    async onSuccess() {
      this.attributeDialog = false;
      this.showAttributeDialog = false;
      this.multipleAttribute = true;
      this.modelDialog = false;
      this.showModelDialog = false;
      this.fromCloneId = null
      this.$emit("success");
      //   await this.initialize()
      // this.$refs.classDiagram.initialize()
    },
    async deleteModel() {
      methods.dispatchDeleteDialog(this.$store, {
        dialog: true,
        what: "Do you really want to delete this model",
        callback: deleteModel,
        data: { id: this.model._id },
        successHandler: () => {
          // this.$emit('fetch');
          this.onSuccess();
        },
        description: "Every attribute,class ... associated will be deleted too",
      });
    },
    async cloneModel() {
      this.fromCloneId = this.model._id

      this.modelModal = {
        fromClone: this.model._id,
        name: `${this.model.name} Copied`,
        description: this.model.description || '',
        isActor: this.model.isActor || false,
        color: this.model.color,
        updatedAuth: this.model.updatedAuth,
        deletedAuth: this.model.deletedAuth,


      };

      this.modelDialog = !this.modelDialog;
      this.callBackModel = createModel;
    },
    manageShowAttribute(attribute) {
      this.showAttributeDialog = !this.showAttributeDialog;

      this.attributeModal = attribute;
    },
    addAttribute() {
      this.attributeDialog = !this.attributeDialog;
      this.callBackAttribute = createAttribute;
      this.multipleAttribute = true;
      this.attributeModal = {
        type: "String",
      };
    },
    editAttribute(attribute) {
      this.multipleAttribute = false;
      this.attributeDialog = !this.attributeDialog;
      this.callBackAttribute = updateAttribute;
      this.attributeModal = attribute;
    },
    cloneAttribute(attribute) {
      delete attribute._id;
      this.multipleAttribute = false;
      this.attributeDialog = !this.attributeDialog;
      this.callBackAttribute = createAttribute;
      this.attributeModal = attribute;
    },

    deleteAttribute(attribute) {
      methods.dispatchDeleteDialog(this.$store, {
        dialog: true,
        what: "Do you really want to delete this attribute",
        callback: deleteAttribute,
        data: { id: attribute._id },
        successHandler: () => {
          this.onSuccess();
        },
        description: "Every place where we use this attribute will be remove",
      });
    },
  },
};
</script>

<style>
.card {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01),
    0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09),
    0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 20px;
}

.search-container input {
  width: 100%;
  height: 50px;
  border: 0;
  outline: none;
  font-size: 13px;
}

.categories {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 0px 20px 20px 20px;
}

.categories button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0.5px 0.5px #efefef, 0px 1px 0.5px rgba(239, 239, 239, 0.5);
  border-radius: 20px;
  font-weight: 600;
  color: #171718;
  transition: all 0.6s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.categories button:hover {
  transform: scale(1.05) translateY(-3px);
}

.results {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.results p {
  padding: 0 20px;
}

p.label {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #5f6368;
}

.results .entry {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  gap: 12px;
  padding: 5px 20px;
  transition: all 0.2s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.results .entry:hover {
  background-color: #ecfbf16c;
}

.results .results-list .entry:hover .icon {
  background-color: #00b33c;
}

.results .results-list .entry:hover .icon svg path,
.results .results-list .entry:hover .icon svg circle {
  stroke: #ffffff;
}

.results .results-list .entry:hover label {
  color: #00b33c;
}

.results .results-list .entry:hover span {
  color: #00b33c9e;
}

.results .results-list .entry:hover * {
  color: #00b33c9e;
}

.results .results-list {
  display: flex;
  flex-direction: column;
}

.results .entry .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ececec;
  border-radius: 7px;
  transition: all 0.2s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.results .entry .desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.results .entry .desc label {
  display: block;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #171718;
}

.results .entry .desc span {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #72767c;
}

.results .entry .badge {
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background: #ddffde;
  border: 1px solid #08581d0e;
  border-radius: 7px;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #08581d;
}

.card-footer {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 10px;
  padding: 0 7px;
  background: #f8f8f8;
}

.card .action {
  display: flex;
  align-items: center;
  gap: 5px;
}

.card .action p {
  padding-left: 5px;
}

.card-footer button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 0;
  background: #ececec;
  outline: none;
}

.edit-actions {
  width: 100%;
  text-align: right;
  padding-right: 10px;
  opacity: 0.6;
}

/* switch */
.switch-holder {
  display: flex;
  padding: 10px 20px;
  border-radius: 10px;
  /* box-shadow: -8px -8px 15px rgba(255, 255, 255, .7),
        10px 10px 10px rgba(0, 0, 0, .2),
        inset 8px 8px 15px rgba(255, 255, 255, .7),
        inset 10px 10px 10px rgba(0, 0, 0, .2); */
  justify-content: space-between;
  align-items: center;
}

.switch-label {
  padding: 0 20px 0 10px;
  box-sizing: border-box;
}

.switch-label i {
  margin-right: 5px;
}

.switch-toggle {
  height: 40px;
}

.switch-toggle input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -2;
}

.switch-toggle input[type="checkbox"]+label {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset -8px -8px 15px rgba(255, 255, 255, 0.6),
    inset 10px 10px 10px rgba(0, 0, 0, 0.25);
}

.switch-toggle input[type="checkbox"]+label::before {
  position: absolute;
  content: "NO";
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  top: 8px;
  left: 8px;
  width: 45px;
  height: 25px;
  border-radius: 20px;
  background-color: #eeeeee;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.5),
    3px 3px 5px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-in-out;
}

.switch-toggle input[type="checkbox"]:checked+label::before {
  left: 50%;
  content: "YES";
  color: #fff;
  background-color: #00b33c;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.5), 3px 3px 5px #00b33c;
}
</style>