import axios from "axios";

import { methods } from "@/utils/constant"
class ParentController {

    static methods() {
        return methods
    }

    static getModels({ project, isActor = '' }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/project/${project}/model?isActor=${isActor}`)
                .then(res => {
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }


    /**
     * Base controller method
     * @param {*} options 
     * @returns 
     */
    static getBaseControllerMethodContent(options = {
        constantCode: "",
        preSchema: "",
        postSchema: "",
        postAction: "",
        preAction: "",
        profile: ""

    }) {
        let {
            name,
            constantCode,
            response,
            action,
            postAction,
            preAction,
            preResolve,
            preReject,
            resourcePart,
            argument,
        } = options



        if (!argument && typeof argument != 'string') {
            argument = `schema = {}, match = {matchUpdatedBy:{}}`
        }
        let strResponse
        strResponse = `${response}`

        if (name == 'getSystemListAlgo') {
            // console.log(options)
        }

        // if (response.includes('resolve')) {
        //     strResponse = `${response}`

        // } else {

        //     strResponse = `resolve(${response});`
        // }
        //  if(){
        //     strResponse = `resolve(${response});`
        //  }


        let output = `
         
            return new Promise(async (resolve,reject)=>{
                try {
                     /*<ConstantCode>*/${constantCode || ''}
                     /*</ConstantCode>*/
    
                    /*<PreAction>*/${preAction || ''}
                    /*</PreAction>*/
    
                     /*<Action>*/
                    ${action || ''}
                    /*</Action>*/
                    
                     /*<PostAction>*/${postAction || ''}
                     /*</PostAction>*/
    
                     /*<ResourcePart>*/${resourcePart || ''}
                  /*</ResourcePart>*/
    
                     /*<PreResolve>*/${preResolve || ''}
                     /*</PreResolve>*/
    
                   
    
                    
                 /*<Response>*/
                 ${strResponse || ''}
                 /*</Response>*/
                } catch (error) {
                    /*<PreReject>*/${preReject ||''}
                    /*</PreReject>*/
                    reject(error);
                }
               })
               
               `

        return output


    }

    /**
     * Controller method
     * @param {*} options 
     * @returns 
     */
    static getControllerMethodContent(options = {
        constantCode: "",
        preSchema: "",
        postSchema: "",
        postAction: "",
        preAction: "",
        profile: ""

    }) {
        let {
            constantCode,
            preSchema,
            schemaPart,
            postSchema,
            preAction,
            action,
            postAction,
            preResolve,
            response,
        } = options




        let strResponse
        strResponse = `${response}`


        // if (response.includes('resolve')) {
        //     strResponse = `${response}`

        // } else {

        //     strResponse = `resolve(${response});`
        // }
        //  if(){
        //     strResponse = `resolve(${response});`
        //  }


        let output = `
        /*<ConstantCode>*/
            ${constantCode || ''}
        /*</ConstantCode>*/

         /*<PreSchema>*/
         ${preSchema || ''}
         
         /*</PreSchema>*/
         
         /*<Schema>*/
         ${schemaPart || ''}
         
         /*</Schema>*/
         
         /*<PostSchema>*/
         ${postSchema || ''}
         /*</PostSchema>*/
         
         /*<PreAction>*/
         ${preAction || ''}
         /*</PreAction>*/
         
         /*<Action>*/
         ${action || ''}
         
         /*</Action>*/
         
         /*<PostAction>*/
         ${postAction || ''}
         /*</PostAction>*/
         /*<PreResolve>*/
         ${preResolve || ''}
         /*</PreResolve>*/

         /*<Response>*/
         ${strResponse || ''}
         /*</Response>*/
               `

        return output


    }

}
export default ParentController