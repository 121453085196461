<template>
    <div class="container white">
        <div>
            <div v-if="backendGitLabSshLink">
                <label for="sshContent" class="sm-title">Clone with SSH</label>
                <div class="content-copy">
                    <input type="text" v-model="backendGitLabSshLink" class="content-copy-input" id="sshContent"
                        readonly="readonly">
                    <span class="content-copy-btn-content">
                        <button class="elevation-0   px-2"
                            @click.prevent="copyToClipboard(backendGitLabSshLink, 'sshcontent')"><v-icon size=".9rem">{{
                                sshContentIsCopied == false ?
                                    'mdi-content-copy' : 'mdi-clipboard-check-outline' }}</v-icon>

                        </button>
                    </span>
                </div>
            </div>
            <div v-if="backendGitLabHttpsLink">
                <label for="sshContent" class="sm-title">Clone with HTTPS</label>
                <div class="content-copy">
                    <input type="text" v-model="backendGitLabHttpsLink" class="content-copy-input" id="sshContent"
                        readonly="readonly">
                    <span class="content-copy-btn-content">
                        <button class="elevation-0   px-2"
                            @click.prevent="copyToClipboard(backendGitLabHttpsLink, 'httpscontent')"><v-icon
                                size=".9rem">{{
                                    httpsContentIsCopied == false ?
                                        'mdi-content-copy' : 'mdi-clipboard-check-outline' }}</v-icon>

                        </button>
                    </span>
                </div>
            </div>
            <div v-if="backendGitLabSshLink">
                <div>
                    <p class="sm-title mb-3 mt-3">Open in your IDE</p>
                    <a v-for="link, i of ideLinks" class="ide-link d-block mb-2" :key="i" :href="link.url"> {{ link.name
                        }}</a>
                </div>
            </div>

        </div>
        <div>
            <p class="sm-title mb-3 mt-3">Download backend source code</p>
            <div>
                <div>
                    <v-switch class="py-0 my-0" v-model="obfuscateCode" :label="`Obfuscate code`"></v-switch>

                </div>
                <div>
                    <v-btn rounded class="elevation-0 white--text" small color="green" :loading="headBtnLoading"
                        @click="exportZipProject">Download Zip
                    </v-btn>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import { methods } from "@/utils/constant.js";
import {
    exportZipProject,
} from "@/api/user.js";
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        backendGitLabSshLink: {
            type: String,
            required: true
        },
        backendGitLabHttpsLink: {
            type: String,
            required: true
        },
    },
    computed: {
        ideLinks() {
            const links = []

            if (this.backendGitLabSshLink) {
                links.push({
                    name: "Visual Studio Code (SSH)",
                    url: `vscode://vscode.git/clone?url=${this.backendGitLabSshLink}`
                })
            }
            if (this.backendGitLabHttpsLink) {
                links.push({
                    name: "Visual Studio Code (HTTPS)",
                    url: `vscode://vscode.git/clone?url=${this.backendGitLabHttpsLink}`
                })
            }

            return links
        }
    },
    data() {
        return {
            headBtnLoading: false,
            obfuscateCode: false,
            exportCodeType: "Backend",
            sshContentIsCopied: false,
            httpsContentIsCopied: false,
        }
    },
    methods: {
        async exportZipProject() {
            try {
                this.headBtnLoading = true;

                const url = await exportZipProject({
                    id: this.id,
                    obfuscateCode: this.obfuscateCode,
                    exportCodeType: this.exportCodeType,
                });
                methods.clickURL(url);
                methods.dispatchSuccess(this.$store);
                // this.initialize();
            } catch (error) {
                console.log(error);
                methods.dispatchError(this.$store);
            } finally {
                this.headBtnLoading = false;
                this.$emit("success");
            }
        },
        copyToClipboard(data, type) {
            if (!navigator.clipboard) {
                console.error('Clipboard API not supported');
                return;
            }
            navigator.clipboard.writeText(data).then(() => {
                console.log('Data copied to clipboard successfully!');
                switch (type) {
                    case 'sshcontent':
                        this.sshContentIsCopied = true
                        break;
                    case 'httpscontent':
                        this.httpsContentIsCopied = true
                        break;

                    default:
                        break;
                }
                setTimeout(() => {
                    this.sshContentIsCopied = false
                    this.httpsContentIsCopied = false

                }, 2000)
            }).catch(err => {
                console.error('Failed to copy data to clipboard: ', err);
            });
        }
    }
}
</script>

<style>
.content-copy {
    display: flex;
    width: 100%;
    background-color: rgb(248, 248, 248);
    border: 1px solid rgb(200, 200, 200);
    border-radius: 5px;
    padding: 0 .2rem;

}

.content-copy-input {
    padding-right: .5rem;
    display: block;
    width: 90%;
    outline: none;

}

.content-copy-btn-content {
    border-left: 1px solid rgb(200, 200, 200);

}

.sm-title {
    font-size: .8rem;
    font-weight: bold;

}

.ide-link {
    text-decoration: none;
    color: rgb(45, 45, 45) !important;
}
</style>