<template>
    <div>
        <div v-if="app" class="marchine-container w-100   ">
            <div class="marchine-terminal_toolbar d-md-flex justify-space-between ">
                <div class="butt pt-2 pt-md-0">
                    <button class="marchine-btn marchine-btn-color" @click="closeDialog"></button>
                    <button class="marchine-btn"></button>
                    <button class="marchine-btn"></button>
                </div>
                <p class="user  mt-md-0"><span class="">orangcode@admin:</span> ~</p>
                <div class="add_tab">
                </div>
            </div>
            <div class="terminal_body px-3 pt-12  pt-md-10">
                <div v-for="item, i of terminalData" :key="i" class="terminal_promt ">
                    <div v-if="item.type == 'text'" class="terminal_user " v-html="item.content"></div>
                    <div v-if="item.type == 'error'" class="terminal_user red--text ">{{ item.content }}</div>
                    <div v-if="item.type == 'image'" class=" ">
                        <img :src="'data:image/png;base64,' + item.content" class="terminal-image" />
                    </div>
                </div>
                <div class="terminal_promt pb-8 ">
                    <span class="terminal_location">~</span>
                    <!-- <span class="terminal_bling">$ {{ terminalText }} </span> -->
                    <input type="text" @keyup.enter="sendCommand" v-model="text" :style="inputStyle"
                        @input="adjustWidth" class=" overflow-input terminal_input " />
                    <!-- <span class="terminal_cursor"></span> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { methods } from "@/utils/constant.js";
import {
    connectionToServerApp,
} from "@/api/user.js";
export default {
    props: {
        app: {
            required: true,
            type: Object
        }

    },
    watch: {
        text() {



        }
    },
    computed: {
        inputStyle() {
            return {
                width: this.inputWidth,
                maxWidth: `${this.maxWidth}px`
            };
        }
    },
    methods: {
        async connectToserverApp() {
            try {
                this.loading = true
                await connectionToServerApp({
                    id: this.app._id
                })
                this.success = true
                await methods.sleep(3000)
                methods.dispatchSuccess(this.$store, "SSH connection established");
                this.$emit("success")
            } catch (error) {
                methods.dispatchError(this.$store, "SSH connection failed.");
                console.log(error)
                this.success = false
            }
            finally {
                this.loading = false
                await methods.sleep(3000)
                this.initialize()

            }
        },
        closeDialog() {
            this.$emit("closeDialog")
        },
        sendCommand() {
            if (this.text) {
                this.$socket.emit('serverAppterminalCommand', this.text)
                this.text = ''

            }
        },
        initialize() {
            this.success = null
            this.loading = false
        },
        adjustWidth() {
            const tempSpan = document.createElement('span');
            tempSpan.style.visibility = 'hidden';
            tempSpan.style.whiteSpace = 'pre';
            tempSpan.textContent = this.text || ' ';
            document.body.appendChild(tempSpan);
            this.inputWidth = `${Math.min(tempSpan.offsetWidth, this.maxWidth)}px`;
            document.body.removeChild(tempSpan);
        }
    },
    data() {
        return {
            loading: false,
            success: null,
            terminalText: "",
            text: '',
            terminalData: [{
                type: "text",
                content: 'ssh connection'
            }],
            // inputWidth: 'auto',
            // maxWidth: 1000 // Taille maximale en pixels
        }
    },

    beforeRouteLeave(to, from, next) {
        if (this.app && this.app._id) {
            this.$socket.emit('closeServerAppTerminal', this.app._id)
        }

        next();

    },
    beforeDestroy(){
        if (this.app && this.app._id) {
            this.$socket.emit('closeServerAppTerminal', this.app._id)
        }
    },
    async mounted() {
        // await this.connectToserverApp()
        this.$socket.emit('openServerAppTerminal', this.app._id)

    },
    sockets: {
        serverAppterminalOutput(data) {
            this.terminalData.push(data)
        }
    }
};
</script>

<style>
.marchine-container {
    width: 100%;
    /* height: 100%; */
    min-height: 50vh;
    /* background: #212121; */
    background: lightgrey;
    position: relative;


}

.marchine-terminal_toolbar {
    /* display: flex; */
    height: 30px;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* background: #212121; */
    /* justify-content: space-between; */
    position: fixed;
    width: 100%;
    background: lightgrey;

}

.butt {
    display: flex;
    align-items: center;
}

.marchine-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 5px;
    font-size: 8px;
    height: 12px;
    width: 12px;
    box-sizing: border-box;
    border: none;
    border-radius: 100%;
    /* background: linear-gradient(#7d7871 0%, #595953 100%); */
    background: linear-gradient(#b6b2ae 0%, #d3d3cd 100%);
    /* text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2); */
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 1px 0px #41403A, 0px 1px 1px 0px #474642;
}

.marchine-btn-color {
    background: #ee411a;
}

.marchine-btn:hover {
    cursor: pointer;
}

.marchine-btn:focus {
    outline: none;
}

.butt--exit {
    background: linear-gradient(#f37458 0%, #de4c12 100%);
}

.add_tab {
    /* border: 1px solid #000;
    color: #000;
    padding: 0 6px;
    border-radius: 4px 4px 0 0;
    border-bottom: none;
    cursor: pointer; */
}

.user {
    /* color: #d5d0ce; */
    color: #000;
    margin-left: 6px;
    font-size: 14px;
    line-height: 15px;
}

.terminal_body {
    /* background: rgba(0, 0, 0, 0.6); */
    background: lightgray;
    height: calc(100% - 30px);
    padding-top: 2px;
    margin-top: -1px;
    font-size: 12px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow-y: scroll;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.overflow-input {}

.terminal_promt {
    display: flex;
}

.terminal_promt span {
    margin-left: 4px;
}

.terminal_user {
    /* color: #1eff8e; */
    color: #1c1b75;
}

.terminal_location {
    color: #4878c0;
}

.terminal_bling {
    /* color: #dddddd; */
    color: #141414;
}

.terminal_input {
    width: 95%;

    overflow: hidden;
    white-space: nowrap;
    outline: none;
}

.terminal_cursor {
    display: block;
    height: 14px;
    width: 5px;
    margin-left: 10px;
    animation: curbl 1200ms linear infinite;
}

@keyframes curbl {

    0% {
        background: #000;
    }

    49% {
        background: #000;
    }

    60% {
        background: transparent;
    }

    99% {
        background: transparent;
    }

    100% {
        background: #000;
    }
}
</style>