<template>
  <div class="tab-container class-diagram">
    <div v-if="!forMobile">
      <div v-if="classList" class="d-flex flex-row pr-6 pt-3">
        <div v-for="model of classList" :key="model._id">
          <class-card @refresh="onSuccess" :model="model" :controllerMethodList="controllerMethodList"
            :workspaceMemberRole="workspaceMemberRole" />
        </div>
      </div>
    </div>
    <div v-else>
      <v-tabs v-model="tab" next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline"
        show-arrows>
        <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->

        <v-tab v-for="model of classList" :key="model._id">
          {{ model.name }}
        </v-tab>

    
        <v-tabs-items class="" v-model="tab">
          <v-tab-item class="tab-items  dash erd-tab"  v-for="model of classList" :key="model._id">
            <class-card @refresh="onSuccess"  :forMobile="true"  :model="model" :controllerMethodList="controllerMethodList"
            :workspaceMemberRole="workspaceMemberRole" />
          </v-tab-item>
          
        </v-tabs-items>
      </v-tabs>
    </div>


    <v-dialog v-model="dialogClass" max-width="50rem">
      <editor-x @success="onSuccess" :fields="classFields" :callback="callBackClass" :model="classModel" title="Class"
        v-if="dialogClass" />
    </v-dialog>

    <!-- show class method -->
    <v-dialog v-model="showDialogClassMethod" max-width="600px">
      <v-card v-if="showDialogClassMethod" elevation="0">
        <v-card-title>
          <span class="headline">{{ methodModel.name }} ~ {{ methodModel.methodType }}</span>
        </v-card-title>
        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-switch disabled label="Activé" color="green" v-model="methodModel.isActive"></v-switch>
          <v-btn color="orange darken-1" text @click="editMethod(methodModel)">
            Edit
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteAttribute(methodModel)">
            Delete
          </v-btn>
          <v-btn color="green darken-1" text @click="cloneAttribute(methodModel)">
            Clone
          </v-btn>
        </v-card-actions>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4"> </v-col>
              <v-col cols="12" md="4"> </v-col>
            </v-row>
            <pre>
                {{ methodModel.preview }}
              </pre>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMethod" max-width="50rem">
      <editor-x @success="onSuccess" :fields="methodFields" :callback="callBackMethod" :model="methodModel"
        title="Method" v-if="dialogMethod" />
    </v-dialog>
  </div>
</template>

<script>
import EditorX from '@/components/universal/EditorX.vue'
import {
  // manageClass,
  manageClassMethod
} from '@/api/user.js'
import { methods } from '@/utils/constant.js'
import { mapGetters, mapActions } from 'vuex'
import ClassCard from './ClassCard.vue'
export default {
  components: { EditorX, ClassCard },
  props: {
    forMobile: {
      type: Boolean,
      default: false
    },
    classList: {
      type: Array,
      default: () => [],
    },
    workspaceMemberRole: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions(['getModels']),


    editMethod(item) {
      this.dialogMethod = true
      // this.listId = item.model && item.model._id ? item.model._id : item.model
      this.methodModel = item
      this.callBackMethod = manageClassMethod
    },

    addMethod(model) {
      // this.card = {}
      this.classModel = model
      this.methodModel = {}
      this.dialogMethod = true
      // this.listId = model._id

      switch (model.classType) {
        // case 'route':
        //   break
        // case 'baseroute':
        //   break
        case 'controller':
          this.methodModel.methodType = 'controllermethod'
          break
        case 'basecontroller':
          this.methodModel.methodType = 'basecontrollermethod'
          break
        case 'validation':
          this.methodModel.methodType = 'validationmethod'
          break
        case 'resource':
          this.methodModel.methodType = 'resourcemethod'
          break
        case 'constant':
          this.methodModel.methodType = 'constantmethod'
          break
        case 'auth':
          this.methodModel.methodType = 'authmethod'
          break
        default:

          this.dialogMethod = false
          break
      }

      this.callBackMethod = manageClassMethod
    },
    initialize() {
      try {
        this.dialogClass = false
        this.dialogMethod = false
        this.showDialogClassMethod = false
        this.classModel = {}
        this.methodModel = {}
      } catch (error) {
        console.log(error)
      }
    },
    onSuccess() {
      this.$emit('refresh')
      this.initialize()
    },

  },

  data() {
    return {
      dialogClass: false,
      dialogMethod: false,
      showDialogClassMethod: false,
      callBackClass: null,
      callBackMethod: null,
      classModel: {},
      methodModel: {},
      tab: null,
    }
  },

  computed: {
    ...mapGetters(['models', 'project']),
    classFields() {
      let result = []

      switch (this.classModel.classType) {
        case 'server':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },

            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },
            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preview',
              label: 'Server.js',
              type: 'Preview',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'httpAction',
              label: 'Http Action',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break
        case 'baseroute':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },

            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },
            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constructorCode',
              label: 'Constructor',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break
        case 'route':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constructorCode',
              label: 'Constructor',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'validation':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'basecontroller':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constantCode',
              label: 'Constructor Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'resources',
              label: 'Constructor Resources',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'models',
              label: 'Constructor Models',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'controller':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },
            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constructorCode',
              label: 'Constructor',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'resource':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constructorCode',
              label: 'Constructor',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'constant':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break
        case 'auth':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        default:
          break
      }
      return result
    },
    methodFields() {
      let result = []

      switch (this.methodModel.methodType) {
        case 'validationmethod':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classMethodType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.methodModel.methodType,
            },
            {
              name: 'validation',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel._id,
            },
            {
              name: 'preview',
              label: 'Preview code',
              type: 'Preview',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'validationSchema',
              label: 'Validation Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'additionalSchema',
              label: 'Additional Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'constantmethod':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'constant',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel._id,
            },
            {
              name: 'classMethodType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.methodModel.methodType,
            },

            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preview',
              label: 'Preview code',
              type: 'Preview',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },
            // {
            //   name: 'comment',
            //   label: 'Comment your code',
            //   type: 'Code',
            //   col: 12,
            //   isRequiredForUpdate: false,
            //   isRequiredForAdd: false,
            //   existForUpdate: true,
            //   existForAdd: true,
            // },

            {
              name: 'content',
              label: 'Content',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break
        case 'authmethod':
          result = [
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'auth',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel._id,
            },
            {
              name: 'classMethodType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.methodModel.methodType,
            },

            {
              name: 'color',
              label: 'Color',
              type: 'Color',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'preview',
              label: 'Preview code',
              type: 'Preview',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'type',
              label: 'Type',
              select: {
                data: methods.getAuthMethodTypeList(false),
              },
              type: 'Select',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: false,
              existForAdd: true,
            },

            {
              name: 'argument',
              label: 'Argument',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            // {
            //   name: 'constantCode',
            //   label: 'Constant',
            //   type: 'Code',
            //   col: 12,
            //   isRequiredForUpdate: false,
            //   isRequiredForAdd: false,
            //   existForUpdate: true,
            //   existForAdd: true,
            // },

            {
              name: 'content',
              label: 'Content',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'basecontrollermethod':
          result = [
            {
              name: 'baseController',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel._id,
            },
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classMethodType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.methodModel.methodType,
            },
            {
              name: 'preview',
              label: 'Preview code',
              type: 'Preview',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            // {
            //   name: 'codeType',
            //   label: 'Method Type ',
            //   select: {
            //     data: methods.getAuthMethodTypeList(false),
            //   },
            //   type: 'Select',
            //   col: 12,
            //   isRequiredForUpdate: false,
            //   isRequiredForAdd: false,
            //   existForUpdate: false,
            //   existForAdd: true,
            // },

            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preAction',
              label: 'Pre Action',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'action',
              label: 'Action',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'postAction',
              label: 'Post Action',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preResource',
              label: 'Pre Resource',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'resourcePart',
              label: 'Resource',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'postResource',
              label: 'Post Resource',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preResolve',
              label: 'Pre resolve',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'response',
              label: 'Response',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preReject',
              label: 'Pre reject',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'controllermethod':
          result = [
            {
              name: 'controller',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel._id,
            },
            {
              name: '_id',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
              value: this.methodModel ? this.methodModel._id : undefined,
            },
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classMethodType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.methodModel.methodType,
            },
            {
              name: 'preview',
              label: 'Preview code',
              type: 'Preview',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'type',
              label: 'Method Type',
              type: 'Select',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: false,
              existForAdd: true,
              select: {
                data: methods.getClassMethodTypeList(false),
              },
            },

            {
              name: 'method',
              label: 'Method',
              type: 'Select',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              select: {
                data: methods.getRequestMethodTypeList(false),
              },
            },
            {
              name: 'auth',
              label: 'Auth',
              type: 'Select',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              select: {
                store: {
                  action: this.getModels,
                  params: {
                    withAuthMiddleware: true,
                    $axios: this.$axios,
                    project: this.$route.params.id,
                  },
                  getter: this.models,
                  fieldName: 'name',
                },
              },
            },
            {
              name: 'routeUrl',
              label: 'Route Url',
              type: 'String',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'preSchema',
              label: 'Pre Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'schemaPart',
              label: 'Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'postSchema',
              label: 'Post Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'preAction',
              label: 'Pre Action',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'action',
              label: 'Action',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'postAction',
              label: 'Post Action',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'preResolve',
              label: 'Pre resolve',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'response',
              label: 'Response',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preReject',
              label: 'Pre reject',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        case 'resourcemethod':
          result = [
            {
              name: 'resource',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel._id,
            },
            {
              name: 'project',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: 'classMethodType',
              label: '',
              type: 'Static',
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.methodModel.methodType,
            },
            {
              name: 'name',
              label: 'Name',
              type: 'String',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'constantCode',
              label: 'Constant',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'preSchema',
              label: 'Pre Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'schemaPart',
              label: 'Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'postSchema',
              label: 'Post Schema',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: 'preResolve',
              label: 'Pre resolve',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'response',
              label: 'Response',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: 'preReject',
              label: 'Pre reject',
              type: 'Code',
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ]
          break

        default:
          break
      }
      return result
    },
    controllerMethodList() {
      let output = []

      for (let item of this.classList) {
        if (item.classType == 'controller') {
          for (let method of item.methods) {
            output.push({
              _id: method._id,
              name: method.name
            })

          }
        }
      }

      return output
    }
  },
  watch: {
    classList() {
      if (!this.tab && Array.isArray(this.classList) && this.classList[0]) {
        this.tab = this.classList[0]._id
      }
    }
  }
}
</script>

<style></style>