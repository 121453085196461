<template>
  <div  :class="forMobile ? 'container' : false">
    <div class="" v-if="model">

      <div class="list-card rounded mr-12">
        <div class="white--text py-2 px-4 list-head rounded d-flex justify-space-between align-center"
          :style="`background-color:${model.color}`">
          <div>
            <span class="font-weight-bolder">{{ methods.limitTextCharacters(model.name,40) }} </span>
            <v-icon small class="ml-3" color="white">{{
              getClassIcon(model.classType)
              }}</v-icon>
          </div>
          <div class="d-flex">
            <v-btn @click="manageShowClass" color="white" icon>
              <v-icon>mdi-dots-horizontal</v-icon></v-btn>
          </div>
        </div>

        <div class="list-body px-2 py-1">
          <div class="my-2">
            <h6 class="my-2">Methods</h6>
            <div v-for="method of model.methods" :key="method._id"
              class="px-2 list-item py-2 d-flex justify-space-between mb-1 rounded" @click="showMethod(method)">
              <span class="list-text">{{ method.name }} </span>
              <v-icon size="18px" v-if="method.ref && method.ref._id" :color="method.ref.color">mdi-key-variant</v-icon>
            </div>
          </div>

          <v-btn v-if="[
            'controller',
            'basecontroller',
            'validation',
            'resource',
            'constant',
            'auth',
          ].includes(model.classType)
          " depressed :disabled="btnLock()" @click="addMethod(model)" class="mt-4 w-100 btn">Add Method</v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialogMethod" max-width="60rem">
      <editor-x @success="onSuccess" v-bind:fields.sync="methodFields" :callback="callBackMethod" :model="methodModel"
        title="Method" v-if="dialogMethod" :submit-text="methodModel && methodModel._id
          ? 'Update Class Method'
          : 'Create Class Method'
          " />
    </v-dialog>

    <!-- show model -->
    <v-dialog v-model="showClassDialog" max-width="600px">
      <div class="card">
        <!-- name -->
        <div class="px-5 py-3">
          <h3 class="h4-title">{{ model.name }}</h3>
          <p class="h5-description mb-0 mt-2">
            {{ model.description }}
          </p>
        </div>

        <!-- type , additional type -->

        <!-- type  -->
        <div>
          <p v-if="model.model && model.model._id" class="mb-0 px-5 py-3">
            <span class="h5-title">Model :</span>
            <span class="h5-description">
              {{ model.model && model.model._id ? model.model.name : "" }}</span>
          </p>
          <p v-if="model.methods && Array.isArray(model.methods)" class="mb-0 px-5 py-3">
            <span class="h5-title">Methods :</span>
            <span class="h5-description"> {{ model.methods.length }}</span>
          </p>
          <div v-for="(relation, i) of model.relationships" :key="i">
            <p class="mb-0 px-5 h5-title py-3">{{ relation.type }}</p>
            <div class="px-2">
              <v-chip-group active-class="primary--text ">
                <v-chip v-for="(partner, a) in relation.partners" :key="a" outlined>
                  {{ partner.class ? partner.class.name : partner }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>
        </div>

        <!-- {{  }} -->
        <!-- auth -->
        <div class="results mt-5">
          <!-- <div>
            <p class="label">Auth</p>
            <div class="results-list">
              <div class="entry">
                <div class="icon">
                  <v-icon>mdi-plus</v-icon>
                </div>
                <div class="desc">
                  <label>{{
                    model.createAuth && model.createAuth._id
                      ? model.createAuth.name
                      : "None"
                  }}</label>
                  <span
                    >This collection will use as default auth for corresponding
                    router
                    <span class="font-weight-bolder">post</span> method</span
                  >
                </div>
              </div>
            </div>
          </div> -->

          <hr />

          <!-- is actor , is active  -->
          <div class="mb-2">
            <v-row class="w-100">
              <v-col cols="6">
                <div class="switch-holder">
                  <div class="switch-label">
                    <v-icon size="18px" class="mr-2">mdi-watch-vibrate</v-icon>
                    
                    <span class="font-weight-bolder">Is Activated : </span>
                    <span>{{ model.isActive ? 'Yes' :'No' }} </span>
                  </div>
                
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <hr />

        <div
          v-if="[methods.getWorkspaceMemberRoles(true).owner, methods.getWorkspaceMemberRoles(true).developer, methods.getWorkspaceMemberRoles(true).maintainer].includes(workspaceMemberRole)"
          class="py-4 pr-3">
          <div class="d-flex justify-end">
            <button v-if="[
            'controller',
            'basecontroller',
            'validation',
            'resource',
            'constant',
            'auth',
          ].includes(model.classType)"
           @click="addMethod(model)" class="m-btn-dense ml-2">
              <span><v-icon size="16px" color="green" class="mr-1">mdi-plus</v-icon>
                Add Method
              </span>
            </button>
            <button @click="editClass" class="m-btn-dense ml-2">
              <span><v-icon size="16px" color="green" class="mr-1">mdi-pencil</v-icon>
                Edit
              </span>
            </button>

            <!-- <button class="m-btn-dense ml-2">
              <span><v-icon size="16px" color="green" class="mr-1">mdi-content-copy</v-icon>
                Clone
              </span>
            </button> -->

            <button @click="deleteClass" class="m-btn-dense ml-2">
              <span><v-icon size="16px" color="green" class="mr-1">mdi-trash-can</v-icon>
                Delete
              </span>
            </button>
          </div>
        </div>
      </div>
    </v-dialog>

    <!-- dialog class -->

    <v-dialog v-model="dialogClass" max-width="50rem">
      <editor-x @success="onSuccess" :fields="classFields" :callback="callBackClass" :model="classModel" title="Class"
        v-if="dialogClass" :submit-text="classModel && classModel._id ? 'Update Class' : 'Create Class'
          " />
    </v-dialog>

    <!-- show method -->
    <v-dialog v-model="showDialogClassMethod" max-width="600px">
      <div class="card">
        <!-- name -->
        <div class="px-5 py-3">
          <h3 class="h4-title">{{ methodModel.name }}</h3>
          <p class="h5-description mb-0 mt-2">
            {{ methodModel.description }}
          </p>
        </div>

        <!-- type , additional type -->
        <!-- {{ methodModel.auth }} -->
        <!-- type  -->
        <div>
          <p class="mb-0 px-5 py-3">
            <span class="h5-title">Type :</span>
            <span class="h5-description"> {{ methodModel.methodType }}</span>
          </p>
          <p v-if="methodModel.auth && methodModel.auth._id" class="mb-0 px-5 py-3">
            <span class="h5-title">Auth :</span>
            <span class="h5-description"> {{ methodModel.auth.name }}</span>
          </p>
          <hr />
          <p class="mb-0 px-5 pt-3 pb-1">
            <span class="h5-title"> Class {{ model.name }} Relation : </span>
          </p>
          <div v-for="(relation, i) of model.relationships" :key="i">
            <p class="mb-0 px-5 pb-3">
              <span class="h5-description"> {{ relation.type }} </span>
            </p>
            <div class="px-2">
              <v-chip-group active-class="primary--text ">
                <v-chip v-for="(partner, a) in relation.partners" :key="a" outlined>
                  {{ partner.class ? partner.class.name : partner }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>
        </div>

        <!-- {{  }} -->
        <!-- auth -->
        <div class="results mt-5">
          <!-- <div>
            <p class="label">Auth</p>
            <div class="results-list">
              <div class="entry">
                <div class="icon">
                  <v-icon>mdi-plus</v-icon>
                </div>
                <div class="desc">
                  <label>{{
                    model.createAuth && model.createAuth._id
                      ? model.createAuth.name
                      : "None"
                  }}</label>
                  <span
                    >This collection will use as default auth for corresponding
                    router
                    <span class="font-weight-bolder">post</span> method</span
                  >
                </div>
              </div>
            </div>
          </div> -->

          <hr />

          <!-- is actor , is active  -->
          <div class="mb-2">
            <v-row class="w-100">
              <v-col cols="6">
                <div class="switch-holder">
                  <div class="switch-label">
                    <v-icon size="18px" class="mr-2">mdi-watch-vibrate</v-icon>
                    <span class="font-weight-bolder">Is Activated : </span>
                    <span>
                      {{ model.isActive ? 'Yes' :'No' }} 
                    </span>
                  </div>
                
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <hr />

        <div
          v-if="[methods.getWorkspaceMemberRoles(true).owner, methods.getWorkspaceMemberRoles(true).developer, methods.getWorkspaceMemberRoles(true).maintainer].includes(workspaceMemberRole)"
          class="py-4 pr-3">
          <div class="d-flex justify-end">
            <button @click="editMethod(methodModel)" class="m-btn-dense ml-2">
              <span><v-icon size="16px" color="green" class="mr-1">mdi-pencil</v-icon>
                Edit
              </span>
            </button>

            <button class="m-btn-dense ml-2 d-none">
              <span><v-icon size="16px" color="green" class="mr-1">mdi-content-copy</v-icon>
                Clone
              </span>
            </button>

            <button @click="deleteClassMethod(methodModel)" class="m-btn-dense ml-2">
              <span><v-icon size="16px" color="green" class="mr-1">mdi-trash-can</v-icon>
                Delete
              </span>
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import EditorX from "../universal/EditorX.vue";
import { manageClass, manageClassMethod, deleteClassMethod } from "@/api/user.js";
import { methods, rules } from "@/utils/constant.js";
import BaseControllerMethods from "@/data/BaseControllerMethods.js";
import ControllerMethods from "@/data/ControllerMethods.js";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    forMobile: {
      type: Boolean,
      default: true,
    },
    model: {
      type: Object,
      required: true,
    },
    workspaceMemberRole: {
      type: String,
      required: true,
    },
    controllerMethodList: {
      type: Array,
      default: () => ([]),
    },
    activesSubscription: null
  },
  components: { EditorX },
  data() {
    return {
      showClassDialog: false,
      dialogClass: false,
      dialogMethod: false,
      showDialogClassMethod: false,
      callBackClass: null,
      callBackMethod: null,
      classModel: {},
      methodModel: {},
      setFieldValue: false,
      methodFieldsData: this.getMethodFields(),
      methods,
    };
  },
  computed: {
    ...mapGetters(["models", "project"]),

    classFields() {
      let result = [];

      switch (this.classModel.classType) {
        case "server":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },

            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },
            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "preview",
              label: "Server.js",
              type: "Preview",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: false,
            },

            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "httpAction",
              label: "Http Action",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;
        case "baseroute":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },

            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },
            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "constructorCode",
              label: "Constructor",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;
        case "route":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "name",
              label: "Name",
              type: "String",
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "constructorCode",
              label: "Constructor",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;

        case "validation":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;

        case "basecontroller":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "constantCode",
              label: "Constructor Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "resources",
              label: "Constructor Resources",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "models",
              label: "Constructor Models",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;

        case "controller":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },
            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "name",
              label: "Name",
              type: "String",
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "constructorCode",
              label: "Constructor",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;

        case "resource":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "name",
              label: "Name",
              type: "String",
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
            {
              name: "constructorCode",
              label: "Constructor",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;

        case "constant":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "name",
              label: "Name",
              type: "String",
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;
        case "auth":
          result = [
            {
              name: "project",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.$route.params.id,
            },
            {
              name: "classType",
              label: "",
              type: "Static",
              col: 0,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
              value: this.classModel.classType,
            },

            {
              name: "color",
              label: "Color",
              type: "Color",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "name",
              label: "Name",
              type: "String",
              col: 12,
              isRequiredForUpdate: true,
              isRequiredForAdd: true,
              existForUpdate: true,
              existForAdd: true,
            },

            {
              name: "constantCode",
              label: "Constant",
              type: "Code",
              col: 12,
              isRequiredForUpdate: false,
              isRequiredForAdd: false,
              existForUpdate: true,
              existForAdd: true,
            },
          ];
          break;

        default:
          break;
      }
      return result;
    },
    methodFields: {
      get() {
        return this.methodFieldsData;
      },
      set(fields) {
        this.setFieldValue = true;
        const outputFields = []
        for (let field of fields) {
          if (field.select && Array.isArray(field.select.objectData) && field.select.objectData.length > 0) {
            if (Array.isArray(field.select.data) && field.select.data.length > 0) {
              // outputFields.push(field)
            } else {
              field.select.data = methods.getNameList(
                field.select.objectData,
                field.select.fieldName || 'name'
              );

            }
          }
          outputFields.push(field)
        }
        // console.log("fields", fields)
        // console.log("outputFields", outputFields)
        this.methodFieldsData = outputFields;
      },
    },
  },
  watch: {
    methodModel() {
      this.methodFieldsData = this.getMethodFields();
      if (this.setFieldValue == false) {
        //
      }
    },

    models() {
      if (this.setFieldValue == false) {
        this.methodFieldsData = this.getMethodFields();
        // console.log("feature", this.getFeatureFields());
      }
    },
  },
  async mounted() {
    await this.initialize();
  },
  methods: {
    ...mapActions(["getModels"]),
    btnLock() {
      let result = false
      if ([methods.getWorkspaceMemberRoles(true).guest, methods.getWorkspaceMemberRoles(true).reporter].includes(this.workspaceMemberRole)) {
        result = true
      }

      if (this.model.isLock) {
        result = true
      }
      return result
    },
    manageShowClass() {
      this.showClassDialog = !this.showClassDialog;
    },
    getClassIcon(name) {
      let icon;
      switch (name) {
        case "route":
          icon = "mdi-routes";
          break;
        case "baseroute":
          icon = "mdi-router";
          break;
        case "controller":
          icon = "mdi-controller-classic";
          break;
        case "basecontroller":
          icon = "mdi-gamepad";
          break;
        case "validation":
          icon = "mdi-security";
          break;
        case "resource":
          icon = "mdi-poll";
          break;
        case "constant":
          icon = "mdi-function";
          break;
        case "auth":
          icon = "mdi-lock";
          break;
        case "server":
          icon = "mdi-server";
          break;

        default:
          icon = "mdi-help";

          break;
      }

      return icon;
    },


    deleteClassMethod(app) {
      methods.dispatchDeleteDialog(this.$store, {
        dialog: true,
        what: "Do you really want to delete this app",
        callback: deleteClassMethod,
        data: { id: app._id, classMethodType: app.methodType },
        successHandler: () => {
          this.onSuccess();
        },
        description: "You can not come back to your decision",
      });
    },
    editClass() {
      this.dialogClass = true;
      this.callBackClass = manageClass;
      this.classModel = this.model;
    },
    editMethod(item) {
      this.dialogMethod = true;
      // this.listId = item.model && item.model._id ? item.model._id : item.model
      this.methodModel = item;
      this.callBackMethod = manageClassMethod;
    },
    deleteClass() { },
    addMethod(model) {
      // this.card = {}
      this.classModel = model;
      this.methodModel = {};
      this.dialogMethod = true;
      // this.listId = model._id

      this.methodModel.project = this.$route.params.id;

      switch (model.classType) {
        // case 'route':
        //   break
        // case 'baseroute':
        //   break
        case "controller":
          this.methodModel.methodType = "controllermethod";
          this.methodModel.controllerModel = this.classModel.model.name;
          this.methodModel.baseController = this.classModel.baseController;

          break;
        case "basecontroller":
          this.methodModel.methodType = "basecontrollermethod";
          break;
        case "validation":
          this.methodModel.methodType = "validationmethod";
          break;
        case "resource":
          this.methodModel.methodType = "resourcemethod";
          break;
        case "constant":
          this.methodModel.methodType = "constantmethod";
          break;
        case "auth":
          this.methodModel.methodType = "authmethod";
          break;
        default:
          this.dialogMethod = false;
          break;
      }

      this.callBackMethod = manageClassMethod;
    },
    async initialize() {
      try {
        await this.getModels({
          $axios: this.$axios,
          project: this.$route.params.id,
        });

        this.dialogClass = false;
        this.dialogMethod = false;
        this.showDialogClassMethod = false;
        this.showClassDialog = false;
        this.callBackClass = null;
        this.callBackMethod = null;
        this.classModel = {};
        this.methodModel = {};
      } catch (error) {
        console.log(error);
      } finally {
        // this.methodFieldsData = this.getMethodFields();
      }
    },
    onSuccess() {
      this.$emit("refresh");
      this.initialize();
    },
    showMethod(method) {
      this.methodModel = method;
      this.classModel = this.model;
      this.showDialogClassMethod = true;
    },
    getMethodFields() {
      let result = [];
      let xfields = []

      if (this.methodModel) {
        switch (this.methodModel.methodType) {
          case "validationmethod":
            result = [
              {
                name: "project",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.$route.params.id,
              },
              {
                name: "classMethodType",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel.methodType,
              },
              {
                name: "validation",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.classModel._id,
              },
              {
                name: "preview",
                label: "Preview code",
                type: "Preview",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: false,
              },
              {
                name: "name",
                label: "Name",
                type: "String",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                rules: [
                  rules.diff([].concat(this.model.methods.map(item => (this.methodModel.name && item.name == this.methodModel.name ? '&' : item.name)), "This validation method name is not available")),
                ]
              },

              // {
              //   name: "model",
              //   label: "Model",
              //   type: "Select",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              //   select: {
              //     store: {
              //       action: this.getModels,
              //       params: {
              //         withAuthMiddleware: true,
              //         $axios: this.$axios,
              //         project: this.$route.params.id,
              //       },
              //       getter: this.models,
              //       fieldName: "name",
              //     },
              //   },
              // },
              // {
              //   name: "controllerMethod",
              //   label: "Controller Method",
              //   type: "Select",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              //   select: {
              //     data: [],
              //     objectData: this.controllerMethodList,
              //     fieldName: "name",
              //     fieldValue: "_id",
              //   },
              // },

              {
                name: "content",
                label: "Content",
                type: "Code",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              // {
              //   name: "validationSchema",
              //   label: "Validation Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "additionalSchema",
              //   label: "Additional Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
            ];
            break;

          case "constantmethod":
            result = [
              {
                name: "project",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.$route.params.id,
              },
              {
                name: "constant",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.classModel._id,
              },
              {
                name: "classMethodType",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel.methodType,
              },

              {
                name: "color",
                label: "Color",
                type: "Color",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              {
                name: "preview",
                label: "Preview code",
                type: "Preview",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: false,
              },
              {
                name: "name",
                label: "Name",
                type: "String",
                col: 12,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                rules: [
                  rules.diff([].concat(this.model.methods.map(item => (this.methodModel.name && item.name == this.methodModel.name ? '&' : item.name))), "This constant method name is not available"),

                ]
              },
              // {
              //   name: "comment",
              //   label: "Comment your code",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

              {
                name: "content",
                label: "Content",
                type: "Code",
                col: 12,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
              },
            ];
            break;
          case "authmethod":
            result = [
              {
                name: "project",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.$route.params.id,
              },
              {
                name: "auth",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.classModel._id,
                rules: [
                  rules.diff([].concat(this.model.methods.map(item => (this.methodModel.name && item.name == this.methodModel.name ? '&' : item.name))), "This auth method name is not available"),
                ]
              },
              {
                name: "classMethodType",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel.methodType,
              },

              {
                name: "color",
                label: "Color",
                type: "Color",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },

              {
                name: "preview",
                label: "Preview code",
                type: "Preview",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: false,
              },
              {
                name: "name",
                label: "Name",
                type: "String",
                col: 12,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
              },
              {
                name: "type",
                label: "Type",
                select: {
                  data: methods.getAuthMethodTypeList(false),
                },
                type: "Select",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: false,
                existForAdd: true,
              },

              {
                name: "argument",
                label: "Argument",
                type: "Code",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              // {
              //   name: 'constantCode',
              //   label: 'Constant',
              //   type: 'Code',
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

              {
                name: "content",
                label: "Content",
                type: "Code",
                col: 12,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
              },
            ];
            break;

          case "basecontrollermethod":
            xfields = [
            {
                name: methods.getBaseControllerClassMethodTypeList(true).blank,
                icon: "mdi-code-json",
                badge: "free",
                color: "green",
                authorizePlans: ["free", "standard", "advanced"]

              },
            {
                name: methods.getBaseControllerClassMethodTypeList(true).manage,
                icon: "mdi-code-json",
                badge: "free",
                color: "green",
                authorizePlans: ["free", "standard", "advanced"]

              },
            ]

            result = [
              {
                name: "baseController",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.classModel._id,
              },
              {
                name: "project",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.$route.params.id,
              },
              {
                name: "classMethodType",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel.methodType,
                rules: [
                  rules.diff([].concat(this.model.methods.map(item => (this.methodModel.name && item.name == this.methodModel.name ? '&' : item.name))), "This base controller method name is not available"),
                ]
              },
              {
                name: "preview",
                label: "Preview code",
                type: "Preview",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: false,
              },

              {
                name: "algoType",
                label: "Algo type",
                type: "SelectX",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                userPlan: this.activesSubscription && this.activesSubscription.plan ? this.activesSubscription.plan.toLowerCase() : "free",
                select: {
                  // data: methods.getBaseControllerClassMethodTypeList(false),
                  data: xfields,
                },
                conditionFields: [
                  {
                    value:
                      methods.getBaseControllerClassMethodTypeList(true).blank,
                    fields: [
                      {
                        name: "name",
                        label: "Name",
                        type: "String",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .blank,
                      },

                      {
                        name: "content",
                        label: "Content",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .blank,
                      },
                      // {
                      //   name: "constantCode",
                      //   label: "Constant",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "preAction",
                      //   label: "Pre Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "action",
                      //   label: "Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "postAction",
                      //   label: "Post Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "preResource",
                      //   label: "Pre Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "resourcePart",
                      //   label: "Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "postResource",
                      //   label: "Post Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "preResolve",
                      //   label: "Pre resolve",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "response",
                      //   label: "Response",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                      // {
                      //   name: "preReject",
                      //   label: "Pre reject",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .blank,
                      // },
                    ],
                  },
                  {
                    value:
                      methods.getBaseControllerClassMethodTypeList(true).manage,
                    fields: [
                      {
                        name: "manageModel",
                        label: "Model",
                        type: "Select",
                        col: 12,
                        md: 8,
                        multipleCol: 2,
                        multipleMdCol: 2,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        select: {
                          store: {
                            action: this.getModels,
                            params: {
                              $axios: this.$axios,
                              project: this.$route.params.id,
                              // isActor:true
                            },
                            getter: this.models,
                            fieldName: "name",
                          },
                        },

                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .manage,
                      },
                      {
                        name: "button",
                        label: "Update Code",
                        color: "primary",
                        type: "Button",
                        action: {
                          type: "return-model",
                          function: BaseControllerMethods.manageAlgo,
                        },
                        col: 12,
                        md: 4,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .manage,
                      },
                      {
                        name: "sep-code",
                        label: "Associated Code",
                        type: "Separator",
                        size: "12px",
                        weight: "600",
                        width: "180px",
                        align: "left",
                        textType: "left",
                        col: 12,
                        md: 12,
                        multipleCol: 2,
                        multipleMdCol: 3,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .manage,
                      },
                      {
                        name: "name",
                        label: "Name",
                        type: "String",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .manage,
                      },
                      {
                        name: "argument",
                        label: "Argument",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .manage,
                      },
                      {
                        name: "content",
                        label: "Content",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category:
                          methods.getBaseControllerClassMethodTypeList(true)
                            .manage,
                      },
                      // {
                      //   name: "constantCode",
                      //   label: "Constant",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "preAction",
                      //   label: "Pre Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "action",
                      //   label: "Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "postAction",
                      //   label: "Post Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "preResource",
                      //   label: "Pre Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "resourcePart",
                      //   label: "Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "postResource",
                      //   label: "Post Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "preResolve",
                      //   label: "Pre resolve",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "response",
                      //   label: "Response",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                      // {
                      //   name: "preReject",
                      //   label: "Pre reject",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category:
                      //     methods.getBaseControllerClassMethodTypeList(true)
                      //       .manage,
                      // },
                    ],
                  },
                ],
              },
            ];
            break;

          case "controllermethod":

            xfields = [
              {
                name: methods.getClassMethodTypeList(true).blank,
                icon: "mdi-code-json",
                badge: "free",
                color: "green",
                authorizePlans: ["free", "standard", "advanced"]

              },
              {
                name: methods.getClassMethodTypeList(true).seedJson,
                icon: "mdi-code-json",
                badge: "free",
                color: "green",
                authorizePlans: ["free", "standard", "advanced"]

              },
              {
                name: methods.getClassMethodTypeList(true).getListMethod,
                icon: "mdi-code-json",
                badge: "free",
                color: "green",
                authorizePlans: ["free", "standard", "advanced"]

              },
              {
                name: methods.getClassMethodTypeList(true).showMethod,
                icon: "mdi-code-json",
                badge: "free",
                color: "green",
                authorizePlans: ["free", "standard", "advanced"]

              },
            ]

            result = [
              {
                name: "controller",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.classModel._id,
              },
              {
                name: "baseController",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel.baseController,
              },
              {
                name: "_id",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel ? this.methodModel._id : undefined,
              },
              {
                name: "project",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.$route.params.id,
              },
              {
                name: "classMethodType",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel.methodType,
              },

              {
                name: "preview",
                label: "Preview code",
                type: "Preview",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: false,
              },
              {
                name: "sep-code-config",
                label: "Method Config",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "180px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              {
                name: "method",
                label: "Method",
                type: "Select",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                select: {
                  data: methods.getRequestMethodTypeList(false),
                },
              },
              {
                name: "auth",
                label: "Auth",
                type: "Select",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                select: {
                  store: {
                    action: this.getModels,
                    params: {
                      withAuthMiddleware: true,
                      $axios: this.$axios,
                      project: this.$route.params.id,
                    },
                    getter: this.models,
                    fieldName: "name",
                  },
                },
              },
              {
                name: "routeUrl",
                label: "Route Url",
                type: "String",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                rules: [
                  rules.diff([].concat(this.model.methods.map(item => typeof item.routeUrl == 'string' ? (this.methodModel.routeUrl && item.routeUrl.trim() == this.methodModel.routeUrl ? '&' : item.routeUrl.trim()) : (this.methodModel.routeUrl && item.routeUrl == this.methodModel.routeUrl ? '&' : item.routeUrl))), "This route url is not available"),
                ]
              },

              {
                name: "sep-code-algo",
                label: "Algo",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "180px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },

              {
                name: "type",
                label: "Method Type",
                type: "SelectX",
                col: 12,
                md: 8,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: false,
                existForAdd: true,
                userPlan: this.activesSubscription && this.activesSubscription.plan ? this.activesSubscription.plan.toLowerCase() : "free",

                select: {
                  data: xfields,
                },
                conditionFields: [
                  {
                    value: methods.getClassMethodTypeList(true).blank,
                    fields: [],
                  },
                  {
                    value: methods.getClassMethodTypeList(true).seedJson,
                    fields: [
                      {
                        name: "sep-basecontroller-code",
                        label: "Base controller Method",
                        type: "Separator",
                        size: "12px",
                        weight: "600",
                        width: "180px",
                        align: "left",
                        textType: "left",
                        col: 12,
                        md: 12,
                        multipleCol: 2,
                        multipleMdCol: 3,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).seedJson,
                      },

                      {
                        name: "baseControllerAlgoType",
                        label: "Algo type",
                        type: "Select",
                        col: 12,
                        md: 6,

                        isRequiredForUpdate: false,
                        isRequiredForAdd: true,
                        existForUpdate: true,
                        existForAdd: true,
                        select: {
                          data: [
                            methods.getBaseControllerClassMethodTypeList(true)
                              .manage,
                          ],
                        },
                        category: methods.getClassMethodTypeList(true).seedJson,
                      },

                      {
                        name: "updateAlgoCode",
                        label: "Update Base Controller method Code",
                        color: "primary",
                        type: "Button",
                        action: {
                          type: "return-model",
                          function: ControllerMethods.updateCode,
                        },
                        col: 12,
                        md: 6,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).seedJson,
                      },
                      {
                        name: "baseControllerMethodName",
                        label: "Base controller Method Name",
                        type: "String",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).seedJson,
                      },
                      {
                        name: "baseControllerMethodArgument",
                        label: "Base Controller Method Argument",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).seedJson,
                      },
                      {
                        name: "baseControllerMethodContent",
                        label: "Base controller Method Content",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).seedJson,
                      },
                      // {
                      //   name: "baseControllerMethodConstantCode",
                      //   label: "Base controller Method Constant",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodPreAction",
                      //   label: "Pre Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodAction",
                      //   label: "Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodPostAction",
                      //   label: "Post Action",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodPreResource",
                      //   label: "Pre Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodResourcePart",
                      //   label: "Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodPostResource",
                      //   label: "Post Resource",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodPreResolve",
                      //   label: "Pre resolve",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodResponse",
                      //   label: "Response",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      // {
                      //   name: "baseControllerMethodPreReject",
                      //   label: "Pre reject",
                      //   type: "Code",
                      //   col: 12,
                      //   isRequiredForUpdate: false,
                      //   isRequiredForAdd: false,
                      //   existForUpdate: true,
                      //   existForAdd: true,
                      //   category: methods.getClassMethodTypeList(true).seedJson,
                      // },
                      {
                        name: "manageModel",
                        label: "",
                        type: "Static",
                        col: 0,
                        isRequiredForUpdate: true,
                        isRequiredForAdd: true,
                        existForUpdate: true,
                        existForAdd: true,
                        value: "",
                      },
                    ],
                  },
                  {
                    value: methods.getClassMethodTypeList(true).getListMethod,
                    fields: [
                      {
                        name: "sep-basecontroller-code",
                        label: "Base controller Method",
                        type: "Separator",
                        size: "12px",
                        weight: "600",
                        width: "180px",
                        align: "left",
                        textType: "left",
                        col: 12,
                        md: 12,
                        multipleCol: 2,
                        multipleMdCol: 3,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).getListMethod,
                      },

                      {
                        name: "baseControllerAlgoType",
                        label: "Algo type",
                        type: "Select",
                        col: 12,
                        md: 6,

                        isRequiredForUpdate: false,
                        isRequiredForAdd: true,
                        existForUpdate: true,
                        existForAdd: true,
                        select: {
                          data: [
                            methods.getBaseControllerClassMethodTypeList(true)
                              .getListAlgo,
                          ],
                        },
                        category: methods.getClassMethodTypeList(true).getListMethod,
                      },

                      {
                        name: "updateAlgoCode",
                        label: "Update Base Controller method Code",
                        color: "primary",
                        type: "Button",
                        action: {
                          type: "return-model",
                          function: ControllerMethods.updateCode,
                        },
                        col: 12,
                        md: 6,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).getListMethod,
                      },
                      {
                        name: "baseControllerMethodName",
                        label: "Base controller Method Name",
                        type: "String",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).getListMethod,
                      },
                      {
                        name: "baseControllerMethodArgument",
                        label: "Base Controller Method Argument",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).getListMethod,
                      },
                      {
                        name: "baseControllerMethodContent",
                        label: "Base controller Method Content",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).getListMethod,
                      },

                      {
                        name: "manageModel",
                        label: "",
                        type: "Static",
                        col: 0,
                        isRequiredForUpdate: true,
                        isRequiredForAdd: true,
                        existForUpdate: true,
                        existForAdd: true,
                        value: "",
                      },
                    ],
                  },
                  {
                    value: methods.getClassMethodTypeList(true).showMethod,
                    fields: [
                      {
                        name: "sep-basecontroller-code",
                        label: "Base controller Method",
                        type: "Separator",
                        size: "12px",
                        weight: "600",
                        width: "180px",
                        align: "left",
                        textType: "left",
                        col: 12,
                        md: 12,
                        multipleCol: 2,
                        multipleMdCol: 3,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).showMethod,
                      },

                      {
                        name: "baseControllerAlgoType",
                        label: "Algo type",
                        type: "Select",
                        col: 12,
                        md: 6,

                        isRequiredForUpdate: false,
                        isRequiredForAdd: true,
                        existForUpdate: true,
                        existForAdd: true,
                        select: {
                          data: [
                            methods.getBaseControllerClassMethodTypeList(true)
                              .showAlgo,
                          ],
                        },
                        category: methods.getClassMethodTypeList(true).showMethod,
                      },

                      {
                        name: "updateAlgoCode",
                        label: "Update Base Controller method Code",
                        color: "primary",
                        type: "Button",
                        action: {
                          type: "return-model",
                          function: ControllerMethods.updateCode,
                        },
                        col: 12,
                        md: 6,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).showMethod,
                      },
                      {
                        name: "baseControllerMethodName",
                        label: "Base controller Method Name",
                        type: "String",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).showMethod,
                      },
                      {
                        name: "baseControllerMethodArgument",
                        label: "Base Controller Method Argument",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).showMethod,
                      },
                      {
                        name: "baseControllerMethodContent",
                        label: "Base controller Method Content",
                        type: "Code",
                        col: 12,
                        isRequiredForUpdate: false,
                        isRequiredForAdd: false,
                        existForUpdate: true,
                        existForAdd: true,
                        category: methods.getClassMethodTypeList(true).showMethod,
                      },

                      {
                        name: "manageModel",
                        label: "",
                        type: "Static",
                        col: 0,
                        isRequiredForUpdate: true,
                        isRequiredForAdd: true,
                        existForUpdate: true,
                        existForAdd: true,
                        value: "",
                      },
                    ],
                  },
                ],
              },

              {
                name: "button",
                label: "Update Code",
                color: "primary",
                type: "Button",
                action: {
                  type: "return-model",
                  function: ControllerMethods.updateCode,
                },
                col: 12,
                md: 4,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },

              {
                name: "sep-code",
                label: "Controller method Code",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "180px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },

              {
                name: "name",
                label: "Name",
                type: "String",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                rules: [
                  rules.diff([].concat(this.model.methods.map(item => (this.methodModel.name && item.name == this.methodModel.name ? '&' : item.name))), "This controller method name is not available"),
                ]
              },
              {
                name: "content",
                label: "Content",
                type: "Code",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              // {
              //   name: "constantCode",
              //   label: "Constant",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

              // {
              //   name: "preSchema",
              //   label: "Pre Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "schemaPart",
              //   label: "Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "postSchema",
              //   label: "Post Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

              // {
              //   name: "preAction",
              //   label: "Pre Action",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "action",
              //   label: "Action",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "postAction",
              //   label: "Post Action",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

              // {
              //   name: "preResolve",
              //   label: "Pre resolve",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "response",
              //   label: "Response",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "preReject",
              //   label: "Pre reject",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

            ];
            break;

          case "resourcemethod":
            result = [
              {
                name: "resource",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.classModel._id,
              },
              {
                name: "project",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.$route.params.id,
              },
              {
                name: "classMethodType",
                label: "",
                type: "Static",
                col: 0,
                isRequiredForUpdate: true,
                isRequiredForAdd: true,
                existForUpdate: true,
                existForAdd: true,
                value: this.methodModel.methodType,
              },
              {
                name: "name",
                label: "Name",
                type: "String",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
                rules: [
                  rules.diff([].concat(this.model.methods.map(item => (this.methodModel.name && item.name == this.methodModel.name ? '&' : item.name))), "This resource method name is not available"),
                ]
              },

              {
                name: "preview",
                label: "Preview code",
                type: "Preview",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: false,
              },
              {
                name: "sep-code-config",
                label: "Method Config",
                type: "Separator",
                size: "12px",
                weight: "600",
                width: "180px",
                align: "left",
                textType: "left",
                col: 12,
                md: 12,
                multipleCol: 2,
                multipleMdCol: 3,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              {
                name: "content",
                label: "Content",
                type: "Code",
                col: 12,
                isRequiredForUpdate: false,
                isRequiredForAdd: false,
                existForUpdate: true,
                existForAdd: true,
              },
              // {
              //   name: "constantCode",
              //   label: "Constant",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

              // {
              //   name: "preSchema",
              //   label: "Pre Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "schemaPart",
              //   label: "Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "postSchema",
              //   label: "Post Schema",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },

              // {
              //   name: "preResolve",
              //   label: "Pre resolve",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "response",
              //   label: "Response",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
              // {
              //   name: "preReject",
              //   label: "Pre reject",
              //   type: "Code",
              //   col: 12,
              //   isRequiredForUpdate: false,
              //   isRequiredForAdd: false,
              //   existForUpdate: true,
              //   existForAdd: true,
              // },
            ];
            break;

          default:
            break;
        }
      }
      return result;
    },
  },
};
</script>

<style></style>