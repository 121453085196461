<template>
  <div class="preview-code">
    <v-row>
      <v-col cols="12" md="3" lg="2" class="d-md-block" :class="(selectedFile && selectedFile.preview) ? 'd-none' :'d-block'"> 
        <div class="code-treeview scrollbar-style-14">
          <v-treeview
            v-model="tree"
            :open="initiallyOpen"
            :items="structure"
            activatable
            item-key="_id"
            return-object
            open-on-click
            @update:active="showSelectedFile"
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="!item.file">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
              <v-icon v-else>
                {{ files[item.file] }}
              </v-icon>
            </template>

            <!-- <template slot="label" slot-scope="{ item }">
            <a @click.prevent="showSelectedFile(item)">{{ item.name }}</a>
          </template> -->
          </v-treeview>
        </div>
      </v-col>
      <v-col cols="12" md="9" lg="10" class="d-md-block preview-code-main-container" :class="(selectedFile && selectedFile.preview) ? 'd-block' :'d-none'">
        <!-- {{ active }} -->
        <div v-if="selectedFile && selectedFile.preview" class="py-3">
          <div class="d-flex ">
            <p class="mb-0 grey lighten-4 mb-1 rounded-pill px-5">
              <v-icon> {{ files[selectedFile.file] }}</v-icon>
              <span>{{ selectedFile.name }}{{selectedFile.file ? `.${selectedFile.file}`:'' }}</span>
              <span class="ml-3"><v-btn icon> <v-icon @click="selectedFile =null" > mdi-close</v-icon> </v-btn></span>
            </p>
            
          </div>

          <codemirror
            v-model="selectedFile.preview"
            :code="selectedFile.preview"
            :options="codemirrorOption"
            class="scrollbar-style-14 "
            :lintOptions="{
              sub: true,
              notypeof: true,
            }"
          >
          </codemirror>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
  <script>
import { codemirror } from "vue2-codemirror-lite-js";

export default {
  components: {
    codemirror,
  },
  props: {
    structure: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    initiallyOpen: ["public"],
    files: {
      html: "mdi-language-html5",
      js: "mdi-nodejs",
      json: "mdi-code-json",
      md: "mdi-language-markdown",
      pdf: "mdi-file-pdf",
      png: "mdi-file-image",
      txt: "mdi-file-document-outline",
      xls: "mdi-file-excel",
      scss: "mdi-sass",
      css: "mdi-language-css3",
      vuejs: "mdi-vuejs",
      nuxt: "mdi-nuxt",
    },
    tree: [],

    active: null,
    selectedFile: null,
    codemirrorOption: {
      strict: false,
      tabSize: 2,
      lineNumbers: true,
      lineWrapping: false,
      line: true,
      size: "70vh",
      // gutters: ['CodeMirror-linenumbers', 'CodeMirror-lint-markers'],
      lint: true,
      readOnly: true,
    },
  }),
  methods: {
    showSelectedFile(item) {
      let selected;
      if (Array.isArray(item)) {
        selected = item[0];
      }
      if (!selected.children) {
        this.selectedFile = selected;
      }
    },
  },
};
</script>

<style  >
.preview-code-main-container .CodeMirror {
  border: 1px solid #eee;
  height: 73vh;
}

.code-treeview {
  height: 73vh;
  overflow-y: scroll;
}
</style>