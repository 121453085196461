<template>
    <div>
        <div>
            <div v-if="!backendTestIsSelected" class="pt-5 px-2 px-md-6 px-lg-8">
                <div class="d-flex mb-5">
                    <h3 class="">List of differentes Test groupe</h3>
                    <v-btn @click="addBackendTest" color="primary" class="ml-5 text-capitalize" rounded small>
                        <span><v-icon size="16px" color="white" class="mr-1">mdi-plus</v-icon>
                            Add test
                        </span>
                    </v-btn>
                </div>
                <v-row>
                    <v-col cols="12">
                        <v-simple-table dense>

                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Actions</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Base URL</th>
                                        <th class="text-left">Comment</th>
                                        <th class="text-left">Total Scenario</th>
                                        <th class="text-left">Success Scenario</th>
                                        <th class="text-left">Failed Scenario</th>
                                        <th class="text-left">Not Test Scenario</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="" v-for="(test, i) of backendTests" :key="i">
                                        <td>
                                            <v-menu elevation="0" right offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on" class="mr-10">
                                                        <v-icon color="primary" size="23">mdi-forwardburger</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-tooltip bottom color="warning">

                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn class="" icon>
                                                                <v-icon size="20" @click="editBackendTest(test)"
                                                                    v-bind="attrs" v-on="on"
                                                                    color="warning">mdi-pencil-box-multiple-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Edit Test</span>
                                                    </v-tooltip>
                                                    <v-tooltip v-if="[
                                                        methods.getWorkspaceMemberRoles(true)
                                                            .owner,
                                                        methods.getWorkspaceMemberRoles(true)
                                                            .developer,
                                                        methods.getWorkspaceMemberRoles(true)
                                                            .maintainer,
                                                        methods.getWorkspaceMemberRoles(true)
                                                            .reporter,

                                                    ].includes(project.workspaceMemberRole)
                                                    " bottom color="red">

                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn class="" icon>
                                                                <v-icon size="20" @click="deleteBackendTest(test)"
                                                                    v-bind="attrs" v-on="on"
                                                                    color="red">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Test</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom color="green">

                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn class="" icon>
                                                                <v-icon size="20" @click="selectBackendTest(test)"
                                                                    v-bind="attrs" v-on="on"
                                                                    color="green">mdi-arrow-right-bold</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Show Test scenario</span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom color="black">

                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn @click="simulateBackendTest(test._id)" class="" icon>
                                                                <v-icon size="20" @click="verifyConnection(test)"
                                                                    v-bind="attrs" v-on="on"
                                                                    color="black">mdi-connection</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Simulate</span>
                                                    </v-tooltip>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                        <td>
                                            <div class="d-flex py-1">
                                                <div class="">
                                                    <h3 class="h5-title">
                                                        {{ test.name }}
                                                    </h3>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <h3 class="h5-title">
                                                    {{ test.baseUrl }}
                                                </h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <h3 class="h5-title">
                                                    {{ test.comment }}
                                                </h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <h3 class="h5-title">
                                                    {{ test.totalScenarios }}
                                                </h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <h3 class="h5-title">
                                                    {{ test.totalSuccessScenarios }}
                                                </h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <h3 class="h5-title">
                                                    {{ test.totalFailedScenarios }}
                                                </h3>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <h3 class="h5-title">
                                                    {{ test.totalNotTestScenarios }}
                                                </h3>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
            <div v-if="backendTestIsSelected">
                <backend-test :backendTestId="backendTestId" @closeTest="onCloseTest"
                    :actors="project.project.actors" />
            </div>

        </div>

        <v-dialog v-model="showBackendTestDialog" max-width="50rem">
            <editor-x @success="onSuccess" :fields="backendTestFields" :callback="callBackBackendTest"
                :model="backendTestModal" title="Backend Test" v-if="showBackendTestDialog" :submitText="backendTestModal && backendTestModal._id
                    ? 'Update Backend Test'
                    : 'Create Backend Test'
                    " />
        </v-dialog>
    </div>
</template>

<script>
import BackendTest from "./BackendTest.vue";
import { methods } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";
import {
    createBackendTest,
    updateBackendTest,
    deleteBackendTest,
    simulateBackendTest
} from "@/api/user.js";
import EditorX from "@/components/universal/EditorX.vue";

export default {
    components: { BackendTest,EditorX },
    props: {
        project: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            backendTestIsSelected: false,
            showBackendTestDialog: false,
            loading: false,
            methods: methods
        }
    },
    computed: {
        ...mapGetters([
            "backendTests", "backendTest"]),

        backendTestFields() {
            return [
                {
                    name: "project",
                    label: "",
                    type: "Static",
                    col: 12,
                    isRequiredForUpdate: true,
                    isRequiredForAdd: true,
                    existForUpdate: true,
                    existForAdd: true,
                    value:
                        this.project && this.project.project
                            ? this.project.project._id
                            : "",
                },

                {
                    name: "name",
                    label: "Backend Test Name",
                    placeholder: "EX: Global Test",
                    type: "String",
                    col: 12,
                    multipleCol: 2,
                    multipleMdCol: 3,
                    isRequiredForUpdate: true,
                    isRequiredForAdd: true,
                    existForUpdate: true,
                    existForAdd: true,
                    icon: "mdi-card-bulleted-settings-outline",
                },
                {
                    name: "type",
                    label: "Test Type",
                    hint: "",
                    type: "Select",
                    col: 12,
                    md: 4,
                    multipleCol: 2,
                    multipleMdCol: 3,

                    isRequiredForUpdate: false,
                    isRequiredForAdd: true,
                    existForUpdate: false,
                    existForAdd: true,
                    select: {
                        data: methods.getBackendTestType(false),
                    },
                },
                {
                    name: "comment",
                    label: "Comment",
                    placeholder: "",
                    type: "String",
                    col: 12,
                    multipleCol: 2,
                    multipleMdCol: 3,
                    isRequiredForUpdate: false,
                    isRequiredForAdd: false,
                    existForUpdate: true,
                    existForAdd: true,
                    icon: "mdi-comment",
                },
                {
                    name: "baseUrl",
                    label: "Base Url",
                    type: "String",
                    col: 12,
                    multipleCol: 2,
                    multipleMdCol: 3,
                    isRequiredForUpdate: false,
                    isRequiredForAdd: false,
                    existForUpdate: true,
                    existForAdd: true,
                    icon: "mdi-routes",
                    placeholder: `http://localhost:${this.project.project.port}/api/v1`,

                },
            ];
        },
    },
    methods: {
        ...mapActions([
            "getBackendTests",
            "getBackendTest",
        ]),

        async selectBackendTest(item) {
            try {
                this.headBtnLoading = true;
                this.backendTestId = item._id;

                this.backendTestIsSelected = true;
            } catch (error) {
                console.log(error);
                this.backendTestIsSelected = false;

                methods.dispatchError(this.$store);
            } finally {
                this.headBtnLoading = false;
            }
        },

        onCloseTest() {
            this.backendTestIsSelected = false;
        },
        async initialize() {
            try {
                this.loading = true
                await this.getBackendTests({
                    project: this.project.project._id,
                });
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        addBackendTest() {
            this.showBackendTestDialog = !this.showBackendTestDialog;
            this.callBackBackendTest = createBackendTest;
            this.backendTestModal = {};
        },
        editBackendTest(item) {
            this.showBackendTestDialog = !this.showBackendTestDialog;
            this.callBackBackendTest = updateBackendTest;
            this.backendTestModal = Object.assign({}, item);
        },
        deleteBackendTest(app) {
            methods.dispatchDeleteDialog(this.$store, {
                dialog: true,
                what: "Do you really want to delete this backend test",
                callback: deleteBackendTest,
                data: { id: app._id },
                successHandler: () => {
                    this.onSuccess();
                },
                description: "You can not come back to your decision",
            });
        },
        async simulateBackendTest(id) {
            try {
                this.headBtnLoading = true;

                await simulateBackendTest({
                    id: id,
                    schema: {
                        query: {}
                    }
                });
                methods.dispatchSuccess(this.$store);
                this.initialize();
            } catch (error) {
                console.log(error);
                methods.dispatchError(this.$store);
            } finally {
                this.headBtnLoading = false;
            }
        },
        async onSuccess() {
            await this.initialize();
            this.showBackendTestDialog = false;
        },
    },
    mounted() {
        this.initialize()
    }
}
</script>