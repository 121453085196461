<template>
  <div v-if="backendTest._id" class="pt-5 px-2 px-md-6 px-lg-8">
    <div class="d-lg-flex justify-space-between mb-5">
      <div class="green lighten-5 px-3 py-3 rounded">
        <h3 class="">{{ backendTest.name }}</h3>
      </div>

      <div class="d-none d-md-flex ">

        <div class="mr-1"> <span>Total Scenarios : </span> <span>{{ backendTest.totalScenarios }}</span></div>
        <div class="mr-1"> <span>Total Success Scenarios : </span> <span>{{ backendTest.totalSuccessScenarios }}</span>
        </div>
        <div class="mr-1"> <span>Total Failed Scenarios : </span> <span>{{ backendTest.totalFailedScenarios }}</span>
        </div>
        <div class="mr-1"> <span>Total Not Teste Scenarios : </span> <span>{{ backendTest.totalNotTestScenarios
            }}</span></div>
      </div>

      <div class="mt-3 mt-md-0">
        <v-btn color="green" class="ml-5 text-capitalize white--text elevation-0" rounded small
          @click="simulateBackendTest(backendTest._id)">
          <span><v-icon size="16px" color="white" class="mr-1">mdi-power</v-icon>
            <span class="d-none d-md-inline">Simulate</span>
          </span>
        </v-btn>
        <v-btn @click="closeTest" color="red" class="ml-5 text-capitalize white--text elevation-0" rounded small>
          <span><v-icon size="16px" color="white" class="mr-1">mdi-close</v-icon>
            <span class="d-none d-md-inline"> Close test</span>
          </span>
        </v-btn>
        <v-btn @click="addRequest" color="primary" class="ml-5 text-capitalize white--text elevation-0" rounded small>
          <span><v-icon size="16px" color="white" class="mr-1">mdi-plus</v-icon>

            <span class="d-none d-md-inline">Add Request</span>
          </span>
        </v-btn>
        <v-btn @click="exportBackendTestPostman" color="orange" class="ml-5 text-capitalize white--text elevation-0"
          rounded small>
          <span><v-icon size="16px" color="white" class="mr-1">mdi-export-variant</v-icon>

            <span class="d-none d-md-inline">Export Post Man </span>
          </span>
        </v-btn>
      </div>
    </div>
    <div>
      <v-container fluid>
        <v-expansion-panels flat style="border: 1px solid primary">
          <v-expansion-panel class="elevation-0 pa-0">
            <v-expansion-panel-header>
              Sort / Search ... | Total request found {{ backendTest.totalScenario }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field color="primary" label="Search Request Name" class="pt-5 pa-2" filled
                    prepend-inner-icon="mdi-magnify" dense v-model="backendTestScenarioQuery.scenarioName" outlined flat
                    background-color="grey lighten-4" rounded></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field color="primary" label="Search Request Route" class="pt-5 pa-2" filled
                    prepend-inner-icon="mdi-magnify" dense v-model="backendTestScenarioQuery.scenarioRoute" outlined
                    flat background-color="grey lighten-4" rounded></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select :items="['Yes', 'No']" class="pt-5 pa-2" label="Got Expected Status"
                    v-model="backendTestScenarioQuery.scenarioGotExpectedStatus" dense outlined rounded></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select :items="['get', 'post', 'put', 'delete']" class="pt-5 pa-2" label="Method"
                    v-model="backendTestScenarioQuery.scenarioMethod" dense outlined rounded></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select :items="[200, 201, 204, 400, 401, 403, 404, 500, 600, 601]" class="pt-5 pa-2" label="Status"
                    v-model="backendTestScenarioQuery.scenarioStatus" dense outlined rounded></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-btn @click="applySearch" color="primary" class="ml-5 text-capitalize white--text elevation-0"
                    rounded small>
                    <span><v-icon size="16px" color="white" class="mr-1">mdi-magnify</v-icon>
                      Apply Search
                    </span>
                  </v-btn>
                  <v-btn @click="clearScenarioSearch" color="red" class="ml-5 text-capitalize white--text elevation-0"
                    rounded small>
                    <span><v-icon size="16px" color="white" class="mr-1">mdi-close</v-icon>
                      Clear Search
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </div>
    <div>
      <v-row>
        <v-col cols="12" md="3" lg="2" class="d-md-block"
          :class="(backendTestModel && backendTestModel.method) ? 'd-none' : 'd-block'">
          <div class="code-treeview scrollbar-style-14">
            <v-treeview v-model="tree" :open="initiallyOpen" :items="backendTest.structure" activatable item-key="_id"
              return-object open-on-click @update:active="showSelectedFile">
              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="!item.file">
                  {{ open ? "mdi-folder-open" : "mdi-folder" }}
                </v-icon>
                <v-icon v-else>
                  <!-- {{ files[item.file] }} -->
                  mdi-code-json
                </v-icon>
              </template>
            </v-treeview>
          </div>
        </v-col>
        <v-col cols="12" md="9" lg="10" class="backend-test-main-container d-md-block"
          :class="(backendTestModel && backendTestModel.method) ? 'd-block' : 'd-none'">
          <!-- {{ active }} -->
          <div v-if="backendTestModel && backendTestModel.method">
            <div>
              <!-- head of request  -->
              <div class="">
                <div class="d-md-flex justify-space-between">
                  <div>
                    <h3 v-show="!backendTestModel.editTitle">
                      {{ backendTestModel.name }}
                      <span class="ml-3"><v-btn @click="backendTestModel.editTitle = true" icon color="grey"
                          small><v-icon>mdi-pencil</v-icon></v-btn></span>
                      <v-btn icon @click="backendTestModel = null"> <v-icon>mdi-close</v-icon> </v-btn>

                    </h3>
                    <v-text-field v-show="backendTestModel.editTitle" dense v-model="backendTestModel.name"
                      label="Request name" append-icon="mdi-check-underline"
                      @click:append="backendTestModel.editTitle = false"></v-text-field>

                  </div>
                  <div class="d-flex mt-4 mt-md-0">


                    <div>
                      <button @click="saveScenario(backendTestModel)" class="m-btn-dense mx-0">
                        <span><v-icon size="16px" color="green" class="mr-1">mdi-content-save-outline</v-icon>
                          Save
                        </span>
                      </button>
                      <v-menu v-model="commentMenu" :close-on-content-click="false" :nudge-width="200" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <button v-bind="attrs" v-on="on" class="m-btn-dense ml-3">
                            <span><v-icon size="16px" color="green" class="mr-1">mdi-comment</v-icon>
                              Comment
                            </span>
                          </button>
                        </template>

                        <v-card class="pt-5">
                          <v-text-field color="green" prepend-inner-icon="mdi-comment" class="px-5" outlined dense
                            v-model="backendTestModel.comment">
                          </v-text-field>
                          <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="green" text @click="commentMenu = false">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>
                </div>
                <div class="px-0 py-0 mt-5">
                  <v-combobox @change="saveScenario(backendTestModel, { silent: true })"
                    v-model="backendTestModel.expectedStatues"
                    :items="[200, 201, 204,300,301,302,303,304, 400, 401, 403, 404, 500, 600, 601]" chips clearable label="Expected status"
                    multiple prepend-icon="mdi-filter-variant" outline dense>
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip v-bind="attrs" :input-value="selected" close @click="select" @click:close="remove(item)">
                        <strong>{{ item }}</strong>&nbsp;
                      </v-chip>
                    </template>
                  </v-combobox>

                </div>
                <div class="rounded py-1 my-8 border-grey d-flex align-center px-2">
                  <div class="html_select_container border-r">
                    <select ref="scenarioMethod" name="" v-model="backendTestModel.method" id="method"
                      class="method_select rounded py-1 px-1" :class="getMethodClass(backendTestModel.method)">
                      <option value="get" :class="getMethodClass('get')">
                        Get
                      </option>
                      <option value="post" :class="getMethodClass('post')">
                        Post
                      </option>
                      <option value="put" :class="getMethodClass('put')">
                        Put
                      </option>
                      <option value="delete" :class="getMethodClass('delete')">
                        Delete
                      </option>
                    </select>
                    <span @click="onClickToChevron" class="html_select_chevron"><v-icon>mdi-chevron-down</v-icon></span>
                  </div>
                  <div>
                    <span class="ml-2 font-weight-bold purple--text" v-text="routeString"></span>
                    <input @change="saveScenario(backendTestModel, { silent: true })" type="text" class="url_input"
                      placeholder="system/version" v-model="backendTestModel.route" />
                  </div>
                  <v-btn class="blue white--text rounded ml-1 elevation-0 justify-self-end" small :loading="loadingBtn"
                    @click="simulateBackendTestScenario(backendTestModel)"
                    :disabled="backendTestModel._id ? false : true">Send</v-btn>
                </div>
                <div>
                  <v-tabs v-model="requestScenarioTab" background-color="dash" class="mt-3">
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab v-for="item in [
    //   'Params',
    'AUTHORIZATION',
    'HEADERS',
    'BODY',
  ]" :key="item">
                      {{ item }}
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items class="input-request " v-model="requestScenarioTab">
                    <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab request-body" :key="'AUTHORIZATION'">
                      <div>
                        <p class="mb-6 mt-2">
                          This authorization method will be used for every
                          request in this collection. You can override this by
                          specifying one in the request.
                        </p>

                        <v-row>
                          <v-col cols="4">
                            <v-select :items="['No Auth', 'API Key', 'ACTOR']" label="Type"
                              v-model="backendTestModel.authType" dense outlined></v-select>
                          </v-col>
                          <v-col cols="4">
                            <div v-if="['ACTOR'].includes(backendTestModel.authType)">
                              <div>
                                <v-select clear-icon="mdi-close-circle" clearable :items="actorNames" dense
                                  v-model="backendTestModel.actor" label="Actor" outlined></v-select>
                              </div>

                            </div>
                            <div v-if="['API Key', 'ACTOR'].includes(backendTestModel.authType)">
                              <div>
                                <v-text-field @change="saveScenario(backendTestModel, { silent: true })"
                                  clear-icon="mdi-close-circle" clearable label="Key" v-model="backendTestModel.authKey"
                                  dense outlined></v-text-field>
                              </div>
                              <div>
                                <v-text-field @change="saveScenario(backendTestModel, { silent: true })"
                                  clear-icon="mdi-close-circle" clearable label="Value"
                                  v-model="backendTestModel.authToken" dense outlined></v-text-field>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-tab-item>
                    <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab request-body" :key="'HEADERS'">
                      <p class="">Comming soon</p>
                    </v-tab-item>
                    <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab request-body w-100 " :key="'BODY'">
                      <v-radio-group v-model="backendTestModel.bodyType" row>
                        <v-radio color="blue" label="none" value="none"></v-radio>
                        <v-radio color="orange" label="form-data" value="formdata"></v-radio>
                        <v-radio color="red" label="raw" value="raw"></v-radio>
                      </v-radio-group>

                      <div v-if="backendTestModel.bodyType == 'raw'">
                        <codemirror v-model="backendTestModel.input" :code="backendTestModel.input"
                          :options="codemirrorOption" class="scrollbar-style-14" :lintOptions="{
    sub: true,
    notypeof: true,
  }">
                        </codemirror>
                      </div>
                      <div v-if="backendTestModel.bodyType == 'none'">
                        <p class="mb-0 text-center">
                          This request does not have a body
                        </p>
                      </div>
                      <div v-if="backendTestModel.bodyType == 'formdata'" class="formdata-container">
                          <div class="mb-2">
                            <v-btn small class="elevation-0  " @click="addRowToFormDataInput" rounded > <v-icon>mdi-plus-circle</v-icon> Add row</v-btn>
                          </div>
                        <div v-for="field,i in backendTestModel.inputFormData" :key="i" class="form-row-container px-1 d-flex ">
                          <div class="d-flex align-center justify-start  ">
                            <v-checkbox v-model="field.enabled" class=" py-0 px-0 mx-0 my-0"  value="true"></v-checkbox>
                          </div>
                          <div class="form-row d-flex  w-100">
                            <div class="w-50 mx-1 px-1 ">
                              <v-text-field v-model="field.key" :append-icon="field.type == 'text' ? 'mdi-text' : 'mdi-file'" type="text" dense label=""
                                outlined 
                                @click:append="field.type = field.type == 'text' ? 'file' : 'text'"
                                ></v-text-field>
                            </div>
                            <div class="w-50 ">
                               <v-text-field v-model="field.value" v-if="field.type == 'text'" type="text" dense label="" outlined></v-text-field>
                               <v-file-input  v-model="field.value" v-if="field.type == 'file'" 
                      outlined dense ></v-file-input>
                            </div>
                          </div>
                          <div>
                            <v-btn @click="removeRowFromFormDataInput(i)" icon color="red"> <v-icon>mdi-delete</v-icon> </v-btn>
                          </div>
                        

                        </div>
                        <!-- <pre> -->
                        <!-- {{ backendTestModel.inputFormData }} -->
                        <!-- </pre> -->
                      </div>
                    </v-tab-item>
                  </v-tabs-items>

                  <div class=" output-response">
                    <div class="d-md-flex justify-space-between">
                      <div>
                        <v-radio-group v-model="outputDisplayType" row>
                          <v-radio color="blue" label="Output" value="output"></v-radio>
                          <v-radio color="orange" label="response-detail" value="response-detail"></v-radio>
                        </v-radio-group>
                      </div>

                      <div>
                        <span>
                          <v-icon>mdi-web</v-icon>
                        </span>
                        <span>
                          Status :
                          <span :class="getStatusClass(backendTestModel.status)">
                            {{ backendTestModel.status }}
                          </span>
                          <span class="d-none d-md-inline" :class="getStatusClass(backendTestModel.status)">
                            {{ backendTestModel.statusMessage }}
                          </span>
                        </span>
                        <span>Time: <span class="green--text">{{ backendTestModel.executionTime }}</span> ms
                        </span>
                      </div>
                    </div>

                    <div class="mt-2">
                      <div v-if="outputDisplayType == 'output'">
                        <codemirror v-model="backendTestModel.output" :code="backendTestModel.output"
                          :options="codemirrorOption" class="scrollbar-style-14" :lintOptions="{
    sub: true,
    notypeof: true,
  }">
                        </codemirror>
                      </div>
                      <div v-if="outputDisplayType == 'response-detail'">
                        <!-- <pre>
                  {{ backendTestModel.status }}
                  {{ backendTestModel.gotExpectedStatus }}
                  {{ backendTestModel.expectedStatues }}
                  {{ backendTestModel.actor }}
                </pre> -->
                        <p class="mb-0 mt-4 text-center">Comming Soon</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main_container">
                <div class="body"></div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <loading-2 v-if="loading" />
  </div>
</template>

<script>
import { codemirror } from "vue2-codemirror-lite-js";
import {
  createBackendTestScenario,
  updateBackendTestScenario,
  exportBackendTestPostman,
  simulateBackendTestScenario,
  simulateBackendTest,
} from "@/api/user.js";
import { methods } from "@/utils/constant.js";
import { mapGetters, mapActions } from "vuex";

import Loading2 from "../universal/Loading2.vue";
export default {
  components: {
    codemirror,
    Loading2,
  },
  props: {
    backendTestId: {
      type: String,
      required: true,
    },
    actors: {
      type: Array,
      default: () => ([])
    }
  },
  watch: {
    backendTest() {
      if (this.backendTestModel) {

        for (let folder of this.backendTest.structure) {
          if (Array.isArray(folder.children)) {
            for (let item of folder.children) {
              if (JSON.stringify(item._id) == JSON.stringify(this.backendTestModel._id)) {
                this.showSelectedFile(item);

                break;
              }
            }
          }
        }
      }
    },
    backendTestModel() {
      if (typeof this.backendTestModel.actor == 'object') {
        this.backendTestModel.actor = this.backendTestModel.actor.name
        if (!this.backendTestModel.authType) {
          this.backendTestModel.authType = `ACTOR`
        }
      }
      if (this.backendTestModel.actor && typeof this.backendTestModel.actor == 'string') {
        if (!this.backendTestModel.authKey) {
          this.backendTestModel.authKey = `${this.backendTestModel.actor.toLowerCase()}-token`
        }

        if (!this.backendTestModel.route) {
          this.backendTestModel.route = `${this.backendTestModel.actor.toLowerCase()}/`
        }

      }
    }
  },
  computed: {
    ...mapGetters(["backendTest"]),
    actorNames() {
      return this.actors.map(actor => actor.name)
    }
  },

  methods: {
    ...mapActions(["getBackendTest"]),
    remove(item) {
      this.backendTestModel.expectedStatues.splice(this.backendTestModel.expectedStatues.indexOf(item), 1)
    },
    async initialize() {
      try {
        this.headBtnLoading = true;
        let backendTestScenarioQuery = this.backendTestScenarioQuery;
        if (!backendTestScenarioQuery) {
          backendTestScenarioQuery = {
            scenarioName: "",
            scenarioRoute: "",
            scenarioGotExpectedStatus: "",
            scenarioMethod: "",
            scenarioActor: "",
            scenarioExpectedStatues: "",
            scenarioStatus: "",
          };
        }
        // this.headBtnLoadingText = ""
        await this.getBackendTest({
          id: this.backendTestId,
          backendTestScenarioQuery: backendTestScenarioQuery,
        });
      } catch (error) {
        console.log(error);

        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },

    async saveScenario(model, options) {
      try {
        if (!options) {
          options = {}
        }

        const { silent } = options
        if (silent == true) {
          if (!model._id) return
        }
        model = Object.assign({}, model);
        this.loading = true;
        model.backendTest = this.backendTest._id;
        model.baseUrl = this.backendTest.baseUrl



        delete model.editTitle;
        delete model.file;

        if (typeof model.actor == 'object') {
          model.actor = model.actor._id
        } else
          if (model.actor && typeof model.actor == 'string') {
            model.actor = methods.getItemId(
              this.actors,
              model.actor,
              'name',
              '_id'
            )


          }



        const id = model._id;
        delete model._id;
        delete model.createdBy;
        delete model.updatedBy;
        delete model.isActive;
        delete model.routeUrl;
        delete model.type;
        delete model.error;
        delete model.errorMessage;
        delete model.gotExpectedStatus;
        delete model.executionTime;
        delete model.responseType;
        delete model.statusMessage;
        delete model.success;

        await this.callBackScenario({
          id: id,
          schema: model,
        });
        this.initialize();
        if (!silent) {
          methods.dispatchSuccess(this.$store);
        }
      } catch (err) {
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
      }
    },
    addRequest() {
      this.backendTestModel = {
        authType: "No Auth",
        method: "get",
        route: "",
        name: "",
        editTitle: true,
      };
      this.callBackScenario = createBackendTestScenario;
    },
    async exportBackendTestPostman() {
      try {
        this.headBtnLoading = true;

        const url = await exportBackendTestPostman({
          id: this.backendTestId,
          schema: {
            query: this.backendTestScenarioQuery,
          },
        });
        methods.clickURL(url);
        methods.dispatchSuccess(this.$store);
        // this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async simulateBackendTest(id) {
      try {
        this.loading = true;

        await simulateBackendTest({
          id: id,
          schema: {
            query: this.backendTestScenarioQuery
          }
        });
        methods.dispatchSuccess(this.$store);
        // this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
      }
    },
    async simulateBackendTestScenario(backendTestModel) {
      try {
        this.loadingBtn = true;

        await this.saveScenario(backendTestModel, { silent: true });
        await simulateBackendTestScenario({
          id: backendTestModel._id,
        });
        methods.dispatchSuccess(this.$store);
        this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.loadingBtn = false;
      }
    },
    closeTest() {
      this.$emit("closeTest");
    },
    clearScenarioSearch() {
      this.backendTestScenarioQuery = {
        scenarioName: "",
        scenarioRoute: "",
        scenarioGotExpectedStatus: "",
        scenarioMethod: "",
        scenarioActor: "",
        scenarioExpectedStatues: "",
        scenarioStatus: "",
      };
    },
    applySearch() {
      this.initialize();
    },
    addRowToFormDataInput(){
      this.backendTestModel.inputFormData.push({
        enabled:true,
        type:"text",
        value:"",
      })
    },
    removeRowFromFormDataInput(index){
      this.backendTestModel.inputFormData.splice(index, 1);
    },

    showSelectedFile(item) {
      let selected;
      if (Array.isArray(item)) {
        selected = item[0];
      } else {
        selected = item
      }
      if (!selected.children) {
        this.backendTestModel = Object.assign({}, selected);
        this.callBackScenario = updateBackendTestScenario;
      }
    },
    getMethodClass(method) {
      let outputClass;
      if (method == "get") {
        outputClass = "green--text";
      }
      if (method == "post") {
        outputClass = "orange--text";
      }
      if (method == "put") {
        outputClass = "blue--text";
      }
      if (method == "delete") {
        outputClass = "red--text";
      }

      return outputClass;
    },
    getStatusClass(status) {
      let outputClass;
      if ([200, 201, 203, 204].includes(status)) {
        outputClass = "green--text";
      }
      if ([300, 301, 303, 304].includes(status)) {
        outputClass = "green--text";
      }
      if ([400, 401, 403, 404].includes(status)) {
        outputClass = "orange--text";
      }
      if ([500].includes(status)) {
        outputClass = "red--text";
      }
      if ([502].includes(status)) {
        outputClass = "gray--text";
      }
      if ([600].includes(status)) {
        outputClass = "gray--text";
      }
      if ([601].includes(status)) {
        outputClass = "red--text";
      }

      return outputClass;
    },
    onClickToChevron() {
      // alert(1)
      // this.$refs.scenarioMethod.click()
    },
  },
  data() {
    return {
      initiallyOpen: ["public routes"],
      files: {
        html: "mdi-language-html5",
        js: "mdi-nodejs",
        json: "mdi-code-json",
        md: "mdi-language-markdown",
        pdf: "mdi-file-pdf",
        png: "mdi-file-image",
        txt: "mdi-file-document-outline",
        xls: "mdi-file-excel",
        scss: "mdi-sass",
        css: "mdi-language-css3",
        vuejs: "mdi-vuejs",
        nuxt: "mdi-nuxt",
      },
      tree: [],
      backendTestScenarioQuery: {
        scenarioName: "",
        scenarioRoute: "",
        scenarioGotExpectedStatus: "",
        scenarioMethod: "",
        scenarioActor: "",
        scenarioExpectedStatues: "",
        scenarioStatus: "",
      },
      active: null,
      commentMenu: false,
      loading: false,
      loadingBtn: false,
      requestScenarioTab: null,
      selectedFile: null,
      requestSenarioBodyType: "none",
      codemirrorOption: {
        strict: false,
        tabSize: 2,
        lineNumbers: true,
        lineWrapping: false,
        line: true,
        size: "70vh",
        // gutters: ['CodeMirror-linenumbers', 'CodeMirror-lint-markers'],
        lint: true,
        readOnly: false,
      },
      callBackScenario: null,
      backendTestModel: null,
      routeString: "{{route}}",
      structure: [
        {
          name: "public routes",
          children: [
            {
              name: "favicon.ico",
              file: "png",
            },
            {
              name: "index.html",
              file: "html",
            },
          ],
        },
      ],
      outputDisplayType: "output",
    };
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() { },
};
</script>

<style>
.formdata-container {}

.form-row-container {
  max-width: 1400px
}

.backend-test-main-container .CodeMirror {
  border: 1px solid #eee;
  /* height: 73vh; */
}

.input-request {
  height: 30vh;
}

.output-response {
  height: 40vh;
}

.request-body .CodeMirror {
  border: 1px solid #eee;
  height: 30vh;
}

.output-response .CodeMirror {
  border: 1px solid #eee;
  height: 30vh;
}

.code-treeview {
  height: 73vh;
  overflow-y: scroll;
}

.method_select {
  width: 5rem;
  outline: rgb(240, 240, 240);
}

.method_select option {
  font-weight: 700;
  color: white;
}

.html_select_container {
  position: relative;
  /* cursor:pointer */
}

.html_select_container select {
  cursor: pointer;
}

.html_select_chevron {
  position: absolute;
  right: 0;
}

.backend-test-main-container .url_input {
  outline: none;
  width: 60vw;
}

.justify-self-end {
  justify-self: end !important;
}

@media screen and (max-width: 1400px) {
  .backend-test-main-container .url_input {
    width: 45vw;
  }

}

@media screen and (max-width: 600px) {
  .backend-test-main-container .url_input {
    width: 35vw;
  }

}

@media screen and (max-width: 400px) {
  .backend-test-main-container .url_input {
    width: 28vw;
  }

}
</style>