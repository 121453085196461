<template>
    <div class="container white  w-marchine-server-app">
        <div>
            <p class="sm-title mb-3 mt-3">Available branche : </p>
            <div>
                <div class="d-flex px-2">
                    <div class="select-container">
                        <select-x :items="branchesModels" :label="''"  v-bind:model.sync="selectedBranch"  />
                    </div>
                    <div class="btn-container">
                        <v-btn @click="fetchBranch"  class="elevation-0 rounded white--text"  color="blue" :loading="headBtnLoading">
                            Fetch
                        </v-btn>
                    </div>
                </div>
                <div>
                    <v-btn  class="elevation-0 white--text"  color="green" :loading="headBtnLoading"
                        @click="gitCheckoutServerApp"> Checkout to {{ selectedBranch }} branch
                    </v-btn>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { methods } from "@/utils/constant.js";
import {
    gitCheckoutServerApp,
    gitFetchServerAppBranch,
} from "@/api/user.js";

import SelectX from "../universal/SelectX.vue";

export default {
    components: {
        SelectX
    },
    props: {
        id: {
            type: String,
            required: true
        },

    },
    computed: {
    },
    data() {
        return {
            headBtnLoading: false,
            obfuscateCode: false,
            exportCodeType: "Backend",
            sshContentIsCopied: false,
            httpsContentIsCopied: false,
            branches: [],
            branchesModels: [],
            selectedBranch: null
        }
    },
    watch: {
        branches() {
            this.branchesModels = []
            for (const branch of this.branches) {
                this.branchesModels.push({
                    name: branch.name,
                    icon: "mdi-code-json",
                    badge: "",
                    color: branch.isCurrent ? "green" : 'blue',
                })
                if (branch.isCurrent) {
                    this.selectedBranch = branch.name
                }
            }
        }
    },
    methods: {
        async gitCheckoutServerApp() {
            try {
                if(!this.selectedBranch){
                    alert("Please select the branch that you want to deploy")
                    return 
                }
                this.headBtnLoading = true;
                const output = await gitCheckoutServerApp({
                    id: this.id,
                    schema:{
                        branch: this.selectedBranch
                    }
                });


                if (output) {
                    methods.dispatchConsoleComponenent(this.$store, {
                        messages: output.messages || []
                    })
                }

                this.success = true;
                this.$emit("success");
                // this.initialize();
            } catch (error) {
                console.log(error);
                methods.dispatchError(this.$store);
            } finally {
                this.headBtnLoading = false;
            }
        },
        copyToClipboard(data, type) {
            if (!navigator.clipboard) {
                console.error('Clipboard API not supported');
                return;
            }
            navigator.clipboard.writeText(data).then(() => {
                console.log('Data copied to clipboard successfully!');
                switch (type) {
                    case 'sshcontent':
                        this.sshContentIsCopied = true
                        break;
                    case 'httpscontent':
                        this.httpsContentIsCopied = true
                        break;

                    default:
                        break;
                }
                setTimeout(() => {
                    this.sshContentIsCopied = false
                    this.httpsContentIsCopied = false

                }, 2000)
            }).catch(err => {
                console.error('Failed to copy data to clipboard: ', err);
            });
        },
        async fetchBranch() {
            try {
                this.headBtnLoading = true;
                const output = await gitFetchServerAppBranch({
                    id: this.id,
                });
                if (output) {
                    this.branches = output.data

                    console.log("output", output)
                }

                this.success = true;
                // this.initialize();
            } catch (error) {
                console.log(error);
                methods.dispatchError(this.$store);
            } finally {
                this.headBtnLoading = false;
            }
        }
    },
    async mounted() {
        await this.fetchBranch()
    }

}
</script>

<style>
.w-marchine-server-app {
    min-width: 30rem !important;
}
.select-container{
    width: 85%;
}
.btn-container{
    width: 15%;
}
</style>