/* eslint-disable */

import ParentController from './ParentController'
import BaseControllerMethods from './BaseControllerMethods'

class ControllerMethods extends ParentController {
    static async updateCode(model) {
        return new Promise((resolve, reject) => {

            switch (model.type) {
                case "blank":
                    ControllerMethods.blank().then(result => {
                        return resolve(result)
                    }).catch(err => {
                        reject(err);
                    })

                    break;
                case "seedJson":
                    ControllerMethods.seedJson(model).then(result => {
                        return resolve(result)
                    }).catch(err => {
                        reject(err);
                    })

                    break;
                case "createMethod":
                    ControllerMethods.createMethod(model).then(result => {
                        return resolve(result)
                    }).catch(err => {
                        reject(err);
                    })

                    break;
                case "updateMethod":
                    ControllerMethods.updateMethod(model).then(result => {
                        return resolve(result)
                    }).catch(err => {
                        reject(err);
                    })

                    break;
                case "deleteMethod":
                    ControllerMethods.deleteMethod(model).then(result => {
                        return resolve(result)
                    }).catch(err => {
                        reject(err);
                    })

                    break;
                case "getListMethod":
                    ControllerMethods.getListMethod(model).then(result => {
                        return resolve(result)
                    }).catch(err => {
                        reject(err);
                    })

                    break;
                case "showMethod":
                    ControllerMethods.showMethod(model).then(result => {
                        return resolve(result)
                    }).catch(err => {
                        reject(err);
                    })

                    break;

                default:
                    break;
            }

            resolve(model)


        })
    }

    static async blank() {
        return new Promise(async(resolve, reject) => {

            try {
                const model = {}
                let preSchema = ``
                let schemaPart = ``
                let postSchema = ``
                let preAction = ``
                let action = ``
                let postAction = ``
                let preResolve = ``
                let response = ``
                let preReject = ``

                model.method = ''
                model.routeUrl = ``
                model.name = ``
                model.constantCode = constantCode
                model.preSchema = preSchema
                model.schemaPart = schemaPart
                model.postSchema = postSchema
                model.preAction = preAction
                model.action = action
                model.postAction = postAction
                model.preResolve = preResolve
                model.response = response
                model.preReject = preReject
                model.content = ``
                alert(1)

                resolve(model)

            } catch (error) {
                reject(error)
            }

        })
    }
    static async seedJson(model) {
        return new Promise(async(resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(async models => {

                    const controllerModel = ParentController.methods().getItemId(models, model.controllerModel, "name", "*")

                    const auth = model.auth || controllerModel.createAuth

                    console.log(controllerModel)

                    let profile

                    if (auth && typeof auth == 'object') {
                        if (auth.authMiddleware) profile = auth.authMiddleware
                        else if (auth.name) profile = auth.name
                    } else {
                        profile = auth
                    }



                    let constantCode = `
                    let result = []
                    const data = req.body.data;
                    `
                    if (profile) {
                        profile = profile.toLowerCase()
                        constantCode += `const profile = req.${profile};
                        `
                    }


                    let preSchema = ``
                    let schemaPart = ``
                    let postSchema = ``
                    let preAction = ``
                    let action = ``
                    let postAction = ``
                    let preResolve = ``
                    let response = ``
                    let preReject = ``


                    for (let attribute of controllerModel.attributes) {
                        // is not provide by system 
                        if (!attribute.fromSystem) {
                            // Hash 
                            if (attribute.isHash) {

                                schemaPart += `
                                if(this.utilConstant.issetData(row.${attribute.name})){
                                
                                const ${attribute.name}Salt = await this.bcrypt.genSalt(10);
                                const hash${attribute.name.capitalize()} = await this.bcrypt.hash(this.utilConstant.getValidTrimData(row.${attribute.name}), ${attribute.name}Salt);
                                schema.${attribute.name} = hash${attribute.name.capitalize()}
                                
                            }
                            `
                            }

                            // other 
                            else {
                                schemaPart += `
                                if(this.utilConstant.issetData(row.${attribute.name})){
                                `

                                if (attribute.ref && attribute.ref.name) {
                                    schemaPart += ` const ${attribute.name} = await this.${attribute.ref.name}.findOne({_id:this.utilConstant.getValidTrimData(row.${attribute.name})})
                                    if(!${attribute.name}) throw new Error(this.messages.canNotFind("this ${attribute.name}}" ));
                                    `

                                }
                                schemaPart += `schema.${attribute.name} = this.utilConstant.getValidTrimData(row.${attribute.name})
                            }
                            `
                            }




                        } else {

                            if (profile) {
                                if (["createdBy", "updatedBy"].includes(attribute.name)) {
                                    schemaPart += `schema.${attribute.name} = profile._id 
                                        `
                                }
                            }

                        }
                    }

                    let baseControllerSchema = Object.assign({}, model)
                    baseControllerSchema.manageModel = model.controllerModel

                    let baseControllerAlgoType = model.baseControllerAlgoType || `manage`

                    let baseControllerMethod
                    if (baseControllerAlgoType == 'manage') {
                        baseControllerMethod = await BaseControllerMethods.manageAlgo(baseControllerSchema)
                    } else {
                        alert("Base controller algo type is required ")

                    }

                    let baseControllerMethodName = baseControllerMethod.name

                    const modelNameToLower = controllerModel.name.toLowerCase()
                    action = `
                    for(let row of data){
                        const schema = {};
                        ${schemaPart}

                        
                        const ${modelNameToLower} =  await this.${baseControllerMethodName}(schema)


                        result.push(await this.${controllerModel.name}Resource.collection(${modelNameToLower}))
                    }
                    `

                    response = `res.json({
                        success:true,
                        data:result
                    })`





                    model.method = 'post'
                    model.routeUrl = `/${controllerModel.name.toLowerCase()}/seed-json`
                    model.name = `seedJson${model.controllerModel.capitalize()}`
                    model.constantCode = constantCode
                    model.preSchema = preSchema
                    model.schemaPart = ``
                    model.postSchema = postSchema
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.preResolve = preResolve
                    model.response = response
                    model.preReject = preReject



                    // basecontroller method 
                    model.baseControllerMethodName = baseControllerMethod.name || ''
                    model.baseControllerMethodArgument = baseControllerMethod.argument || ''
                    model.baseControllerMethodConstantCode = baseControllerMethod.constantCode || ''
                    model.baseControllerMethodPreAction = baseControllerMethod.preAction || ''
                    model.baseControllerMethodAction = baseControllerMethod.action || ''
                    model.baseControllerMethodPostAction = baseControllerMethod.postAction || ''
                    model.baseControllerMethodPreResource = baseControllerMethod.preResource || ''
                    model.baseControllerMethodResourcePart = baseControllerMethod.resourcePart || ''
                    model.baseControllerMethodPostResource = baseControllerMethod.postResource || ''
                    model.baseControllerMethodPreResolve = baseControllerMethod.preResolve || ''
                    model.baseControllerMethodResponse = baseControllerMethod.response || ''
                    model.baseControllerMethodPreReject = baseControllerMethod.preReject || ''
                    model.baseControllerMethodContent = baseControllerMethod.content || ''
                    model.baseControllerAlgoType = baseControllerAlgoType || ''

                    model.content = ParentController.getControllerMethodContent(model)

                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }
    static async createMethod(model) {
        return new Promise(async(resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(async models => {

                    const controllerModel = ParentController.methods().getItemId(models, model.controllerModel, "name", "*")

                    const auth = model.auth || controllerModel.createAuth


                    let profile

                    if (auth && typeof auth == 'object') {
                        if (auth.authMiddleware) profile = auth.authMiddleware
                        else if (auth.name) profile = auth.name
                    } else {
                        profile = auth
                    }



                    let constantCode = `
                    `
                    if (profile) {
                        profile = profile.toLowerCase()
                        constantCode += `const profile = req.${profile};
                        `

                    }
                    constantCode += `
                    // Validate data
                        const { error } = this.Validation.${controllerModel.name}Validation(req.body);
                        if (error) throw new Error(error.details[0].message);
                
                
                        const oldPaths=[]
                        `


                    let preSchema = ``
                    let schemaPart = ``
                    let postSchema = ``
                    let preAction = ``
                    let action = ``
                    let postAction = `this.utilConstant.deletePaths(oldPaths)`
                    let preResolve = ``
                    let response = ``
                    let preReject = ``


                    for (let attribute of controllerModel.attributes) {
                        // is not provide by system 
                        if (!attribute.fromSystem) {
                            // Hash 
                            if (attribute.isHash) {

                                schemaPart += `
                                if(this.utilConstant.issetData(req.body.${attribute.name})){
                                
                                const ${attribute.name}Salt = await this.bcrypt.genSalt(10);
                                const hash${attribute.name.capitalize()} = await this.bcrypt.hash(this.utilConstant.getValidTrimData(req.body.${attribute.name}), ${attribute.name}Salt);
                                schema.${attribute.name} = hash${attribute.name.capitalize()}
                                
                            }
                            `
                            }

                            // other 
                            else {
                                schemaPart += `
                                if(this.utilConstant.issetData(req.body.${attribute.name})){
                                `

                                if (attribute.ref && attribute.ref.name) {
                                    schemaPart += ` const ${attribute.name} = await this.${attribute.ref.name}.findOne({_id:this.utilConstant.getValidTrimData(req.body.${attribute.name})})
                                    if(!${attribute.name}) throw new Error(this.messages.canNotFind("this ${attribute.name}}" ));
                                    `

                                }
                                schemaPart += `schema.${attribute.name} = this.utilConstant.getValidTrimData(req.body.${attribute.name})
                            }
                            `
                            }




                        } else {

                            if (profile) {
                                if (["createdBy", "updatedBy"].includes(attribute.name)) {
                                    schemaPart += `schema.${attribute.name} = profile._id 
                                        `
                                }
                            }

                        }
                    }






                    const modelNameToLower = controllerModel.name.toLowerCase()
                    action = `
                    const schema = {};
                    ${schemaPart}

                    
                    const ${modelNameToLower} =  await this.${controllerModel.name}(schema)
                    const save = await ${modelNameToLower}.save()
                    
                    `

                    response = `res.json({
                        success:true,
                        data:save
                    })`





                    model.method = 'post'
                    model.routeUrl = `/${controllerModel.name.toLowerCase()}`
                    model.name = `create`
                    model.constantCode = constantCode
                    model.preSchema = preSchema
                    model.schemaPart = ``
                    model.postSchema = postSchema
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.preResolve = preResolve
                    model.response = response
                    model.preReject = preReject





                    model.content = ParentController.getControllerMethodContent(model)

                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }

    static async updateMethod(model) {
        return new Promise(async(resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(async models => {

                    const controllerModel = ParentController.methods().getItemId(models, model.controllerModel, "name", "*")

                    const auth = model.auth || controllerModel.createAuth


                    let profile

                    if (auth && typeof auth == 'object') {
                        if (auth.authMiddleware) profile = auth.authMiddleware
                        else if (auth.name) profile = auth.name
                    } else {
                        profile = auth
                    }



                    let constantCode = `
                    // Validate data
                    const { error } = this.Validation.${controllerModel.name}Validation(req.body);
                    if (error) throw new Error(error.details[0].message);
            
            
                    const oldPaths=[]
            
                    
                    const id = req.params.id
            
            
                    const ${controllerModel.name.toLowerCase()} = await this.${controllerModel.name}.findOne({
                        _id: id,
                        deletedAt: {
                            $exists: false
                        }
                    });
            
                    if (!${controllerModel.name.toLowerCase()}) throw new Error(this.messages.canNotFind('this ${controllerModel.name.toLowerCase()}'))
                   
                    `
                    if (profile) {
                        profile = profile.toLowerCase()
                        constantCode += `const profile = req.${profile};
                        `
                    }


                    let preSchema = ``
                    let schemaPart = ``
                    let postSchema = ``
                    let preAction = ``
                    let action = ``
                    let postAction = `this.utilConstant.deletePaths(oldPaths)`
                    let preResolve = ``
                    let response = ``
                    let preReject = ``


                    for (let attribute of controllerModel.attributes) {
                        // is not provide by system 
                        if (!attribute.fromSystem) {
                            // Hash 
                            if (attribute.isHash) {

                                schemaPart += `
                                if(this.utilConstant.issetData(req.body.${attribute.name})){
                                
                                const ${attribute.name}Salt = await this.bcrypt.genSalt(10);
                                const hash${attribute.name.capitalize()} = await this.bcrypt.hash(this.utilConstant.getValidTrimData(req.body.${attribute.name}), ${attribute.name}Salt);
                                schema.${attribute.name} = hash${attribute.name.capitalize()}
                                
                            }
                            `
                            }

                            // other 
                            else {
                                schemaPart += `
                                if(this.utilConstant.issetData(req.body.${attribute.name})){
                                `

                                if (attribute.ref && attribute.ref.name) {
                                    schemaPart += ` const ${attribute.name} = await this.${attribute.ref.name}.findOne({_id:this.utilConstant.getValidTrimData(req.body.${attribute.name})})
                                    if(!${attribute.name}) throw new Error(this.messages.canNotFind("this ${attribute.name}}" ));
                                    `

                                }
                                schemaPart += `schema.${attribute.name} = this.utilConstant.getValidTrimData(req.body.${attribute.name})
                            }
                            `
                            }




                        } else {

                            if (profile) {
                                if (["createdBy", "updatedBy"].includes(attribute.name)) {
                                    schemaPart += `schema.${attribute.name} = profile._id 
                                        `
                                }
                            }

                        }
                    }






                    const modelNameToLower = controllerModel.name.toLowerCase()
                    preSchema = ` 
                    `


                    action = `
                      await ${modelNameToLower}.updateOne(schema)
                    
                    `

                    response = `res.json({
                        success:true,
                        data: await this.${controllerModel.name}.findOne({
                            _id:req.params.id,
                            deletedAt:{
                                $exists:false
                            }
                        })
     
                    })`





                    model.method = 'put'
                    model.routeUrl = `/${controllerModel.name.toLowerCase()}/:id`
                    model.name = `update`
                    model.constantCode = constantCode
                    model.preSchema = preSchema
                    model.schemaPart = ` const schema = {};
                    ${schemaPart}`

                    model.postSchema = postSchema
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.preResolve = preResolve
                    model.response = response
                    model.preReject = preReject





                    model.content = ParentController.getControllerMethodContent(model)

                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }
    static async deleteMethod(model) {
        return new Promise(async(resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(async models => {

                    const controllerModel = ParentController.methods().getItemId(models, model.controllerModel, "name", "*")

                    const auth = model.auth || controllerModel.createAuth


                    let profile

                    if (auth && typeof auth == 'object') {
                        if (auth.authMiddleware) profile = auth.authMiddleware
                        else if (auth.name) profile = auth.name
                    } else {
                        profile = auth
                    }



                    let constantCode = `
                    `
                    if (profile) {
                        profile = profile.toLowerCase()
                        constantCode += `const profile = req.${profile};
                        `

                    }

                    constantCode += `
    
                    const oldPaths=[]
            
                    
                    const id = req.params.id
            
            
                    const ${controllerModel.name.toLowerCase()} = await this.${controllerModel.name}.findOne({
                        _id: id,
                        deletedAt: {
                            $exists: false
                        }
                    });
            
                    if (!${controllerModel.name.toLowerCase()}) throw new Error(this.messages.canNotFind('this ${controllerModel.name.toLowerCase()}'))
                    `


                    let preSchema = ``
                    let schemaPart = ``
                    let postSchema = ``
                    let preAction = ``
                    let action = `
                    await ${controllerModel.name.toLowerCase()}.updateOne({
                        deletedAt:Date.now(),
                        deletedBy:profile._id,
                    });
                    `
                    let postAction = `this.utilConstant.deletePaths(oldPaths)`
                    let preResolve = ``
                    let response = ``
                    let preReject = ``








                    const modelNameToLower = controllerModel.name.toLowerCase()
                    preSchema = ``




                    response = `res.json({
                        success:true,
                    })`





                    model.method = 'delete'
                    model.routeUrl = `/${controllerModel.name.toLowerCase()}/:id`
                    model.name = `delete`
                    model.constantCode = constantCode
                    model.preSchema = preSchema
                    model.schemaPart = schemaPart

                    model.postSchema = postSchema
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.preResolve = preResolve
                    model.response = response
                    model.preReject = preReject





                    model.content = ParentController.getControllerMethodContent(model)

                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }
    static async getListMethod(model) {
        return new Promise(async(resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(async models => {

                    const controllerModel = ParentController.methods().getItemId(models, model.controllerModel, "name", "*")

                    const auth = model.auth || controllerModel.createAuth


                    let profile

                    if (auth && typeof auth == 'object') {
                        if (auth.authMiddleware) profile = auth.authMiddleware
                        else if (auth.name) profile = auth.name
                    } else {
                        profile = auth
                    }


                    let baseStatistcalDate = {
                        start: 'startDate',
                        end: 'endDate',
                    }

                    let matchString = `const match = {
                        matchUpdatedBy: {},
                        `
                    let constantCode = `
                    const query = req.query

                    let withoutPeriod = true
                   
                    `
                    if (profile) {
                        profile = profile.toLowerCase()
                        constantCode += `const profile = req.${profile};
                        `
                    }


                    let preSchema = ``
                    let schemaPart = `
                    const schema = {
                        deletedAt: {
                            $exists: false
                        }
                    }
            
            
                    if (query.withOutDeletedRestriction == 'yes') {
                        delete schema.deletedAt
                    }
                    if (query.onlyDeletedData == 'yes') {
                        schema.deletedAt = {
                            $exists: true
                        }
                    }
                    if (query.agentName) {
                        match.matchUpdatedBy.fullName = {
                            $regex: ".*" + query.agentName + ".*",
                            $options: "i",
                        }
                    }
            
            
                    if (query.startDate || query.endDate) {
                        schema.createdAt = this.utilConstant.getPeriodSchema({
                            periodStartDate: query.startDate,
                            periodEndDate: query.endDate,
                        })
            
                        withoutPeriod = false
                    }
            
                    `
                    let postSchema = ``
                    let preAction = ``
                    let action = ``
                    let postAction = ``
                    let preResolve = ``
                    let response = ``
                    let preReject = ``


                    for (let attribute of controllerModel.attributes) {
                        // is not provide by system 
                        if (!attribute.fromSystem) {

                            // ref
                            if (attribute.ref && typeof attribute.ref == 'object') {
                                matchString += `match${attribute.name.capitalize()} : {},`
                            }


                            // Hash 
                            if (attribute.isHash) {

                            }

                            // File 
                            else if (attribute.isFile) {

                            }

                            // other 
                            else {


                                if (['Date'].includes(attribute.type)) {
                                    schemaPart += `
                            if (query.${attribute.name}Start || query.${attribute.name}End) {
                                schema.${attribute.name} = this.utilConstant.getPeriodSchema({
                                    periodStartDate: query.${attribute.name}Start,
                                    periodEndDate: query.${attribute.name}End,
                                })
                            
                            `

                                    if (attribute.isStatisticalBaseDate) {
                                        schemaPart += `withoutPeriod = false;`

                                        baseStatistcalDate.start = `${attribute.name}Start`
                                        baseStatistcalDate.end = `${attribute.name}End`
                                    }

                                    schemaPart += `}`


                                } else if (['SelectString'].includes(attribute.type)) {
                                    schemaPart += `
                            if (query.${attribute.name}) {
                                schema.${attribute.name} = query.${attribute.name}
                            }
                            `


                                } else if (['mongoose.Schema.Types.ObjectId'].includes(attribute.type)) {
                                    schemaPart += `
                            if (query.${attribute.name}) {
                                schema.${attribute.name} = query.${attribute.name}
                            }
                            `
                                } else if (['Array', 'Object', 'Number'].includes(attribute.type)) {
                                    schemaPart += `
                            if (query.${attribute.name}) {
                                schema.${attribute.name} = query.${attribute.name}
                            }
                            `
                                } else if (['Number'].includes(attribute.type)) {
                                    // must be less than and more than
                                    schemaPart += `
                            if (query.${attribute.name}) {
                                schema.${attribute.name} = query.${attribute.name}
                            }
                            `
                                } else if ([
                                        'fullname',
                                        'name',
                                        'agentname',
                                        'description',
                                    ].includes(attribute.name.toLowerCase())) {
                                    schemaPart += `
                            if (query.${attribute.name}) {
                                schema.${attribute.name} = {
                                    $regex: ".*"+ query.${attribute.name}  +".*",
                                    $options: "i",
                                }
                            }
                            `
                                } else {
                                    schemaPart += `
                        if (query.${attribute.name}) {
                            schema.${attribute.name} = {
                                $regex: ".*"+ query.${attribute.name}  +".*",
                                $options: "i",
                            }
                        }
                        `
                                }


                            }

                        }
                    }

                    // end match string 
                    matchString += `}
  `


                    constantCode += matchString



                    const modelNameToLower = controllerModel.name.toLowerCase()
                    preSchema = ``


                    action = `
        const data = await  this.get${controllerModel.name}ListAlgo(schema,match);
                      
                    
                    `

                    if (controllerModel.withPagination) {
                        postAction += `
                            let currentPage = req.query.page || 1
                            let limitPerPage = req.query.limit || process.env.PAGINATION_LIMIT || 1
                    
                            const paginatedResult = this.utilConstant.paginateArray(data, currentPage, limitPerPage, process.env.SITE_URL + "api/v1/${controllerModel.name.toLowerCase()}");
                    
                            `
                    }

                    postAction += ` const linkData = []

                    let excelSubTitle = this.utilConstant.getEmissionTitle({
                        periodStartDate: query.${baseStatistcalDate.start},
                        periodEndDate: query.${baseStatistcalDate.end},
                        withoutPeriod
                    })
        
                    for (let item of data) {
                        linkData.push(await this.${controllerModel.name}Resource.statistical(item))
                    }`

                    response = `res.json({`

                    if (controllerModel.withPagination) {
                        response += `
                        ...paginatedResult,`
                    } else {
                        response += `
                        data,`
                    }
                    response += `
                            link: await this.utilConstant.downloadExcellData(linkData, {
                                excelTitle: 'STATISTIQUES ${controllerModel.name.toUpperCase()}',
                                excelSubTitle: excelSubTitle
                            }),
                            success: true,
                        })`





                    model.method = 'get'
                    model.routeUrl = `/${controllerModel.name.toLowerCase()}`
                    model.name = `getList`
                    model.constantCode = constantCode
                    model.preSchema = preSchema
                    model.schemaPart = schemaPart

                    model.postSchema = postSchema
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.preResolve = preResolve
                    model.response = response
                    model.preReject = preReject



                    // basecontroller method 
                    let baseControllerSchema = Object.assign({}, model)
                    baseControllerSchema.manageModel = model.controllerModel

                    let baseControllerAlgoType = model.baseControllerAlgoType || `getListAlgo`

                    let baseControllerMethod
                    if (baseControllerAlgoType == 'getListAlgo') {
                        baseControllerMethod = await BaseControllerMethods.getListAlgo(baseControllerSchema)
                    } else {
                        alert("Base controller algo type is required ")
                    }

                    let baseControllerMethodName = baseControllerMethod.name

                    model.baseControllerMethodName = baseControllerMethod.name || ''
                    model.baseControllerMethodArgument = baseControllerMethod.argument || ''
                    model.baseControllerMethodConstantCode = baseControllerMethod.constantCode || ''
                    model.baseControllerMethodPreAction = baseControllerMethod.preAction || ''
                    model.baseControllerMethodAction = baseControllerMethod.action || ''
                    model.baseControllerMethodPostAction = baseControllerMethod.postAction || ''
                    model.baseControllerMethodPreResource = baseControllerMethod.preResource || ''
                    model.baseControllerMethodResourcePart = baseControllerMethod.resourcePart || ''
                    model.baseControllerMethodPostResource = baseControllerMethod.postResource || ''
                    model.baseControllerMethodPreResolve = baseControllerMethod.preResolve || ''
                    model.baseControllerMethodResponse = baseControllerMethod.response || ''
                    model.baseControllerMethodPreReject = baseControllerMethod.preReject || ''
                    model.baseControllerMethodContent = baseControllerMethod.content || ''
                    model.baseControllerAlgoType = baseControllerAlgoType || ''





                    model.content = ParentController.getControllerMethodContent(model)

                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }
    static async showMethod(model) {
        return new Promise(async(resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(async models => {

                    const controllerModel = ParentController.methods().getItemId(models, model.controllerModel, "name", "*")

                    const auth = model.auth || controllerModel.createAuth


                    let profile

                    if (auth && typeof auth == 'object') {
                        if (auth.authMiddleware) profile = auth.authMiddleware
                        else if (auth.name) profile = auth.name
                    } else {
                        profile = auth
                    }


                    let baseStatistcalDate = {
                        start: 'startDate',
                        end: 'endDate',
                    }

                    let matchString = `const match = {
                        matchUpdatedBy: {},
                        `
                    let constantCode = `
                    const query = req.query

                   
                    `
                    if (profile) {
                        profile = profile.toLowerCase()
                        constantCode += `const profile = req.${profile};
                        `
                    }


                    let preSchema = ``
                    let schemaPart = `
                    const schema = {
                        _id: req.params.id,
                        deletedAt: {
                            $exists: false
                        }
                    }
                
                    if (query.withOutDeletedRestriction == 'yes') {
                        delete schema.deletedAt
                    }
                    if (query.onlyDeletedData == 'yes') {
                        schema.deletedAt = {
                            $exists: true
                        }
                    }
                    if (query.agentName) {
                        match.matchUpdatedBy.fullName = {
                            $regex: ".*" + query.agentName + ".*",
                            $options: "i",
                        }
                    }
                    `
                    let postSchema = ``
                    let preAction = ``
                    let action = ``
                    let postAction = ``
                    let preResolve = ``
                    let response = ``
                    let preReject = ``


                    for (let attribute of controllerModel.attributes) {
                        // is not provide by system 
                        if (!attribute.fromSystem) {
                            // ref
                            if (attribute.ref && typeof attribute.ref == 'object') {
                                matchString += `match${attribute.name.capitalize()} : {},`
                            }

                            // Hash 
                            if (attribute.isHash) {

                            }

                            // File 
                            else if (attribute.isFile) {

                            }

                            // other 
                            else {


                                if (['Date'].includes(attribute.type)) {
                                    schemaPart += `
                        if (query.${attribute.name}Start || query.${attribute.name}End) {
                            schema.${attribute.name} = this.utilConstant.getPeriodSchema({
                                periodStartDate: query.${attribute.name}Start,
                                periodEndDate: query.${attribute.name}End,
                            })
                        
                        `

                                    schemaPart += `}`


                                } else if ([
                                        'fullname',
                                        'name',
                                        'agentname',
                                    ].includes(attribute.name.toLowerCase())) {
                                    schemaPart += `
                        if (query.${attribute.name}) {
                            schema.${attribute.name} = {
                                $regex: ".*"+ query.${attribute.name}  +".*",
                                $options: "i",
                            }
                        }
                        `
                                } else {
                                    schemaPart += `
                        if (query.${attribute.name}) {
                            schema.${attribute.name} = query.${attribute.name}
                        }
                        `
                                }


                            }

                        }
                    }

                    // end match string 
                    matchString += `}
  `


                    constantCode += matchString



                    const modelNameToLower = controllerModel.name.toLowerCase()
                    preSchema = ``


                    action = `
        const data = await  this.get${controllerModel.name}Algo(schema,match);
                    
                    `



                    postAction += ``

                    response = `res.json({
                    data,
                    success: true,
                })`






                    model.method = 'get'
                    model.routeUrl = `/${controllerModel.name.toLowerCase()}/:id`
                    model.name = `show`
                    model.constantCode = constantCode
                    model.preSchema = preSchema
                    model.schemaPart = schemaPart

                    model.postSchema = postSchema
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.preResolve = preResolve
                    model.response = response
                    model.preReject = preReject



                    // basecontroller method 
                    let baseControllerSchema = Object.assign({}, model)
                    baseControllerSchema.manageModel = model.controllerModel

                    let baseControllerAlgoType = model.baseControllerAlgoType || `showAlgo`

                    let baseControllerMethod
                    if (baseControllerAlgoType == 'showAlgo') {
                        baseControllerMethod = await BaseControllerMethods.showAlgo(baseControllerSchema)
                    } else {
                        alert("Base controller algo type is required ")
                    }

                    let baseControllerMethodName = baseControllerMethod.name

                    model.baseControllerMethodName = baseControllerMethod.name || ''
                    model.baseControllerMethodArgument = baseControllerMethod.argument || ''
                    model.baseControllerMethodConstantCode = baseControllerMethod.constantCode || ''
                    model.baseControllerMethodPreAction = baseControllerMethod.preAction || ''
                    model.baseControllerMethodAction = baseControllerMethod.action || ''
                    model.baseControllerMethodPostAction = baseControllerMethod.postAction || ''
                    model.baseControllerMethodPreResource = baseControllerMethod.preResource || ''
                    model.baseControllerMethodResourcePart = baseControllerMethod.resourcePart || ''
                    model.baseControllerMethodPostResource = baseControllerMethod.postResource || ''
                    model.baseControllerMethodPreResolve = baseControllerMethod.preResolve || ''
                    model.baseControllerMethodResponse = baseControllerMethod.response || ''
                    model.baseControllerMethodPreReject = baseControllerMethod.preReject || ''
                    model.baseControllerMethodContent = baseControllerMethod.content || ''
                    model.baseControllerAlgoType = baseControllerAlgoType || ''





                    model.content = ParentController.getControllerMethodContent(model)

                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }




}

export default ControllerMethods