<template>

    <div>
        <v-row>
            <v-col v-if="project.marchineServers.length == 0">
                <div class="text-center">
                    <v-icon size="5rem">mdi-thought-bubble</v-icon>
                    <p class="mt-3">The servers displayed here are those that have been added and verified via SSH
                        connection
                        on the workspace. <br> Make sure you have correctly added your server and verified the
                        connection
                        from
                        the Server tab of the workspace of the project, on the <router-link
                            to="/home">homepage</router-link> .
                    </p>
                </div>
            </v-col>
            <v-col v-else v-for="(marchine, i) of project.marchineServers" :key="i" cols="12" md="4" lg="2">
                <marchine-server @refresh="emitInitialize" :marchine="marchine" :projectId="project.project._id"
                    :project="project.project" :workspaceMemberRole="project.workspaceMemberRole" />
            </v-col>
        </v-row>
    </div>
</template>
<script>
import MarchineServer from "./MarchineServer.vue";

export default {
    components: { MarchineServer },
    props: {
        project: {
            type: Object,
            required: true
        },
    },
    methods: {
        emitInitialize() {
            this.$emit("refresh");
        }
    }
}
</script>