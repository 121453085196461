<template>
  <div>
    <div v-if="project && project.project">
      <div v-if="!loading">
        <!-- head -->
        <div class="d-flex items-center justify-space-between">
          <div class="d-block d-lg-flex items-center">
            <div class="d-flex  justify-space-between ">

              <div class="d-flex align-center ">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="manageShowSetting" icon class="mr-3">
                      <v-icon size="20px" class="">mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ "Setting" }}</span>
                </v-tooltip>

                <h1 class="h3-title secondary--text  pt-2 mr-lg-16 mr-md-1 ">
                  <span class="short-workspace-icon primary rounded white--text py-1 px-2 mr-2">{{
                    methods.getFirstCharacter(project.project.name) }}</span>
                  {{ methods.limitTextCharacters(project.project.name, 25) }}
                </h1>

              </div>
              <div class="d-md-none ml-3">
                <v-btn @click="mobileMainDialog = !mobileMainDialog" icon><v-icon>mdi-menu</v-icon></v-btn>
              </div>
            </div>

            <div class="  head-project-content">
              <div class="head-project-content-first">
                <v-sheet elevation="0" class="d-none d-md-inline py-2 py-lg-0 px-1 rounded-pill">
                  <v-chip-group active-class="primary--text" class="white">
                    <v-chip v-if="[
                      methods.getWorkspaceMemberRoles(true).owner,
                      methods.getWorkspaceMemberRoles(true).developer,
                      methods.getWorkspaceMemberRoles(true).maintainer,
                    ].includes(project.workspaceMemberRole)
                    " class="white d-none">
                      <button @click="buildFrontend" class="m-btn-dense mx-0">
                        <span><v-icon size="16px" color="green" class="mr-1">mdi-cog-sync-outline</v-icon>
                          Build Frontend
                        </span>
                      </button>
                    </v-chip>
                    <v-chip v-if="[
                      methods.getWorkspaceMemberRoles(true).owner,
                      methods.getWorkspaceMemberRoles(true).developer,
                      methods.getWorkspaceMemberRoles(true).maintainer,
                    ].includes(project.workspaceMemberRole)
                    " class="white">
                      <button @click="buildBackend" class="m-btn-dense mx-0">
                        <span><v-icon size="16px" color="green" class="mr-1">mdi-cog-sync-outline</v-icon>
                          Build Backend
                        </span>
                      </button>
                    </v-chip>
                    <v-chip v-if="[
                      methods.getWorkspaceMemberRoles(true).owner,
                      methods.getWorkspaceMemberRoles(true).developer,
                      methods.getWorkspaceMemberRoles(true).maintainer,
                      methods.getWorkspaceMemberRoles(true).reporter,
                    ].includes(project.workspaceMemberRole)
                    " class="white d-none d-lg-inline">
                      <button @click="pushProjectToGitLab" class="m-btn-dense mx-0">
                        <span><v-icon size="16px" color="green" class="mr-1">mdi-gitlab</v-icon>
                          Push backend to git lab
                        </span>
                      </button>
                    </v-chip>
                    <v-chip v-if="[
                      methods.getWorkspaceMemberRoles(true).owner,
                      methods.getWorkspaceMemberRoles(true).developer,
                      methods.getWorkspaceMemberRoles(true).maintainer,
                      methods.getWorkspaceMemberRoles(true).reporter,
                    ].includes(project.workspaceMemberRole)
                    " class="white d-none d-md-inline">
                      <v-menu v-model="additionalModel.exportCodeMenu" :close-on-content-click="false" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <button class="m-btn-dense mx-0" v-bind="attrs" v-on="on">
                            <span><v-icon size="16px" color="green" class="mr-1">mdi-download-outline</v-icon>
                              Export Backend Code
                            </span>
                          </button>
                        </template>
                        <export-backend-code @success="handledExportSuccess" :id="$route.params.id"
                          :backendGitLabSshLink="project.project.backendGitLabSshLink"
                          :backendGitLabHttpsLink="project.project.backendGitLabHttpsLink" />
                      </v-menu>
                    </v-chip>
                    <v-chip v-if="[
                      methods.getWorkspaceMemberRoles(true).owner,
                      methods.getWorkspaceMemberRoles(true).developer,
                      methods.getWorkspaceMemberRoles(true).maintainer,
                      methods.getWorkspaceMemberRoles(true).reporter,
                    ].includes(project.workspaceMemberRole)
                    " class="white d-none d-md-inline">
                      <button @click="exportPostmanProject" class="m-btn-dense mx-0">
                        <span><v-icon size="16px" color="green" class="mr-1">mdi-export-variant</v-icon>
                          Export Postman
                        </span>
                      </button>
                    </v-chip>

                    <v-chip v-if="[
                      methods.getWorkspaceMemberRoles(true).owner,
                      methods.getWorkspaceMemberRoles(true).developer,
                      methods.getWorkspaceMemberRoles(true).maintainer,
                    ].includes(project.workspaceMemberRole)
                    " class="white d-none d-sm-inline">
                      <button @click="saveBackends" class="m-btn-dense mx-0">
                        <span><v-icon size="16px" color="green" class="mr-1">mdi-content-save-outline</v-icon>
                          Pull Backend
                        </span>
                      </button>
                    </v-chip>

                    <v-chip class="white">
                      <button @click="showMoreOptions = !showMoreOptions" class="m-btn-dense mx-0">
                        <span><v-icon size="16px" color="green" class="mr-1">mdi-menu</v-icon>
                          More Options
                        </span>
                      </button>
                    </v-chip>

                  </v-chip-group>
                </v-sheet>
              </div>
            </div>
          </div>
        </div>
        <!-- {{ project.models }} -->
        <!-- Tab -->
        <div ref="contentToExport" class="d-none d-md-block">
          <v-tabs v-model="tab" background-color="dash" class="mt-0">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-for="item in [
              'ERD',
              'Class Diagram',
              'BACKEND CODE',
              // 'FRONTEND VUE CODE',
              'WORKSPACE CONNECTED SERVERS',
              'BACKEND TEST (BETA)',

            ]" :key="item">
              {{ item }}

            </v-tab>
          </v-tabs>

          <v-tabs-items class="" v-model="tab">
            <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab" :key="'ERD'">
              <erd @refresh="initialize" :models="project.models.data" :actorsModels="project.project.actors"
                :workspaceMemberRole="project.workspaceMemberRole"
                :limitModelPerProjectReached="project.limitModelPerProjectReached"
                :activesSubscription="activesSubscription" />
            </v-tab-item>
            <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab" :key="'Class Diagram'">
              <class-diagram @refresh="initialize" ref="classDiagram" :classList="project.class"
                :workspaceMemberRole="project.workspaceMemberRole" :activesSubscription="activesSubscription" />
            </v-tab-item>
            <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab" :key="'BACKEND CODE'">
              <preview-code :structure="project.structure" />
            </v-tab-item>
            <!-- <v-tab-item
            class="tab-items scrollbar-style-14 dash erd-tab"
            :key="'FRONTEND VUE CODE'"
          >
            <preview-code :structure="project.frontVueStructure" />
          </v-tab-item> -->
            <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab" :key="'WORKSPACE CONNECTED SERVERS'">
              <div class="pt-5">
                <marchine-server-list @refresh="initialize" :project="project" />
              </div>
            </v-tab-item>
            <v-tab-item class="tab-items scrollbar-style-14 dash erd-tab" :key="'BACKEND TEST (BETA)'">
              <backend-test-list :project="project" />
            </v-tab-item>
          </v-tabs-items>
        </div>

        <!-- mobile -->
        <div class="d-md-none">
          <div v-show="activatedMobileMenu == 'ERD'">
            <erd :forMobile="true" @refresh="initialize" :models="project.models.data"
              :actorsModels="project.project.actors" :workspaceMemberRole="project.workspaceMemberRole"
              :limitModelPerProjectReached="project.limitModelPerProjectReached"
              :activesSubscription="activesSubscription" />
          </div>
          <div v-show="activatedMobileMenu == 'CLASS-DIAGRAM'">
            <class-diagram :forMobile="true" @refresh="initialize" ref="classDiagram" :classList="project.class"
              :workspaceMemberRole="project.workspaceMemberRole" :activesSubscription="activesSubscription" />
          </div>
          <div v-show="activatedMobileMenu == 'BACKEND-CODE'">
            <preview-code :structure="project.structure" />
          </div>
          <div v-show="activatedMobileMenu == 'WORKSPACE-CONNECTED-SERVERS'">
            <div class="pt-5">
              <marchine-server-list :project="project" />
            </div>
          </div>
          <div v-show="activatedMobileMenu == 'BACKEND-TEST'">
            <backend-test-list :project="project" />

          </div>

        </div>

      </div>
      <div v-else-if="project && project.invalidMemberSubscription">
        <div class="text-center">
          <h3>You don't have a valid subscription to work in this project </h3>
          <p>Please contact the workspace owner or get a valid subscription </p>
        </div>
      </div>
      <loading v-if="loading" />
      <loading-2 v-if="headBtnLoading" :text="headBtnLoadingText" />

      <!--  -->
      <v-dialog v-model="featureDialog" max-width="1000px">
        <editor-x @success="onSuccess" v-bind:fields.sync="featureFields" :callback="callBackFeature"
          :model="featureModel" title="Add Feature" v-if="featureDialog" submit-text="Add Feature" />
      </v-dialog>

      <!--  -->
      <project-setting @success="onSuccess" :dialog="showSetting" @dialogChange="manageShowSetting" />


      <v-dialog v-model="showMoreOptions" class="" max-width="65rem">
        <v-card class="white py-8 px-1 ">
          <v-card-text class="px-1 px-md-5">

            <h3>More Options </h3>
            <div class="">

              <div class="grey lighten-3 rounded mb-5 mt-5 pt-1 px-3">
                <p class="font-weight-bold mb-2 ">Global Options </p>
                <v-row>
                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <button @click="buildBackend" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-cog-sync-outline</v-icon>
                        Build Backend
                      </span>
                    </button>
                  </v-col>
                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <button @click="saveBackends" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-content-save-outline</v-icon>
                        Pull Backend
                      </span>
                    </button>
                  </v-col>

                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <button @click="addFeature" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-plus</v-icon>
                        Add Feature
                      </span>
                    </button>
                  </v-col>

                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                    methods.getWorkspaceMemberRoles(true).reporter,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <button @click="pushProjectToGitLab" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-gitlab</v-icon>
                        Push backend to git lab
                      </span>
                    </button>
                  </v-col>

                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                    methods.getWorkspaceMemberRoles(true).reporter,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <v-menu v-model="additionalModel.exportCodeSubMenu" :close-on-content-click="false" offset-y>

                      <template v-slot:activator="{ on, attrs }">
                        <button class="m-btn-dense mx-0 " v-bind="attrs" v-on="on">
                          <span><v-icon size="16px" color="green" class="mr-1">mdi-download-outline</v-icon>
                            Export Backend Code
                          </span>
                        </button>
                      </template>
                      <export-backend-code @success="handledExportSuccess" :id="$route.params.id"
                        :backendGitLabSshLink="project.project.backendGitLabSshLink"
                        :backendGitLabHttpsLink="project.project.backendGitLabHttpsLink" />
                    </v-menu>
                  </v-col>

                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                    methods.getWorkspaceMemberRoles(true).reporter,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <button @click="exportPostmanProject" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-export-variant</v-icon>
                        Export Postman
                      </span>
                    </button>
                  </v-col>


                </v-row>
              </div>

              <div class="grey lighten-3 rounded mb-5 mt-5 pt-1 px-3">
                <p class="font-weight-bold mb-2 ">Model Options </p>
                <v-row>
                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <button @click="saveModels" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-content-save-outline</v-icon>
                        Pull Models
                      </span>
                    </button>
                  </v-col>
                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">

                    <button @click="buildErd" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-wrench-outline</v-icon>
                        Push Model
                      </span>
                    </button>
                  </v-col>
                </v-row>
              </div>

              <div class="grey lighten-3 rounded mb-5 mt-5 pt-1 px-3">
                <p class="font-weight-bold mb-2 ">Class Options </p>
                <v-row>
                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">
                    <button @click="buildClassDiagramProject('')" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-wrench-outline</v-icon>
                        Build Class Diagram
                      </span>
                    </button>
                  </v-col>

                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">
                    <button @click="buildClassDiagramProject('Restore')" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-restore</v-icon>
                        Restore Class Diagram
                      </span>
                    </button>


                  </v-col>

                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">
                    <button @click="saveClasses" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-content-save-outline</v-icon>
                        Pull Classes
                      </span>
                    </button>
                  </v-col>
                  <v-col v-if="[
                    methods.getWorkspaceMemberRoles(true).owner,
                    methods.getWorkspaceMemberRoles(true).developer,
                    methods.getWorkspaceMemberRoles(true).maintainer,
                  ].includes(project.workspaceMemberRole)
                  " cols="12" md="3">
                    <button @click="buildCodeProject" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-cog-sync-outline</v-icon>
                        Push Classes
                      </span>
                    </button>
                  </v-col>


                </v-row>
              </div>

              <v-row>





              </v-row>

            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Mobile -->
      <v-dialog class="d-md-none" v-model="mobileMainDialog" fullscreen hide-overlay
        transition="dialog-bottom-transition">
        <v-container class="white" fluid>
          <div class="py-1 mobile-main-dialog  w-100 px-5">
            <div class="d-flex align-center">
              <v-btn class="mr-2" icon @click="mobileMainDialog = false">
                <v-icon class="font-weight-bold" size="1rem">mdi-close</v-icon>
              </v-btn>
              <h3 class="orange lighten-3 rounded white--text px-3">Menu</h3>
            </div>
            <div>
              <div class="mt-3">
                <h1 class="h3-title secondary--text  pt-2 mr-lg-16 mr-md-1 ">
                  <span class="short-workspace-icon primary rounded white--text py-1 px-2 mr-2">{{
                    methods.getFirstCharacter(project.project.name) }}</span>
                  {{ project.project.name }}
                </h1>
                <v-row class="mt-5">
                  <v-col v-for="menu of mobileMenus " :key="menu.key" cols="4">
                    <div class="text-center">
                      <v-btn @click="activeMenu(menu.key)" class="mx-2 elevation-0 menu-btn "
                        :class="activatedMobileMenu == menu.key ? 'green lighten-4' : ''" fab>
                        <v-icon dark color="black">
                          {{ menu.icon }}
                        </v-icon>
                      </v-btn>
                      <p class="menu-btn-text mt-2 mb-0" :class="activatedMobileMenu == menu.key ? 'green--text' : ''">
                        {{
                          menu.name }}</p>
                    </div>
                  </v-col>
                  <v-col cols="4">
                    <div class="text-center">
                      <v-btn @click=" showMoreOptions = !showMoreOptions" class="mx-2 elevation-0 menu-btn " fab>
                        <v-icon dark color="black">
                          mdi-dots-horizontal-circle-outline
                        </v-icon>
                      </v-btn>
                      <p class="menu-btn-text mt-2 mb-0"> More Options</p>
                    </div>
                  </v-col>


                </v-row>
              </div>
            </div>
          </div>
        </v-container>

      </v-dialog>
    </div>
  </div>
</template>

<script>
import ClassDiagram from "@/components/user/ClassDiagram.vue";
import erd from "@/components/user/ERD.vue";
import PreviewCode from "@/components/user/PreviewCode.vue";
import ExportBackendCode from "@/components/user/ExportBackendCode.vue";
import { methods } from "@/utils/constant.js";
import {
  buildFrontend,
  buildErdProject,
  buildClassDiagramProject,
  buildCodeProject,
  buildBackend,
  exportPostmanProject,
  exportZipProject,
  pushProjectToGitLab,
  saveModels,
  saveClasses,
  // deleteProject,
  addFeature,

} from "@/api/user.js";

import html2canvas from "html2canvas";

import { mapGetters, mapActions } from "vuex";
import EditorX from "../../components/universal/EditorX.vue";
import Loading from "../../components/universal/Loading.vue";
import Loading2 from "../../components/universal/Loading2.vue";
import ProjectSetting from "../../components/user/ProjectSetting.vue";
// import MarchineServer from "../../components/user/MarchineServer.vue";
import MarchineServerList from "../../components/user/MarchineServerList.vue";
// import BackendTest from "../../components/user/BackendTest.vue";
import BackendTestList from "../../components/user/BackendTestList.vue";
export default {
  components: {
    erd,
    ClassDiagram,
    EditorX,
    Loading,
    Loading2,
    ProjectSetting,
    PreviewCode,
    ExportBackendCode,
    // MarchineServer,
    MarchineServerList,
    // BackendTest,
    BackendTestList
  },
  data() {
    return {
      mobileMainDialog: false,
      showMoreOptions: false,
      tab: null,
      loading: false,
      methods,
      headBtnLoading: false,
      headBtnLoadingText: "In progress",
      featureModel: {},
      callBackFeature: null,
      callBackBackendTest: null,
      backendTestModal: {},
      additionalModel: {
        obfuscateCode: false,
        exportCodeMenu: false,
        exportCodeSubMenu: false,
        exportCodeType: "Backend",
      },
      featureDialog: false,
      featureFieldsData: this.getFeatureFields(),
      showSetting: false,
      showBackendTestDialog: false,
      backendTestIsSelected: false,
      backendTestId: null,
      models: [],
      activatedMobileMenu: "ERD",
      mobileMenus: [
        {
          name: "ERD",
          icon: "mdi-view-grid",
          key: "ERD"
        },
        {
          name: "Class Diagram",
          icon: "mdi-view-grid-outline",
          key: "CLASS-DIAGRAM"
        },
        {
          name: "Backend Code",
          icon: "mdi-code-json",
          key: "BACKEND-CODE"
        },
        {
          name: "Workspace Connected Servers",
          icon: "mdi-server-network",
          key: "WORKSPACE-CONNECTED-SERVERS"
        },
        {
          name: "Backend Test (Beta)",
          icon: "mdi-motion-play-outline",
          key: "BACKEND-TEST"
        },
      ]
    };
  },
  computed: {
    ...mapGetters(["project",
      "activesSubscription",
      // "models", 
      "backendTests", "backendTest"]),
    featureFields: {
      get() {
        return this.featureFieldsData;
      },
      set(fields) {
        this.setFieldValue = true;
        // alert(1)
        this.featureFieldsData = fields;
        // console.log(fields)
        // return fields
      },
    },

  },
  watch: {
    project() {
      if (this.project && this.project.project) {
        this.$socket.emit('openProject', this.project.project._id)
      }
      if (this.project.models.data) {
        this.models = this.project.models.data
      }
    },
    models() {
      if (this.setFieldValue == false) {
        this.featureFieldsData = this.getFeatureFields();
        // console.log("feature", this.getFeatureFields());
      }
    },
  },
  sockets: {
    // openModel() {
    //   this.refreshModel()
    // }
  },
  methods: {
    ...mapActions([
      "getUserProfile",
      "getProject",
      "getModels",
      "getBackendTests",
      "getBackendTest",
    ]),
    activeMenu(key) {
      this.activatedMobileMenu = key
      this.mobileMainDialog = false
    },
    handledExportSuccess() {

      this.additionalModel = {
        obfuscateCode: false,
        exportCodeMenu: false,
        exportCodeSubMenu: false,
        exportCodeType: "Backend",
      }

    },
    refreshModel() {
      return new Promise((resolve, reject) => {
        this.getProject({
          id: this.$route.params.id,
        })
          .then(item => {
            resolve(item)
          }).catch(err => reject(err))
      })
    },

    async initialize() {
      try {
        this.loading = true;
        await this.getProject({
          id: this.$route.params.id,
        });

        // await this.getBackendTests({
        //   project: this.$route.params.id,
        // });
      } catch (error) {
        console.log("initialize project", error);
        methods.dispatchError(this.$store);
      } finally {
        this.loading = false;
        this.headBtnLoadingText = "In progress";
        this.headBtnLoading = false;
        this.featureFieldsData = this.getFeatureFields();
        this.featureDialog = false;
        this.showBackendTestDialog = false;
        this.backendTestModal = {};
        this.additionalModel = {
          obfuscateCode: false,
          exportCodeMenu: false,
          exportCodeSubMenu: false,
          exportCodeType: "Backend",
        };
        this.callBackBackendTest = null;
      }
    },
    onSuccess() {
      this.showBackendTestDialog = false;
      this.initialize();
    },
    chooseBtn(name) {
      this.activeClass = name;
    },
    async exportModelDiagram() {
      try {
        this.headBtnLoading = true;

        const contentElement = this.$refs.contentToExport;

        // Use html2canvas to render the content as an image
        const canvas = await html2canvas(contentElement);

        // Convert canvas to a data URL
        const imageDataURL = canvas.toDataURL("image/png");

        // Create a download link
        const link = document.createElement("a");
        link.href = imageDataURL;
        link.download = "exported_image.png";
        link.click();

        methods.dispatchSuccess(this.$store);
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async buildErd() {
      try {
        this.headBtnLoading = true;

        await buildErdProject({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        // this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async buildFrontend() {
      try {
        this.headBtnLoading = true;

        await buildFrontend({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async buildClassDiagramProject(manageType) {
      try {
        this.headBtnLoading = true;

        await buildClassDiagramProject({
          id: this.$route.params.id,
          manageType: manageType,
        });
        methods.dispatchSuccess(this.$store);
        this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async buildCodeProject() {
      try {
        this.headBtnLoading = true;

        await buildCodeProject({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        // this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async buildBackend() {
      try {
        this.headBtnLoading = true;

        await buildBackend({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async pushProjectToGitLab() {
      try {
        this.headBtnLoading = true;

        await pushProjectToGitLab({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        // this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async exportZipProject() {
      try {
        this.headBtnLoading = true;

        const url = await exportZipProject({
          id: this.$route.params.id,
          obfuscateCode: this.additionalModel.obfuscateCode,
          exportCodeType: this.additionalModel.exportCodeType,
        });
        methods.clickURL(url);
        methods.dispatchSuccess(this.$store);
        // this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
        this.additionalModel.exportCodeMenu = false;
        this.additionalModel.exportCodeSubMenu = false;
      }
    },
    async exportPostmanProject() {
      try {
        this.headBtnLoading = true;

        const url = await exportPostmanProject({
          id: this.$route.params.id,
        });
        methods.clickURL(url);
        methods.dispatchSuccess(this.$store);
        // this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },

    async addFeature() {
      try {
        this.featureModel = {};
        this.callBackFeature = addFeature;
        this.featureDialog = true;

        // methods.dispatchSuccess(this.$store)
      } catch (error) {
        methods.dispatchError(this.$store);
      }
    },

    async saveBackends() {
      try {
        this.headBtnLoading = true;
        // this.headBtnLoadingText = "Saving models in progress"
        await saveModels({
          id: this.$route.params.id,
        });
        await saveClasses({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async saveModels() {
      try {
        this.headBtnLoading = true;
        // this.headBtnLoadingText = "Saving models in progress"
        await saveModels({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },
    async saveClasses() {
      try {
        this.headBtnLoading = true;

        await saveClasses({
          id: this.$route.params.id,
        });
        methods.dispatchSuccess(this.$store);
        this.initialize();
      } catch (error) {
        console.log(error);
        methods.dispatchError(this.$store);
      } finally {
        this.headBtnLoading = false;
      }
    },


    getFeatureFields() {
      // let features = methods.getProjectFeature(false);
      // if (this.project && this.project.project) {
      //   features = methods.removeArrayElements(
      //     methods.getProjectFeature(false),
      //     this.project.project.features
      //   );
      // }

      const features = this.project && this.project.project && Array.isArray(this.project.project.features) ? this.project.project.features : []

      const xfeatures = []

      if (!features.includes(methods.getProjectFeature(true).simpleEnventoryMangement) && !features.includes(methods.getProjectFeature(true).eCommerceCartSystem)) {
        xfeatures.push({
          name: methods.getProjectFeature(true).simpleEnventoryMangement,
          icon: "mdi-account-hard-hat",
          badge: features.includes(methods.getProjectFeature(true).simpleEnventoryMangement) ? "free (already use)" : "free",
          authorizePlans: ["free", "standard", "advanced"]
        })
      }

      xfeatures.push({
        name: methods.getProjectFeature(true).simplePaymentSystem,
        icon: "mdi-account-hard-hat",
        badge: features.includes(methods.getProjectFeature(true).simplePaymentSystem) ? "standard+ (already use)" : "standard+",
        authorizePlans: ["standard", "advanced"]
      })
      if (!features.includes(methods.getProjectFeature(true).eCommerceCartSystem)) {
        xfeatures.push({
          name: methods.getProjectFeature(true).eCommerceCartSystem,
          icon: "mdi-account-hard-hat",
          badge: features.includes(methods.getProjectFeature(true).eCommerceCartSystem) ? "standard+ (already use)" : "standard+",
          authorizePlans: ["standard", "advanced"]
        })
      }

      return [
        {
          name: "project",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.$route.params.id,
        },
        {
          name: "feature",
          label: "Feature",
          type: "SelectX",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          userPlan: this.activesSubscription && this.activesSubscription.plan ? this.activesSubscription.plan.toLowerCase() : "free",

          select: {
            // data: features,
            data: xfeatures,
          },
          conditionFields: [
            {
              value: methods.getProjectFeature(true).simpleEnventoryMangement,
              fields: [
                {
                  name: "sep-code-1",
                  label: "Select the seller's model if it appears in the dropdown list.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "50rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },

                {
                  name: "sellerModel",
                  label: "Seller Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "sep-code-2",
                  label: "If the seller's model is not in the dropdown list above, create it by providing its name and type.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "100rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "sellerModelName",
                  label: "Seller Model Name",
                  hint: "Create seller model if seller did'nt exist  (default name : Seller)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },

                {
                  name: "sellerType",
                  label: "Seller Type",
                  hint: "If you mentionne Seller Model Name, you need to specify seller type too",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,

                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: [
                      // methods.getModelTypes(true).defaultType,
                      methods.getModelTypes(true).usernamePassword,
                      methods.getModelTypes(true).emailPassword,
                      methods.getModelTypes(true).emailOrUsernamePassword,
                    ],
                  },
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },

                {
                  name: "sep-code-1",
                  label: "Select the customer's model if it appears in the dropdown list.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "50rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "customerModel",
                  label: "Customer Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "sep-code-2",
                  label: "If the customer's model is not in the dropdown list above, create it by providing its name and type.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "100rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "customerModelName",
                  label: "Customer Model Name",
                  hint: "Create customer model if customer did'nt exist  (default name : Customer)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "customerType",
                  label: "Customer Type",
                  hint: "If you mentionne Customer Model Name, you need to specify customer type too",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,

                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: [
                      methods.getModelTypes(true).defaultType,
                      methods.getModelTypes(true).usernamePassword,
                      methods.getModelTypes(true).emailPassword,
                      methods.getModelTypes(true).emailOrUsernamePassword,
                    ],
                  },
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },

                {
                  name: "sep-code-5",
                  label: "Select the product's model if it appears in the dropdown list or create it by providing the product model name .",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "productModel",
                  label: "Product Model",
                  type: "Select",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "productModelName",
                  label: "Product Model Name",
                  hint: "Create product model if product did'nt exist  (default name : Product)",
                  type: "String",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "sep-code-5",
                  label: "Select the transaction's model if it appears in the dropdown list or create it by providing the transaction model name .",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },

                {
                  name: "transactionModel",
                  label: "Transaction Model",
                  type: "Select",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
                {
                  name: "transactionModelName",
                  label: "Transaction Model Name",
                  hint: "Create transaction model if transaction did'nt exist  (default name : Transaction)",
                  type: "String",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simpleEnventoryMangement,
                },
              ],
            },
            {
              value: methods.getProjectFeature(true).simplePaymentSystem,
              fields: [

                {
                  name: "sep-code-1",
                  label: "Select the seller's model if it appears in the dropdown list.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "50rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },

                {
                  name: "sellerModel",
                  label: "Seller Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "sep-code-2",
                  label: "If the seller's model is not in the dropdown list above, create it by providing its name and type.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "100rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "sellerModelName",
                  label: "Seller Model Name",
                  hint: "Create seller model if seller did'nt exist  (default name : Seller)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },

                {
                  name: "sellerType",
                  label: "Seller Type",
                  hint: "If you mentionne Seller Model Name, you need to specify seller type too",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,

                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: [
                      // methods.getModelTypes(true).defaultType,
                      methods.getModelTypes(true).usernamePassword,
                      methods.getModelTypes(true).emailPassword,
                      methods.getModelTypes(true).emailOrUsernamePassword,
                    ],
                  },
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },


                {
                  name: "sep-code-1",
                  label: "Select the customer's model if it appears in the dropdown list.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "50rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "customerModel",
                  label: "Customer Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "sep-code-2",
                  label: "If the customer's model is not in the dropdown list above, create it by providing its name and type.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "100rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "customerModelName",
                  label: "Customer Model Name",
                  hint: "Create customer model if customer did'nt exist  (default name : Customer)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "customerType",
                  label: "Customer Type",
                  hint: "If you mentionne Customer Model Name, you need to specify customer type too",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,

                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: [
                      methods.getModelTypes(true).defaultType,
                      methods.getModelTypes(true).usernamePassword,
                      methods.getModelTypes(true).emailPassword,
                      methods.getModelTypes(true).emailOrUsernamePassword,
                    ],
                  },
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },

                {
                  name: "sep-code-5",
                  label: "Select the transaction's model if it appears in the dropdown list or create it by providing the transaction model name .",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "transactionModel",
                  label: "Transaction Model",
                  type: "Select",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "transactionModelName",
                  label: "Transaction Model Name",
                  hint: "Create transaction model if transaction did'nt exist  (default name : Transaction)",
                  type: "String",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "sep-code-5",
                  label: "Choose at least one payment method.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "paymentMethods",
                  label: "Payment Methods",
                  hint: "Create seller model if seller did'nt exist  (default name : Seller)",
                  type: "Select",
                  col: 12,
                  md: 12,
                  multipleCol: 12,
                  multipleMdCol: 12,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: true,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: ["Fedapay", "Paypal"],
                    multiple: true
                  },
                  category:
                    methods.getProjectFeature(true).simplePaymentSystem,
                },
                {
                  name: "products",
                  label: "Transaction products",
                  emptyTexte: "Add the product models you wish to sell.",
                  multipleAddBtnText: "Add product model",
                  type: "FieldX",
                  multiple: true,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: false,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).simplePaymentSystem,
                  fields: [
                    {
                      name: "sep-code-5",
                      label: "Select the product's model if it appears in the dropdown list or create it by providing the product model name .",
                      type: "Separator",
                      size: "12px",
                      weight: "600",
                      width: "150rem",
                      align: "left",
                      textType: "left",
                      col: 12,
                      md: 12,
                      multipleCol: 12,
                      multipleMdCol: 12,
                      isRequiredForUpdate: false,
                      isRequiredForAdd: false,
                      existForUpdate: true,
                      existForAdd: true,
                      category:
                        methods.getProjectFeature(true).simplePaymentSystem,
                    },

                    {
                      name: "productModel",
                      label: "Product",
                      type: "Select",
                      hint: "Choose the product model you wish to sell. If the model does not exist, write the model name in the 'Product Model Name' field.",
                      col: 12,
                      md: 4,
                      multipleCol: 12,
                      multipleMdCol: 6,
                      isRequiredForUpdate: false,
                      isRequiredForAdd: false,
                      existForUpdate: true,
                      existForAdd: true,
                      select: {
                        store: {
                          action: this.getModels,
                          params: {
                            $axios: this.$axios,
                            project: this.$route.params.id,
                            // isActor:true
                          },
                          getter: this.models,
                          fieldName: "name",
                        },
                      },
                      category:
                        methods.getProjectFeature(true).simplePaymentSystem,
                    },
                    {
                      name: "productModelName",
                      label: "Product Model Name",
                      hint: "Enter the product model name if the model does not already exist.",
                      type: "String",
                      col: 12,
                      md: 4,
                      multipleCol: 12,
                      multipleMdCol: 6,
                      isRequiredForUpdate: false,
                      isRequiredForAdd: false,
                      existForUpdate: true,
                      existForAdd: true,
                      category:
                        methods.getProjectFeature(true).simplePaymentSystem,
                    },
                    {
                      name: "sep-code-5",
                      label: "Enter the name of the attribute that corresponds to the product amount (e.g.:amount).",
                      type: "Separator",
                      size: "12px",
                      weight: "600",
                      width: "150rem",
                      align: "left",
                      textType: "left",
                      col: 12,
                      md: 12,
                      multipleCol: 12,
                      multipleMdCol: 12,
                      isRequiredForUpdate: false,
                      isRequiredForAdd: false,
                      existForUpdate: true,
                      existForAdd: true,
                      category:
                        methods.getProjectFeature(true).simplePaymentSystem,
                    },
                    {
                      name: "productAmountField",
                      label: "Product Amount Name",
                      hint: "Enter the name of the attribute that corresponds to the product amount (e.g.:amount).",
                      type: "String",
                      col: 12,
                      md: 4,
                      multipleCol: 12,
                      multipleMdCol: 11,
                      isRequiredForUpdate: false,
                      isRequiredForAdd: true,
                      existForUpdate: true,
                      existForAdd: true,
                      default: "amount",
                      category:
                        methods.getProjectFeature(true).simplePaymentSystem,
                    },
                  ],

                }


              ],
            },
            {
              value: methods.getProjectFeature(true).eCommerceCartSystem,
              fields: [

                {
                  name: "sep-code-1",
                  label: "Select the seller's model if it appears in the dropdown list.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "50rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "sellerModel",
                  label: "Seller Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "sep-code-2",
                  label: "If the seller's model is not in the dropdown list above, create it by providing its name and type.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "100rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "sellerModelName",
                  label: "Seller Model Name",
                  hint: "Create seller model if seller did'nt exist  (default name : Seller)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "sellerType",
                  label: "Seller Type",
                  hint: "If you mentionne Seller Model Name, you need to specify seller type too",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,

                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: [
                      // methods.getModelTypes(true).defaultType,
                      methods.getModelTypes(true).usernamePassword,
                      methods.getModelTypes(true).emailPassword,
                      methods.getModelTypes(true).emailOrUsernamePassword,
                    ],
                  },
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },


                {
                  name: "sep-code-1",
                  label: "Select the customer's model if it appears in the dropdown list.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "50rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "customerModel",
                  label: "Customer Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "sep-code-2",
                  label: "If the customer's model is not in the dropdown list above, create it by providing its name and type.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "100rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "customerModelName",
                  label: "Customer Model Name",
                  hint: "Create customer model if customer did'nt exist  (default name : Customer)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "customerType",
                  label: "Customer Type",
                  hint: "If you mentionne Customer Model Name, you need to specify customer type too",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,

                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: [
                      methods.getModelTypes(true).usernamePassword,
                      methods.getModelTypes(true).emailPassword,
                      methods.getModelTypes(true).emailOrUsernamePassword,
                    ],
                  },
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },


                {
                  name: "sep-code-1",
                  label: "Select the cart's model if it appears in the dropdown list or create it by providing its name",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "70rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "cartModel",
                  label: "Cart Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "cartModelName",
                  label: "Cart Model Name",
                  hint: "Create cart model if cart didn't exist  (default name : Cart)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "sep-code-1",
                  label: "Select the cartDetail's model if it appears in the dropdown list or create it by providing its name",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "70rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "cartDetailModel",
                  label: "CartDetail Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "cartDetailModelName",
                  label: "CartDetail Model Name",
                  hint: "Create cartDetail model if cartDetail didn't exist  (default name : CartDetail)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "sep-code-1",
                  label: "Select the order's model if it appears in the dropdown list or create it by providing its name",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "70rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "orderModel",
                  label: "Order Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "orderModelName",
                  label: "Order Model Name",
                  hint: "Create order model if order didn't exist  (default name : Order)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "sep-code-1",
                  label: "Select the orderDetail's model if it appears in the dropdown list or create it by providing its name ",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "70rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "orderDetailModel",
                  label: "OrderDetail Model",
                  type: "Select",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "orderDetailModelName",
                  label: "OrderDetail Model Name",
                  hint: "Create orderDetail model if orderDetail didn't exist  (default name : OrderDetail)",
                  type: "String",
                  col: 12,
                  md: 4,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },


                {
                  name: "sep-code-5",
                  label: "Select the stockTransaction's model if it appears in the dropdown list or create it by providing the stockTransaction model name.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "stockTransactionModel",
                  label: "StockTransaction Model",
                  type: "Select",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "stockTransactionModelName",
                  label: "StockTransaction Model Name",
                  hint: "Create stockTransaction model if stockTransaction didn't exist (default name : StockTransaction)",
                  type: "String",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category: methods.getProjectFeature(true).eCommerceCartSystem,
                },



                {
                  name: "sep-code-5",
                  label: "Select the product's model if it appears in the dropdown list or create it by providing the product model name .",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "productModel",
                  label: "Product Model",
                  type: "Select",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        $axios: this.$axios,
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "productModelName",
                  label: "Product Model Name",
                  hint: "Create product model if product did'nt exist  (default name : Product)",
                  type: "String",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },

                {
                  name: "sep-code-5",
                  label: "Select the transaction's model if it appears in the dropdown list or create it by providing the transaction model name .",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "transactionModel",
                  label: "Transaction Model",
                  type: "Select",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 2,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    store: {
                      action: this.getModels,
                      params: {
                        project: this.$route.params.id,
                        // isActor:true
                      },
                      getter: this.models,
                      fieldName: "name",
                    },
                  },
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "transactionModelName",
                  label: "Transaction Model Name",
                  hint: "Create transaction model if transaction did'nt exist  (default name : Transaction)",
                  type: "String",
                  col: 12,
                  md: 6,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "sep-code-5",
                  label: "Choose at least one payment method.",
                  type: "Separator",
                  size: "12px",
                  weight: "600",
                  width: "150rem",
                  align: "left",
                  textType: "left",
                  col: 12,
                  md: 12,
                  multipleCol: 2,
                  multipleMdCol: 3,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: false,
                  existForUpdate: true,
                  existForAdd: true,
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },
                {
                  name: "paymentMethods",
                  label: "Payment Methods",
                  hint: "Create seller model if seller did'nt exist  (default name : Seller)",
                  type: "Select",
                  col: 12,
                  md: 12,
                  multipleCol: 12,
                  multipleMdCol: 12,
                  isRequiredForUpdate: false,
                  isRequiredForAdd: true,
                  existForUpdate: true,
                  existForAdd: true,
                  select: {
                    data: ["Fedapay", "Paypal"],
                    multiple: true
                  },
                  category:
                    methods.getProjectFeature(true).eCommerceCartSystem,
                },



              ],
            },
          ],
        },
      ];
    },
    manageShowSetting() {
      this.showSetting = !this.showSetting;
    },

  },
  beforeRouteLeave(to, from, next) {
    if (this.project && this.project.project) {
      this.$socket.emit('closeProject', this.project.project._id)
    }

    next();

  },
  mounted() {
    this.initialize();
  },
};
</script>

<style>
.mobile-menu-title {}

.menu-btn {
  background: rgba(135, 132, 132, 0.446);
}

.menu-btn-text {
  font-size: .6rem;
}

.mobile-main-dialog {
  overflow-y: scroll;
  height: 100vh;
}

.tab-items {
  height: 80vh;
  width: 98vw;
  overflow-y: scroll;
  overflow-x: scroll;
}

.head-project-content {
  max-width: 75vw;
  /* width: 100vw; */
}

@media screen and (min-width: 958px) {
  .head-project-content {
    width: 100vw;
  }
}

@media screen and (max-width: 1266px) {
  .head-project-content {
    max-width: 90vw;
    width: 100%;
  }

  .head-project-content-first {
    width: 100%;
  }
}

@media screen and (max-width: 1260px) {
  .head-project-content {
    width: 100vw;
  }
}
</style>