<template>
  <div>
    <div class="server">
      <div>
        <div class="server-head d-flex justify-space-between px-2">
          <h3 class="">{{ marchine.name }}</h3>

        </div>
        <div class="pb-5 px-2">
          <p class="mb-0 py-2">
            <span class="font-weight-bold">Host : </span>
            <span>{{ marchine.host }} : {{ marchine.port }} </span>
          </p>
          <p class="mb-0 py-2">
            <span class="font-weight-bold">Os : </span>
            <span>{{ marchine.os }} </span>
          </p>
          <p class="mb-0 py-2">
            <span class="font-weight-bold">Total App : </span>
            <span>{{ marchine.totalMarchineServerApp }} </span>
          </p>

          <div>
            <v-btn small @click="manageShowServer" class="white--text elevation-0 rounded" width="100%" color="green">
              Show <v-icon size="20px">mdi-cog-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>


    <v-dialog v-model="showMarchineServerDialog" max-width="1300px">
      <v-card class="px-5 py-5 scrollbar-style-14" color="white">
        <div>
          <div class="d-flex justify-space-between px-2 mb-3">
            <div>
              <h3 class="">{{ marchine.name }}</h3>
            </div>
            <div>
              <v-icon>mdi-server</v-icon>
            </div>
          </div>
          <div class="px-2 pt-1">
            <div class="">
              <p class="mb-0 py-2">
                <span class="font-weight-bold">Host : </span>
                <span>{{ marchine.host }} : {{ marchine.port }} </span>
              </p>
              <p class="mb-0 py-2">
                <span class="font-weight-bold">Os : </span>
                <span>{{ marchine.os }} </span>
              </p>
              <div class="server-app-container my-3 py-4">
                <div class="d-flex justify-space-between">
                  <p class="mb-0 py-2">
                    <span class="font-weight-bold">Total App : </span>
                    <span>{{ marchine.totalMarchineServerApp }} </span>
                  </p>

                  <span
                    v-if="[methods.getWorkspaceMemberRoles(true).owner, methods.getWorkspaceMemberRoles(true).developer, methods.getWorkspaceMemberRoles(true).maintainer].includes(workspaceMemberRole)">
                    <button @click="addServerApp" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-plus</v-icon>
                        Add Server App
                      </span>
                    </button>
                  </span>
                </div>

                <div>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Actions</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Database</th>
                          <th class="text-left">Port</th>
                          <th class="text-left">Path</th>
                          <th class="text-left">App Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="" v-for="(app, i) of marchine.marchineServerApps" :key="i">
                          <td>
                            <v-menu elevation="0" right offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" class="mr-10"
                                  :disabled="![methods.getWorkspaceMemberRoles(true).owner, methods.getWorkspaceMemberRoles(true).developer, methods.getWorkspaceMemberRoles(true).maintainer].includes(workspaceMemberRole)">
                                  <v-icon color="primary" size="23">mdi-forwardburger</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-tooltip bottom color="primary">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="" icon>
                                      <v-icon size="20" @click="manageShowServerApp(app)" v-bind="attrs" v-on="on"
                                        color="primary">mdi-server</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Show App</span>
                                </v-tooltip>
                                <v-tooltip bottom color="warning">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="" icon>
                                      <v-icon size="20" @click="updateServerApp(app)" v-bind="attrs" v-on="on"
                                        color="warning">mdi-pencil-box-multiple-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit Server</span>
                                </v-tooltip>

                                <v-tooltip bottom color="black">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="" icon>
                                      <v-icon size="20" @click="editServerAppConfigEnv(app)" v-bind="attrs" v-on="on"
                                        color="black">mdi-cogs</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit config.env </span>
                                </v-tooltip>
                                <v-tooltip bottom color="green">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="" icon>
                                      <v-icon size="20" @click="editServerAppNginxConf(app)" v-bind="attrs" v-on="on"
                                        color="green">mdi-contain</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit Nginx Config </span>
                                </v-tooltip>

                                <v-tooltip bottom color="red">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn class="" icon>
                                      <v-icon size="20" @click="deleteServerApp(app)" v-bind="attrs" v-on="on"
                                        color="red">mdi-delete</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete Server</span>
                                </v-tooltip>
                              </v-list>
                            </v-menu>
                          </td>
                          <td>
                            <div class="d-flex py-1">
                              <div class="">
                                <h3 class="h5-title">
                                  {{ app.name }}
                                </h3>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h3 class="h5-title">
                                {{ app.database }}
                              </h3>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h3 class="h5-title">
                                {{ app.port }}
                              </h3>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h3 class="h5-title">
                                {{ app.basePath }}{{ app.path }}
                              </h3>
                            </div>
                          </td>
                          <td>
                            <div>
                              <h3 class="h5-title" :class="getProjectTypeClass(app.projectType)">
                                {{ app.projectType }}
                              </h3>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showMarchineServerAppServerDialog" max-width="1200px">
      <v-card class="px-5 py-5 scrollbar-style-14" color="white">
        <div>
          <div class="d-flex justify-space-between px-2 mb-3">
            <div>
              <h3 class="">{{ marchineApp.name }}</h3>
            </div>
            <div>
              <v-icon>mdi-server</v-icon>
            </div>
          </div>
          <div class="px-2 pt-1">
            <div class="">
              <p class="mb-0 py-2">
                <span class="font-weight-bold">App Type : </span>
                <span>{{ marchineApp.projectType }} </span>
              </p>
              <p class="mb-0 py-2">
                <span class="font-weight-bold">Os : </span>
                <span>{{ marchine.os }} </span>
              </p>
              <p v-if="!marchineApp.domainName" class="mb-0 py-2 red lighten-5 px-2 rounded text-center">
                <span class="font-weight-bold"><v-icon color="red">mdi-alert</v-icon> </span>
                <span class="red--text ml-2">{{ `You cannot access the Nginx server configuration until you have filled
                  out
                  the domain name field. ` }}

                  <v-btn small color="red" @click="updateServerApp(marchineApp)"
                    class="elevation-0 white--text mx-0 mt-2">
                    <span><v-icon size="16px" color="white" class="mr-1">mdi-pencil-box-multiple-outline</v-icon>
                      Fill out the domain name field
                    </span>
                  </v-btn>
                </span>
              </p>
              <div class="server-app-container my-3 pt-4 pb-2">
                <p class="mb-2 font-weight-bold">Global : </p>
                <div class="d-flex">
                  <span class="mr-2">
                    <button @click="openMarchineServerAppTerminal(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-console</v-icon>
                        Terminal
                      </span>
                    </button>

                  </span>

                  <v-menu v-model="appPrerequestModule" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="mr-2">
                        <!-- @click="gitCheckoutServerApp(marchineApp)"  -->
                        <button v-bind="attrs" v-on="on" class="m-btn-dense mx-0">
                          <span><v-icon size="16px" color="green" class="mr-1">mdi-application-array-outline</v-icon>
                            Prerequisites
                          </span>
                        </button>
                      </span>
                    </template>

                    <div>
                      <div class="container white">
                        <h3>Configuration Check</h3>
                        <p>To ensure your system is properly configured, the following software needs to be installed:
                        </p>
                        <ul>
                          <li>nginx</li>
                          <li>nvm (Node Version Manager) with Node.js 18</li>
                          <li>npm</li>
                          <li>pm2</li>
                        </ul>
                        <p>If you do not already have this configuration, please click the "Install" button below to
                          install
                          the necessary software.</p>
                        <div class="button-container">
                          <button @click="installRequiredModuleInServerApp(marchineApp)"
                            class="m-btn-dense mx-0">
                            <span><v-icon size="16px" color="green" class="mr-1">mdi-application-array-outline</v-icon>
                              Install
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </v-menu>

                </div>
              </div>
              <div class="server-app-container my-3 pt-4 pb-2">
                <p class="mb-2 font-weight-bold">Git command : </p>
                <div class="d-flex">
                  <span class="mr-2">
                    <button @click="gitCloneServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-gitlab</v-icon>
                        Clone App
                      </span>
                    </button>
                  </span>

                  <span class="mr-2">
                    <button @click="gitPullServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-git</v-icon>
                        Git Pull
                      </span>
                    </button>
                  </span>
                  <v-menu v-model="gitCheckoutMenu" :close-on-content-click="false" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="mr-2">
                        <!-- @click="gitCheckoutServerApp(marchineApp)"  -->
                        <button v-bind="attrs" v-on="on" class="m-btn-dense mx-0">
                          <span><v-icon size="16px" color="green" class="mr-1">mdi-tournament</v-icon>
                            Git Checkout
                          </span>
                        </button>
                      </span>
                    </template>

                    <div>
                      <marchine-server-app-checkout :id="marchineApp._id" @success="onSuccess" />
                    </div>
                  </v-menu>

                  <span class="mr-2">
                    <button @click="updateMarchineServerAppGitAccessToken(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-gitlab</v-icon>
                        Update GitLab Access Token On Server
                      </span>
                    </button>
                  </span>

                </div>
              </div>
              <div class="server-app-container my-3 pt-4 pb-2">
                <p class="mb-2 font-weight-bold">App configuration and command : </p>

                <div class="d-flex">
                  <span class="mr-2">
                    <button @click="npmInstallServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-npm</v-icon>
                        Install App
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="editServerAppConfigEnv(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-cogs</v-icon>
                        Edit Env File
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="pushEnFileAppServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-git</v-icon>
                        Push Env File
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="startAppServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-power</v-icon>
                        Start App
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="restartAppServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-restart</v-icon>
                        Restart App
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="stopAppServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-power-off</v-icon>
                        Stop App
                      </span>
                    </button>
                  </span>

                 
                </div>
              </div>
              <div v-if="marchineApp.domainName" class="server-app-container my-3 pt-4 pb-2">
                <p class="mb-2 font-weight-bold">Nginx configuration : </p>

                <div class="d-flex">

                  <span class="mr-2">
                    <button @click="editServerAppNginxConf(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-contain</v-icon>
                        Edit Nginx Conf
                      </span>
                    </button>
                  </span>

                  <span class="mr-2">
                    <button @click="editSSLCertificate(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-certificate</v-icon>
                        SSL Certificate
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="editSSLCertificateKey(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-certificate-outline</v-icon>
                        SSL Certificate Key
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="editSSLClientCertificate(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-certificate</v-icon>
                        SSL Client Certificate
                      </span>
                    </button>
                  </span>

                  <span class="mr-2">
                    <button @click="regenerateNginxConfAppServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-restart</v-icon>
                        Regenerate Nginx Conf
                      </span>
                    </button>
                  </span>
                </div>
              </div>
              <div class="server-app-container my-3 pt-4 pb-2">
                <p class="mb-2 font-weight-bold">Nginx command : </p>

                <div class="d-flex">
                  <span class="mr-2">
                    <button @click="pushNginxConfAppServerApp(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-server</v-icon>
                        Push Nginx
                      </span>
                    </button>
                  </span>

                  <span class="mr-2">
                    <button @click="checkNginxConfiguration(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-check-all</v-icon>
                        Check Nginx Conf
                      </span>
                    </button>
                  </span>
                  <span class="mr-2">
                    <button @click="restartNginxConfiguration(marchineApp)" class="m-btn-dense mx-0">
                      <span><v-icon size="16px" color="green" class="mr-1">mdi-restart</v-icon>
                        Restart Nginx Conf
                      </span>
                    </button>
                  </span>


                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMachineServerAppDialog" max-width="50rem">
      <editor-x @success="onSuccess" :fields="getMarchineServerAppFields()" :callback="callBackMarchinServerApp"
        :model="marchineServerAppModal" title="App" v-if="showMachineServerAppDialog"
        :submitText="marchineServerAppText" :withoutImages="withoutImages" />
    </v-dialog>
    <v-dialog v-model="openMarchineServerAppTerminalDialog" max-width="98%">
      <marchine-server-app-terminal v-if="openMarchineServerAppTerminalDialog" :app="marchineServerAppModal"
        @closeDialog="closeMarchineServerAppTerminalDialog" />
    </v-dialog>


    <loading-2 v-if="loading" :text="loadingText" />

  </div>
</template>

<script>

import { methods } from "@/utils/constant.js";

import {
  createMarchineServerApp,
  updateMarchineServerApp,
  uploadSslCertificate,
  uploadSslCertificateKey,
  uploadClientSslCertificate,
  deleteMarchineServerApp,
  gitCloneServerApp,
  installRequiredModuleInServerApp,
  gitPullServerApp,
  updateMarchineServerAppGitAccessToken,
  gitCheckoutServerApp,
  npmInstallServerApp,
  startAppServerApp,
  restartAppServerApp,
  pushEnFileAppServerApp,
  pushNginxConfAppServerApp,
  regenerateNginxConfAppServerApp,
  stopAppServerApp,
  checkNginxConfiguration,
  restartNginxConfiguration,
} from "@/api/user.js";

import EditorX from "../universal/EditorX.vue";
import Loading2 from "../universal/Loading2.vue";
import MarchineServerAppCheckout from "./MarchineServerAppCheckout.vue";
import MarchineServerAppTerminal from "./MarchineServerAppTerminal.vue";

export default {
  components: { EditorX, Loading2, MarchineServerAppCheckout, MarchineServerAppTerminal },
  props: {
    marchine: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    workspaceMemberRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showMarchineServerDialog: false,
      showMachineServerAppDialog: false,
      loading: false,
      gitCheckoutMenu: false,
      appPrerequestModule: false,
      loadingText: "In progress",

      showMarchineServerAppServerDialog: false,
      callBackMarchinServerApp: null,
      marchineServerAppDialogType: "",
      marchineServerAppModal: {},
      marchineApp: {},
      marchineServerAppText: "Create Server App",
      methods,
      openMarchineServerAppTerminalDialog: false
    };
  },
  methods: {
    getMarchineServerAppFields() {
      let fields = this.marchineServerAppFields;

      if (this.marchineServerAppDialogType == "config.env") {
        fields = this.marchineServerAppConfigEnvFields;
      }
      if (this.marchineServerAppDialogType == "nginx.conf") {
        fields = this.marchineServerAppNginxConfFields;
      }
      if (this.marchineServerAppDialogType == "ssl-certificate") {
        fields = this.sslCertificateConfFields;
      }
      if (this.marchineServerAppDialogType == "ssl-certificate-key") {
        fields = this.sslCertificateKeyConfFields;
      }
      if (this.marchineServerAppDialogType == "ssl-client-certificate") {
        fields = this.sslClientCertificateConfFields;
      }

      return fields;
    },
    manageShowServer() {
      this.showMarchineServerDialog = !this.showMarchineServerDialog;
    },
    manageShowServerApp(app) {
      this.marchineApp = app;
      this.showMarchineServerAppServerDialog =
        !this.showMarchineServerAppServerDialog;
    },
    addServerApp() {
      this.marchineServerAppText = "Create Server App";

      this.marchineServerAppDialogType = "normal";
      this.showMachineServerAppDialog = !this.showMachineServerAppDialog;
      this.callBackMarchinServerApp = createMarchineServerApp;
      this.marchineServerAppModal = {};
    },
    updateServerApp(app) {
      this.marchineServerAppText = "Update Server App";

      this.marchineServerAppDialogType = "normal";
      this.showMachineServerAppDialog = !this.showMachineServerAppDialog;
      this.callBackMarchinServerApp = updateMarchineServerApp;
      this.marchineServerAppModal = Object.assign({}, app);
    },
    editServerAppConfigEnv(app) {
      this.marchineServerAppText = "Update Config.env";

      this.marchineServerAppDialogType = "config.env";
      this.showMachineServerAppDialog = !this.showMachineServerAppDialog;
      this.callBackMarchinServerApp = updateMarchineServerApp;
      this.marchineServerAppModal = Object.assign({}, app);
    },
    editSSLCertificate(app) {
      this.marchineServerAppText = "Upload SSL Certificate";

      this.marchineServerAppDialogType = "ssl-certificate";
      this.showMachineServerAppDialog = !this.showMachineServerAppDialog;
      this.callBackMarchinServerApp = uploadSslCertificate;
      this.marchineServerAppModal = Object.assign({}, app);
    },
    editSSLCertificateKey(app) {
      this.marchineServerAppText = "Upload SSL Certificate Key";

      this.marchineServerAppDialogType = "ssl-certificate-key";
      this.showMachineServerAppDialog = !this.showMachineServerAppDialog;
      this.callBackMarchinServerApp = uploadSslCertificateKey;
      this.marchineServerAppModal = Object.assign({}, app);
    },
    editSSLClientCertificate(app) {
      this.marchineServerAppText = "Upload SSL Client Certificate";

      this.marchineServerAppDialogType = "ssl-client-certificate";
      this.showMachineServerAppDialog = !this.showMachineServerAppDialog;
      this.callBackMarchinServerApp = uploadClientSslCertificate;
      this.marchineServerAppModal = Object.assign({}, app);
    },
    editServerAppNginxConf(app) {
      this.marchineServerAppText = "Update Nginx configuration";
      this.marchineServerAppDialogType = "nginx.conf";
      this.showMachineServerAppDialog = !this.showMachineServerAppDialog;
      this.callBackMarchinServerApp = updateMarchineServerApp;
      this.marchineServerAppModal = Object.assign({}, app);
    },
    deleteServerApp(app) {
      methods.dispatchDeleteDialog(this.$store, {
        dialog: true,
        what: "Do you really want to delete this app ?",
        callback: deleteMarchineServerApp,
        data: { id: app._id },
        successHandler: () => {
          this.onSuccess();
        },
        description: `<div class="container">
        <h3>Warning: Manual Deletion Required</h3>
        <p>Please be advised that deleting this application will not remove the deployment from your server. For security reasons, you will need to manually delete the deployed application from your server.</p>
        <div class="steps">
            <h4>Important Steps:</h4>
            <ol>
                <li>We will stop the application on PM2 as part of the deletion process.</li>
                <li>You must manually remove the deployed application from your server to ensure it is completely uninstalled.</li>
            </ol>
        </div>
        <p>Ensure you follow these steps to maintain the security and integrity of your server.</p>
    </div>`,
      });
    },

    async gitCloneServerApp(app) {
      try {
        this.loading = true;

        const output = await gitCloneServerApp({
          id: app._id,
        });


        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }
        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },

    async installRequiredModuleInServerApp(app) {
      try {
        this.loading = true;

        const output = await installRequiredModuleInServerApp({
          id: app._id,
        });


        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }
        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async gitPullServerApp(app) {
      try {
        this.loading = true;

        const output = await gitPullServerApp({
          id: app._id,
        });


        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }
        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async updateMarchineServerAppGitAccessToken(app) {
      try {
        this.loading = true;

        const output = await updateMarchineServerAppGitAccessToken({
          id: app._id,
        });


        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }
        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async gitCheckoutServerApp(app) {
      try {
        this.loading = true;

        const output = await gitCheckoutServerApp({
          id: app._id,
        });


        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }

        this.success = true;
        this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async npmInstallServerApp(app) {
      try {
        this.loading = true;

        const output = await npmInstallServerApp({
          id: app._id,
        });

        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }
        this.success = true;
        this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async startAppServerApp(app) {
      try {
        this.loading = true;

        const output = await startAppServerApp({
          id: app._id,
        });

        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }
        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async restartAppServerApp(app) {
      try {
        this.loading = true;

        const output = await restartAppServerApp({
          id: app._id,
        });

        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }

        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async stopAppServerApp(app) {
      try {
        this.loading = true;

        const output = await stopAppServerApp({
          id: app._id,
        });


        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }
        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async pushEnFileAppServerApp(app) {
      try {
        this.loading = true;

        const output = await pushEnFileAppServerApp({
          id: app._id,
        });

        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }

        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async regenerateNginxConfAppServerApp(app) {
      try {
        this.loading = true;

        await regenerateNginxConfAppServerApp({
          id: app._id,
        });

        // if (output) {
        //   methods.dispatchConsoleComponenent(this.$store, {
        //     messages: output.messages || []
        //   })
        // }

        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
        this.$emit("refresh");

      }
    },
    async pushNginxConfAppServerApp(app) {
      try {
        this.loading = true;

        const output = await pushNginxConfAppServerApp({
          id: app._id,
        });

        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }

        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async checkNginxConfiguration(app) {
      try {
        this.loading = true;

        const output = await checkNginxConfiguration({
          id: app._id,
        });

        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }

        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async restartNginxConfiguration(app) {
      try {
        this.loading = true;

        const output = await restartNginxConfiguration({
          id: app._id,
        });

        if (output) {
          methods.dispatchConsoleComponenent(this.$store, {
            messages: output.messages || []
          })
        }

        this.success = true;
        // this.$emit("refresh");
      } catch (error) {
        console.log(error);
        this.success = false;
      } finally {
        this.loading = false;

        this.initialize();
      }
    },
    async openMarchineServerAppTerminal(app) {
      try {
        this.openMarchineServerAppTerminalDialog = true

        this.marchineServerAppModal = app
      } catch (error) {
        console.log(error)
      }
    },
    async closeMarchineServerAppTerminalDialog() {
      try {
        this.openMarchineServerAppTerminalDialog = false
        // this.marchineServerAppModal = app
      } catch (error) {
        console.log(error)
      }
    },
    getProjectTypeClass(type) {
      let result = "";
      if (type == methods.getMarchineServerAppProjectType(true).backendNodejs) {
        result = "green--text";
      }
      if (type == methods.getMarchineServerAppProjectType(true).frontendNuxt) {
        result = "orange--text";
      }

      return result;
    },
    initialize() {
      this.marchineServerAppModal = {};
      this.callBackMarchinServerApp = null;
      this.showMachineServerAppDialog = false;
      this.showMarchineServerDialog = false;
    },
    onSuccess(data) {

      if (data && Array.isArray(data.messages)) {
        methods.dispatchConsoleComponenent(this.$store, {
          messages: data.messages || []
        })
      }

      this.initialize();
      this.$emit("refresh");
    },
  },
  computed: {

    withoutImages() {
      let output = true

      if (["ssl-certificate", "ssl-certificate-key"].includes(this.marchineServerAppDialogType)) {
        output = false
      }

      return output
    },
    marchineServerAppFields() {
      return [
        {
          name: "marchineServer",
          label: "",
          type: "Static",
          col: 6,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.marchine._id,
        },
        {
          name: "app-Info",
          label: "App information",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "project",
          label: "",
          type: "Static",
          col: 6,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value: this.projectId,
        },
        {
          name: "name",
          label: "App Name",
          placeholder: "Name",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },

        {
          name: "projectType",
          label: "App Type",
          type: "Select",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 2,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: methods.getMarchineServerAppProjectType(false),
          },
        },

        {
          name: "port",
          label: "App Port",
          placeholder: "3000",
          type: "Number",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-router-network",
        },


        {
          name: "path",
          label: "Full path to your application on the server",
          type: "String",
          placeholder: "/root/deploy-app",
          hint: "The user you use to create your OrangCode server must have both create and read permissions on this path",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-folder",
        },
        {
          name: "domainName",
          label: "App Domain Name",
          hint: "You cannot access the configuration of the Nginx server until you have filled out this field",
          placeholder: "",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-web",
        },

        {
          name: "sep-backend",
          label: "Database Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "htmlPreviewDb",
          value: `<div class="container">
        <h3>Modify Database Information</h3>
        <p>If you need to modify the database information, please follow the instructions below:</p>
        <div class="steps">
            <h4>Steps to Modify:</h4>
            <ol>
                <li>Open the <code>Edit config.env</code> dialogue .</li>
                <li>Find the line that starts with <code>MONGO_URL</code>.</li>
                <li>Modify the MongoDB connection URL to your new database information.</li>
                <li>Save the changes and close the dialogue.</li>
            </ol>
        </div>
        <p>Ensure you correctly update the <code>MONGO_URL</code> to avoid connection issues with your database.</p>
    </div>`,
          type: "HTMLPreview",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: false,
          icon: "mdi-database-cog",
          class: "alert"
        },
        {
          name: "database",
          label: "Database Name",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          icon: "mdi-database-cog",
        },
        {
          name: "databaseHost",
          hint: "The hostname or IP address where your database is running",
          label: "Database Host",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          icon: "mdi-database-cog",
          default: "localhost"

        },
        {
          name: "databasePort",
          hint: "The port number on which your database is listening for connections.",
          label: "Database Port",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          icon: "mdi-database-cog",
          default: "27017"
        },


        {
          name: "databaseUsername",
          label: "Database Username",
          hint: " If authentication is enabled, the username used to connect to the database",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          icon: "mdi-database-cog",
        },
        {
          name: "databasePassword",
          label: "Database Password",
          hint: "The password associated with the username for authentication",
          type: "String",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: false,
          existForAdd: true,
          icon: "mdi-database-cog",
        },

        {
          name: "gitlab-Info",
          label: "Gitlab Information",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },

        {
          name: "projectGitLabPath",
          label: "GitLab Project Path",
          type: "String",
          placeholder: "gitlab.com/projectPathOnGitLab.git",
          hint: "If you wish to use a custom repository to deploy your app, please provide the GitLab path here. Otherwise, we will use the default repository created for this project.",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-gitlab",
        },
        {
          name: "gitLabAccessToken",
          label: "GitLab Access token",
          type: "String",
          placeholder: "",
          hint: "To proceed, you'll need to generate a Personal Access Token (PAT) on GitLab with API privileges. Once generated, copy the token and paste it here for authentication.",
          col: 12,
          md: 6,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-passport",
        },
      ];
    },
    marchineServerAppConfigEnvFields() {
      return [
        {
          name: "configEnv",
          label: "Config.env",
          placeholder: "",
          type: "Code",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
        },
      ];
    },
    marchineServerAppNginxConfFields() {
      return [

        {
          name: "nginx-conf",
          label: "Upload Certificate ",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "nginxConfChoice",
          label: "Way to deploy nginx conf",
          type: "Radio",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
          // default:"default",
          radio: {
            data: [{
              "name": "Site aivailable - Site enable",
              value: "default"
            },
            {
              "name": "Custom Path",
              value: "custom"
            },

            ]
          }
        },

        {
          name: "nginxConfFileName",
          label: `File name on this directory : /etc/nginx/site-availables/`,
          placeholder: "",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          isHidden: true,
          conditions: [
            {
              field: "nginxConfChoice",
              values: ['default'],
              action: "show"
            },
          ],
        },
        {
          name: "nginxConf",
          label: `File in : /etc/nginx/site-availables/${this.marchineServerAppModal.nginxConfFileName || '-'}`,
          placeholder: "",
          type: "Code",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          isHidden: true,
          conditions: [
            {
              field: "nginxConfChoice",
              values: ['default'],
              action: "show"
            },
          ],
        },
        {
          name: "nginxConfFileFullPath",
          label: `Full path to the place we will create de nginx file`,
          placeholder: "",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          isHidden: true,
          conditions: [
            {
              field: "nginxConfChoice",
              values: ['custom'],
              action: "show"
            },
          ],
        },
        {
          name: "nginxConf",
          label: `File in : ${this.marchineServerAppModal.nginxConfFileFullPath || '-'}`,
          placeholder: "",
          type: "Code",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          isHidden: true,
          conditions: [
            {
              field: "nginxConfChoice",
              values: ['custom'],
              action: "show"
            },
          ],
        },
      ];
    },
    sslCertificateConfFields() {
      return [



        {
          name: "upload-certificate",
          label: "Upload Certificate ",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "sslCertificateChoice",
          label: "Upload choice",
          type: "Radio",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
          // default:"text",
          radio: {
            data: [{
              "name": "Text",
              value: "text"
            },
            {
              "name": "File",
              value: "file"
            },
            {
              "name": "Path",
              value: "path"
            }
            ]
          }
        },


        {
          name: "sslCertificateFile",
          label: "Please select the SSL certificate file you wish to upload.",
          type: "File",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
          isHidden: true,
          conditions: [
            {
              field: "sslCertificateChoice",
              values: ['file'],
              action: "show"
            },
          ],
        },
        {
          name: "sslCertificateContent",
          label: `The content of your SSL certificate file:`,
          placeholder: "",
          type: "Code",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          isHidden: true,
          conditions: [
            {
              field: "sslCertificateChoice",
              values: ['text'],
              action: "show"
            },
          ],
        },

        {
          name: "sep-basic-config",
          label: "SSL Certificate Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          conditions: [
            {
              field: "sslCertificateChoice",
              values: ['file', 'text', 'path'],
              action: "show"
            },
          ],
        },
        {
          name: "sslCertificateFileFullPath",
          label: "Please provide the full path to your SSL certificate file on the server.",
          hint: "Make sure that the file is located at the path you just provided",
          placeholder: "path",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslCertificateChoice",
              values: ['path'],
              action: "show"
            },
          ],
        },

        {
          name: "sslCertificateFileName",
          label: "Please enter the name you'd like to use for your SSL certificate file on the server",
          hint: "You don't need to include the file extension. We will automatically append '.crt' for you.",
          placeholder: "domain_name_com_chain",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslCertificateChoice",
              values: ['file', 'text'],
              action: "show"
            },
          ],
        },
        {
          name: "sslCertificateFilePath",
          label: "Please specify the folder where you'd like to upload the SSL certificate file on the server.",
          hint: "The user you use to create your OrangCode server must have both create and read permissions on this path",
          placeholder: "path",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslCertificateChoice",
              values: ['file', 'text'],
              action: "show"
            },
          ],
        },

      ];
    },

    sslCertificateKeyConfFields() {
      return [




        {
          name: "upload-certificate",
          label: "Upload Certificate ",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "sslCertificateKeyChoice",
          label: "Upload choice",
          type: "Radio",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
          // default:"text",
          radio: {
            data: [{
              "name": "Text",
              value: "text"
            },
            {
              "name": "File",
              value: "file"
            },
            {
              "name": "Path",
              value: "path"
            }
            ]
          }
        },
        {
          name: "sslCertificateKeyFile",
          label: "Please select the SSL certificate key file you wish to upload.",
          type: "File",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
          isHidden: true,
          conditions: [
            {
              field: "sslCertificateKeyChoice",
              values: ['file'],
              action: "show"
            },
          ],
        },
        {
          name: "sslCertificateKeyContent",
          label: `The content of your SSL certificate key file:`,
          placeholder: "",
          type: "Code",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          isHidden: true,
          conditions: [
            {
              field: "sslCertificateKeyChoice",
              values: ['text'],
              action: "show"
            },
          ],
        },

        {
          name: "sep-basic-config",
          label: "SSL Certificate key Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          conditions: [
            {
              field: "sslCertificateKeyChoice",
              values: ['file', 'text', 'path'],
              action: "show"
            },
          ],
        },

        {
          name: "sslCertificateKeyFileFullPath",
          label: "Please provide the full path to your SSL certificate key file on the server.",
          hint: "Make sure that the file is located at the path you just provided",
          placeholder: "path",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslCertificateKeyChoice",
              values: ['path'],
              action: "show"
            },
          ],
        },

        {
          name: "sslCertificateKeyFileName",
          label: "Please enter the name you'd like to use for your SSL certificate key file on the server",
          hint: "You don't need to include the file extension. We will automatically append '.crt' for you.",
          placeholder: "domain_name_com_chain",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslCertificateKeyChoice",
              values: ['file', 'text'],
              action: "show"
            },
          ],
        },
        {
          name: "sslCertificateKeyFilePath",
          label: "Please specify the folder where you'd like to upload the SSL certificate key file on the server.",
          hint: "The user you use to create your OrangCode server must have both create and read permissions on this path",
          placeholder: "path",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslCertificateKeyChoice",
              values: ['file', 'text'],
              action: "show"
            },
          ],
        },


      ];
    },
    sslClientCertificateConfFields() {
      return [




        {
          name: "upload-certificate",
          label: "Upload Certificate ",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "180px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "sslClientCertificateChoice",
          label: "Upload choice",
          type: "Radio",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
          // default:"text",
          radio: {
            data: [{
              "name": "Text",
              value: "text"
            },
            {
              "name": "File",
              value: "file"
            },
            {
              "name": "Path",
              value: "path"
            }
            ]
          }
        },
        {
          name: "sslClientCertificateFile",
          label: "Please select the SSL certificate key file you wish to upload.",
          type: "File",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-upload",
          isHidden: true,
          conditions: [
            {
              field: "sslClientCertificateChoice",
              values: ['file'],
              action: "show"
            },
          ],
        },
        {
          name: "sslClientCertificateContent",
          label: `The content of your SSL Client Certificate file:`,
          placeholder: "",
          type: "Code",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          isHidden: true,
          conditions: [
            {
              field: "sslClientCertificateChoice",
              values: ['text'],
              action: "show"
            },
          ],
        },

        {
          name: "sep-basic-config",
          label: "SSL Client Certificate Config",
          type: "Separator",
          size: "12px",
          weight: "600",
          width: "250px",
          align: "left",
          textType: "left",
          col: 12,
          md: 12,
          multipleCol: 12,
          multipleMdCol: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
          conditions: [
            {
              field: "sslClientCertificateChoice",
              values: ['file', 'text', 'path'],
              action: "show"
            },
          ],
        },

        {
          name: "sslClientCertificateFileFullPath",
          label: "Please provide the full path to your SSL client certificate file on the server.",
          hint: "Make sure that the file is located at the path you just provided",
          placeholder: "path",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslClientCertificateChoice",
              values: ['path'],
              action: "show"
            },
          ],
        },

        {
          name: "sslClientCertificateFileName",
          label: "Please enter the name you'd like to use for your SSL client certificate file on the server",
          hint: "You don't need to include the file extension. We will automatically append '.crt' for you.",
          placeholder: "domain_name_com_chain",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslClientCertificateChoice",
              values: ['file', 'text'],
              action: "show"
            },
          ],
        },
        {
          name: "sslClientCertificateFilePath",
          label: "Please specify the folder where you'd like to upload the SSL client certificate file on the server.",
          hint: "The user you use to create your OrangCode server must have both create and read permissions on this path",
          placeholder: "path",
          type: "String",
          col: 12,
          multipleCol: 2,
          multipleMdCol: 3,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          icon: "mdi-card-bulleted-settings-outline",
          conditions: [
            {
              field: "sslClientCertificateChoice",
              values: ['file', 'text'],
              action: "show"
            },
          ],
        },


      ];
    },
  },
};
</script>

<style>
.server {
  border: 1px solid rgb(200, 197, 197);
  border-radius: 5px;
}

.server-head {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #efefef;
}

.server-head h3 {
  font-size: 0.9rem;
}

.server-app-container {
  border-top: 1px solid rgb(230, 228, 228);
}
</style>