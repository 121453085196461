import ParentController from './ParentController'

class BaseControllerMethods extends ParentController {
    static async manageAlgo(model) {
        return new Promise((resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(models => {

                    const manageModel = ParentController.methods().getItemId(models, model.manageModel, "name", "*")

                    let constantCode = `
                    `
                    let preAction = ``
                    let action = ``
                    let response = ``
                    let argument = ``


                    // for (let attribute of manageModel.attributes) {
                    //     // is not provide by system 
                    //     if (!attribute.fromSystem) {
                    //         // Hash 
                    //         if (attribute.isHash) {

                    //             preAction += `const ${attribute.name}Salt = await this.bcrypt.genSalt(10);
                    //             const hash${attribute.name.capitalize()} = await this.bcrypt.hash(schema.password, ${attribute.name}Salt);
                    //             actionSchema.${attribute.name} = hash${attribute.name.capitalize()} `
                    //         }

                    //         // other 
                    //         else {
                    //             preAction += `
                    //             if(schema.${attribute.name}){
                    //             `

                    //             if (attribute.ref && attribute.ref.name) {
                    //                 preAction += ` const ${attribute.name} = await this.${attribute.ref.name}.findOne({_id:schema.${attribute.name}})
                    //                 if(!${attribute.name}) throw new Error(this.messages.canNotFind("this ${attribute.name}}" ));
                    //                 `

                    //             }
                    //             preAction += `actionSchema.${attribute.name} = schema.${attribute.name}
                    //         }`
                    //         }




                    //     } else {

                    //         if (["createdBy", "updatedBy"].includes(attribute.name)) {
                    //             preAction += `actionSchema.${attribute.name} = profile._id 
                    //                 `
                    //         }

                    //     }
                    // }


                    action = `
                    let ${manageModel.name.toLowerCase()} = await this.${manageModel.name}.findOne({
                        name: schema.name,
                        deletedAt: {
                            $exists: false
                        }
                    });

                    if (!${manageModel.name.toLowerCase()}) {
                        const new${manageModel.name.capitalize()} = new this.${manageModel.name}(schema)
                        ${manageModel.name.toLowerCase()}  = await new${manageModel.name.capitalize()}.save()
                    }else {
                        await ${manageModel.name.toLowerCase()}.updateOne(schema);
                    }
                    `

                    response = `resolve(${manageModel.name.toLowerCase()})`

                    argument = "schema"




                    model.name = `manage${model.manageModel.capitalize()}`
                    model.constantCode = constantCode
                    model.preAction = preAction
                    model.action = action
                    model.response = response
                    model.argument = argument

                    model.content = ParentController.getBaseControllerMethodContent(model)




                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }
    static async getListAlgo(model) {
        return new Promise((resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(models => {

                    const ourModel = ParentController.methods().getItemId(models, model.manageModel, "name", "*")

                    const attributes = ourModel.attributes
                    let sort = ""

                    // const keys = attributes.map(item => item.name)



                    // console.log(model)

                    let match = `match = { 
                        matchUpdatedBy: {},
                        `


                    let constantCode = `
                    const { matchUpdatedBy
                        `
                    let preAction = `
                    const data = []`
                    let action = `let response = await this.${ourModel.name}.find(schema)
                    .populate({
                        path: "updatedBy",
                        match: matchUpdatedBy
                    })`

                    let resourceCondition = `item.updatedBy
                    `

                    for (let attribute of attributes) {
                        // is not provide by system 
                        if (attribute.ref && typeof attribute.ref == 'object') {

                            if (['updatedBy'].includes(attribute.name)) {
                                continue
                            }

                            action += `.populate({
                                path: "${attribute.name}",
                                match: match${attribute.name.capitalize()}
                            })`

                            match += `match${attribute.name.capitalize()} : {},`
                            constantCode += `,match${attribute.name.capitalize()}`

                            resourceCondition += `//&& item.${attribute.name}
                            `
                        }

                    }
                    action += sort

                    constantCode += `} = match 
                    `

                    match += `}`

                    let argument = `schema = {}, ${match}`

                    let postAction = ``

                    let preResourceCondition = ``
                    let preResource = ``
                    let resource = `data.push(await this.${ourModel.name}Resource.collection(item))`
                    let postResource = ``
                    let postResourceCondition = ``


                    let resourcePart = `
                    for (let item of response) {
                    /*<PreResourceCondition>*/${preResourceCondition}
                    /*</PreResourceCondition>*/
            
            
                        if (${resourceCondition}) {
                            /*<PreResource>*/${preResource}
                            /*</PreResource>*/
                           
                            /*<Resource>*/
                            ${resource}
                            /*</Resource>*/
                           
                            /*<PostResource>*/${postResource}
                            /*</PostResource>*/
                        }
            
            
                        /*<PostResourceCondition>*/${postResourceCondition}
                        /*</PostResourceCondition>*/
            
                    }
                    `
                    let preResolve = ``
                    let response = `resolve(data)`
                    let preReject = ``

                    model.name = `get${ourModel.name}ListAlgo`
                    model.constantCode = constantCode
                    model.resourcePart = resourcePart
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.response = response
                    model.argument = argument
                    model.preResolve = preResolve
                    model.preReject = preReject

                    model.content = ParentController.getBaseControllerMethodContent(model)




                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }

    static async showAlgo(model) {
        return new Promise((resolve, reject) => {

            ParentController.getModels({
                    project: model.project,
                    isActor: model.isActor,
                }).then(models => {

                    const ourModel = ParentController.methods().getItemId(models, model.manageModel, "name", "*")

                    const attributes = ourModel.attributes

                    // const keys = attributes.map(item => item.name)



                    // console.log(model)

                    let match = `match = { 
                        matchUpdatedBy: {},
                        `


                    let constantCode = `
                    const { matchUpdatedBy
                        `
                    let preAction = `
                    let data `
                    let action = `let response = await this.${ourModel.name}.findOne(schema)
                    .populate({
                        path: "updatedBy",
                    })`

                    let resourceCondition = `response.updatedBy
                    `

                    for (let attribute of attributes) {
                        // is not provide by system 
                        if (attribute.ref && typeof attribute.ref == 'object') {

                            if (['updatedBy'].includes(attribute.name)) {
                                continue
                            }

                            action += `.populate({
                    path: "${attribute.name}",
                    match: match${attribute.name.capitalize()}
                })`

                            match += `match${attribute.name.capitalize()} : {},`
                            constantCode += `,match${attribute.name.capitalize()}`

                            resourceCondition += `//&& response.${attribute.name}
                `
                        }

                    }

                    constantCode += `} = match 
                    `

                    match += `}`

                    let argument = `schema = {}, ${match}`
                    action += `
                    if (!response) throw new Error(this.messages.canNotFind('this response'))
            
                    `
                    let postAction = ``

                    let preResourceCondition = ``
                    let preResource = ``
                    let resource = `data = await this.${ourModel.name}Resource.collection(response)`
                    let postResource = ``
                    let postResourceCondition = ``


                    let resourcePart = `
                    /*<PreResourceCondition>*/${preResourceCondition}
                    /*</PreResourceCondition>*/
            
            
                        if (${resourceCondition}) {
                            /*<PreResource>*/${preResource}
                            /*</PreResource>*/
                           
                            /*<Resource>*/
                            ${resource}
                            /*</Resource>*/
                           
                            /*<PostResource>*/${postResource}
                            /*</PostResource>*/
                        }
            
            
                        /*<PostResourceCondition>*/${postResourceCondition}
                        /*</PostResourceCondition>*/
            
                    `
                    let preResolve = ``
                    let response = `resolve(data)`
                    let preReject = ``

                    model.name = `get${ourModel.name}Algo`
                    model.constantCode = constantCode
                    model.resourcePart = resourcePart
                    model.preAction = preAction
                    model.action = action
                    model.postAction = postAction
                    model.response = response
                    model.argument = argument
                    model.preResolve = preResolve
                    model.preReject = preReject

                    model.content = ParentController.getBaseControllerMethodContent(model)




                    resolve(model)
                })
                .catch(err => {
                    console.log(err)
                    reject(err);

                });

        })
    }
}

export default BaseControllerMethods